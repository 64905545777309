main.service('UserService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var User = this;

  User.getUsers = function(departmentId) {
    return $http({
      url : 'index.php/getUsers/',
      params : {
        'departmentId' : departmentId
        }
    });
  };

  User.checkIfPasswordCorrect = function(current_password) {
return $http({
method : 'GET',
url : 'index.php/checkIfPasswordCorrect',
params : {
'current_password' : current_password
}
});
},
User.postChangePassword = function(dict) {
return $http({
method: 'POST',
url: 'index.php/postChangePassword',
headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
data: $.param(dict)
});
},

User.emailnotification = function(newtask,replycoments,statuschange,taskreport,taskdue,dailyreport) {
return $http({
method: 'POST',
url: 'index.php/emailnotification',
params: {
        'newtask':newtask,
        'replycoments':replycoments,
        'statuschange':statuschange,
        'taskreport':taskreport,
        'taskdue':taskdue,
        'dailyreport':dailyreport,
      }
});
},


  User.getProfile = function() {
    return $http({
      url : 'index.php/getProfile/',
    });
  };
    User.getusernotifications = function() {
    return $http({
      url : 'index.php/getusernotifications/',
    });
  };
  User.removefile = function(userId) {
    // console.log(fileId);
    return $http({
      method: 'GET',
      url : 'index.php/removeprofile/',
      params: {
        'userId':userId,
      }
    });
  };

  User.activateDeactivate = function(userId,actDeaId) {
    return $http({
        method: 'POST',
      url : 'index.php/activateDeactivate/',
      params: {
        'userId':userId,
        'actDeaId':actDeaId,
      }
    });
  };
   User.accesCompany = function(userId,companyId) {
    return $http({
        method: 'POST',
      url : 'index.php/accesCompany/',
      params: {
        'userId':userId,
        'companyId':companyId,
      }
    });
  };

   User.NotaccesCompany = function(userId,companyId) {
    return $http({
        method: 'POST',
      url : 'index.php/NotaccesCompany/',
      params: {
        'userId':userId,
        'companyId':companyId,
      }
    });
  };
User.save = function(user,id,birthDate) {
    return $http({
        method: 'POST',
      url : 'index.php/users/',
      params: {
        'user':user,
        'id':id,
        'birthDate':birthDate,
      }
    });
  };
  User.saveSuper = function(user,id,birthDate) {
    return $http({
        method: 'POST',
      url : 'index.php/saveSuper/',
      params: {
        'user':user,
        'id':id,
        'birthDate':birthDate,
      }
    });
  };
  User.update = function(user,previousId,birthDate) {
    return $http({
        method: 'POST',
      url : 'index.php/update/',
      params: {
        'user':user,
        'previousId':previousId,
        'birthDate':birthDate,
      }
    });
  };

    User.updateSuper = function(user,previousId,birthDate) {
    return $http({
        method: 'POST',
      url : 'index.php/updateSuper/',
      params: {
        'user':user,
        'previousId':previousId,
        'birthDate':birthDate,
      }
    });
  };
 User.getRoles = function() {
    return $http({
      url : 'index.php/getRoles/',
    });
  };
User.getUser = function(userId) {
    return $http({
      method: 'GET',
      url : 'index.php/getUser/',
      params: {
        'userId':userId,
      }
    });
  };

  User.toggle = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggle/',
      params: {
        'id':id,
      }
    });
  };

  User.getCompanies = function() {
    return $http({
      url : 'index.php/getCompanies/',
    });
  };
});
