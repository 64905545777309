main.service('TaskService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Task = this;

 Task.getTasks = function(projectId) {
    return $http({
      url : 'index.php/getTasks/',
      params: {
        'projectId':projectId,
      }
    });
  }; 
   Task.updateprojectcache = function(projectId) {
    return $http({
      url : 'index.php/updateprojectcache/',
      params: {
        'projectId':projectId,
      }
    });
  }; 
 
      Task.getMilestones = function(projectId) {
    return $http({
      url : 'index.php/getMilestones/',
      params: {
        'projectId':projectId,
      }
    });
  }; 
  Task.taskCategory = function(projectId,categoryid) {
    return $http({
      url : 'index.php/taskCategory/',
      params: {
        'projectId':projectId,
        'categoryid':categoryid,
      }
    });
  }; 
  Task.savecomment = function(comment,taskId,tasksassign,priorityId,statusId,notify2) {
    console.log(notify2);
    return $http({
       method: 'POST',
      url : 'index.php/savecomment/',
      params: {
        'comment':comment,
        'taskId':taskId,
        'tasksassign':tasksassign,
        'priorityId':priorityId,
        'statusId':statusId,
        'notify2[]':notify2,
      }
    });
  };
    Task.saveTask = function(task,id,projectId,companyId,startDate,endDate) {
    return $http({
       method: 'POST',
      url : 'index.php/tasks/',
      params: {
        'task':task,
        'id':id,
        'projectId':projectId,
        'companyId':companyId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  }; 
  Task.storeFromTime = function(task,id,projectId,companyId,startDate,endDate) {
    return $http({
       method: 'POST',
      url : 'index.php/storeFromTime/',
      params: {
        'task':task,
        'id':id,
        'projectId':projectId,
        'companyId':companyId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };
     Task.saveTaskFrmMile = function(task,id,milestoneId,startDate,endDate) {
    return $http({
       method: 'POST',
      url : 'index.php/saveTaskFrmMile/',
      params: {
        'task':task,
        'id':id,
        'milestoneId':milestoneId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  }; 
    Task.UpdateTask = function(modalstate,taskUpdatestartDate,taskUpdateendDate) {
    return $http({
       method: 'POST',
      url : 'index.php/UpdateTask/',
      params: {
        'modalstate':modalstate,
        'taskUpdatestartDate':taskUpdatestartDate,
        'taskUpdateendDate':taskUpdateendDate,
      }
    });
  };
   Task.milestoneIsactive = function(projectId,isactiveId) {
    return $http({
      url : 'index.php/milestoneIsactive/',
      params: {
        'projectId':projectId,
        'isactiveId':isactiveId,
        'isactiveId':isactiveId,
      }
    });
  };
   Task.getTasksNew = function(statusid,projectId,milestoneId) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'statusid':statusid,
        'projectId':projectId,
        'milestoneId':milestoneId,
      }
    });
  }; 
    Task.getTasksCatNew = function(projectId,categoryid,statusid) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'projectId':projectId,
        'categoryid':categoryid,
        'statusid':statusid,
      }
    });
  }; 
  Task.getTasksClosed = function(statusid,projectId,milestoneId) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'statusid':statusid,
        'projectId':projectId,
        'milestoneId':milestoneId,
      }
    });
  }; 
  Task.getTasksCatClosed = function(projectId,categoryid,statusid) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
       'projectId':projectId,
        'categoryid':categoryid,
        'statusid':statusid,
      }
    });
  }; 
  Task.getTasksCatInProgress = function(projectId,categoryid,statusid) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'projectId':projectId,
        'categoryid':categoryid,
        'statusid':statusid,
      }
    });
  };
   Task.getTasksInProgress = function(statusid,projectId,milestoneId) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'statusid':statusid,
        'projectId':projectId,
        'milestoneId':milestoneId,
      }
    });
  };  
  Task.getTasksResolved = function(statusid,projectId,milestoneId) {
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
        'statusid':statusid,
        'projectId':projectId,
        'milestoneId':milestoneId,
      }
    });
  }; 
    Task.getTasksCatResolved = function(projectId,categoryid,statusid){
    return $http({
      url : 'index.php/getTaskStatus/',
      params: {
         'projectId':projectId,
        'categoryid':categoryid,
        'statusid':statusid,
      }
    });
  };

  // Task.getProjects = function() {
  //   return $http({
  //     url : 'index.php/getProjects/',
  //   });
  // }; 
    Task.getTypes = function() {
    return $http({
      url : 'index.php/getTypes/',
    });
  };
  Task.getPriorities = function() {
    return $http({
      url : 'index.php/getPriorities/',
    });
  };  

    Task.toggleTask = function(id,milestoneId,taskId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleTask/',
      params: {
        'id':id,
        'milestoneId':milestoneId,
        'taskId':taskId,
        
      }
    });
  };
  Task.toggleTaskEdit = function(id,projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleTaskEdit/',
      params: {
        'id':id,
        'projectId':projectId,
      }
    });
  };
   Task.toggleTimelogEdit = function(id,projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleTaskEdit/',
      params: {
        'id':id,
        'projectId':projectId,
      }
    });
  };
  Task.toggleMilestoneEdit = function(id,projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleTaskEdit/',
      params: {
        'id':id,
        'projectId':projectId,
      }
    });
  };

   Task.UpdateStatus = function(taskId,projectId,assigneto,statusId) {
     console.log(statusId) 
    return $http({
      method: 'POST',
      url : 'index.php/UpdateStatus/',
      params: {
        // '$index':$index,
        'taskId':taskId,
        'projectId':projectId,
        'assigneto':assigneto,
        'statusId':statusId,
      }
    });
  };
    Task.UpdateStatusArchive = function(taskId,projectId,statusId) {
     // console.log(statusId) 
    return $http({
      method: 'POST',
      url : 'index.php/UpdateStatusArchive/',
      params: {
        'taskId':taskId,
        'projectId':projectId,
        'statusId':statusId,
      }
    });
  };
      Task.setFav = function(taskId,projectId,statusId) {
     // console.log(statusId) 
    return $http({
      method: 'POST',
      url : 'index.php/setFav/',
      params: {
        // '$index':$index,
        'taskId':taskId,
        'projectId':projectId,
        'statusId':statusId,
      }
    });
  };
  Task.setFavcheck = function(taskId,projectId,favId) {
     // console.log(statusId) 
    return $http({
      method: 'POST',
      url : 'index.php/setFavcheck/',
      params: {
        // '$index':$index,
        'taskId':taskId,
        'projectId':projectId,
        'favId':favId,
      }
    });
  };
    Task.completeMile = function(milestoneId,projectId,isactiveId) {
     // console.log(statusId) 
    return $http({
      method: 'POST',
      url : 'index.php/completeMile/',
      params: {
        'milestoneId':milestoneId,
        'isactiveId':isactiveId,
        'projectId':projectId,
      }
    });
  };


  
    Task.saveMilestone = function(modalstate,projectId,companyId,startDate,endDate) {
     // console.log(projectId); 
    return $http({
      method: 'POST',
      url : 'index.php/saveMilestone/',
      params: {
        'modalstate':modalstate,
        'projectId':projectId,
        'companyId':companyId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };


  Task.updateMilestone = function(milestone,id,projectId,companyId,UpdatestartDate,UpdateendDate) {
    return $http({
      method: 'POST',
      url : 'index.php/updateMilestone/',
      params: {
        'id':id,
        'milestone':milestone,
        'projectId':projectId,
        'companyId':companyId,
        'UpdatestartDate':UpdatestartDate,
        'UpdateendDate':UpdateendDate,
      }
    });
  };



 Task.UpdatePriority = function(taskId,projectId,priorityId) {
     console.log(priorityId) 
    return $http({
      method: 'POST',
      url : 'index.php/UpdatePriority/',
      params: {
        // '$index':$index,
        'taskId':taskId,
        'projectId':projectId,
        'priorityId':priorityId,
      }
    });
  };


  Task.getProjectUsers  = function(projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/getProjectUsers/',
      params: {
        'projectId':projectId,
      }
    });
  };
  Task.getUsersNotInProject = function(projectId) {
    // console.log(projectId);
    
    return $http({
      url : 'index.php/getUsersNotInProject/',
       params: {
        'projectId':projectId,
      }
    });
  };
    Task.getMilestoneTasks  = function(milestoneId,projectId) {
    // console.log(milestoneId);  
    return $http({
      method: 'GET',
      url : 'index.php/getMilestoneTasks/',
      params: {
         'projectId':projectId,
        'milestoneId':milestoneId,
       
      }
    });
  };
  Task.getTasksNotInMilestone = function(milestoneId,projectId) {
    // console.log(projectId);
    
    return $http({
      url : 'index.php/getTasksNotInMilestone/',
       params: {
        'projectId':projectId,
        'milestoneId':milestoneId,
        

      }
    });
  };
     Task.addTaskToMile = function(list,milestoneId,projectId) {
    // console.log(projectId);

      return $http({
        method: 'POST',
        url: 'index.php/task_milestones',
      params: {
          'list[]' : list,
          'milestoneId' : milestoneId,
          'projectId' : projectId,
        }
      });
    },
    Task.moveTaskToMile = function(taskId,milestoneId,projectId) {
      return $http({
        method: 'POST',
        url: 'index.php/movetask_milestones',
      params: {
          'taskId' : taskId,
          'milestoneId' : milestoneId,
          'projectId' : projectId,
        }
      });
    },
    Task.moveTaskToProject = function(projectId,taskId) {
      return $http({
        method: 'POST',
        url: 'index.php/movetask_project',
      params: {
          'taskId' : taskId,
          'projectId' : projectId,
        }
      });
    },
  Task.getTask = function(taskId) {
    
    return $http({
      method: 'GET',
      url : 'index.php/getTask/',
      params: {
        'taskId':taskId,
      }
    });
  };
   Task.removefile = function(fileId,taskId) {
    // console.log(fileId);
    return $http({
      method: 'GET',
      url : 'index.php/removefile/',
      params: {
        'fileId':fileId,
        'taskId':taskId,
      }
    });
  };
});
