main.service('RoleService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Role = this;

 Role.getRoles = function() {
    return $http({
      url : 'index.php/getRoles/',
    });
  };  
   Role.toggleRole = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleRole/',
      params: {
        'id':id,
      }
    });
  };

});
