main.service('TypeService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Type = this;

 Type.getTypes = function() {
    return $http({
      url : 'index.php/getTypes/',
    });
  };  

    Type.toggleType = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleType/',
      params: {
        'id':id,
      }
    });
  };

});
