main.service('TimelogService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Timelog = this;

     Timelog.getProjects = function() {
    return $http({
      url : 'index.php/getProjects/',
    });
  }; 

  Timelog.downloadExcelTimelog = function(projectId) {
    return $http({
      url : 'index.php/downloadExcelTimelog/xls',
      params: {
        // 'timelogs':timelogs,
        'projectId':projectId
      }
    });
  }; 

  // Timelog.get_rows_per_page = function() {
  //   return $http({
  //     method : 'GET',
  //     url : 'index.php/getRowsPerPage',
      
  //   });
  // },

  Timelog.filterTimelog = function(startDate,endDate,projectId) {
    return $http({
      method : 'GET',
      url : 'index.php/filterTimelog',
      params : {
        'startDate' : startDate,
        'endDate' : endDate,
        'projectId': projectId,
      }

    });
  },
    Timelog.printtime = function(projectId,timelogId) {
    return $http({
      method: 'GET',
      url : 'index.php/printtime',
      params: {
        'projectId':projectId,
        'timelogId':timelogId
      }
    });
  }; 

  Timelog.saveTimelog = function(itemused,timelog,user_id,projectId,taskId,selectdate,totalsumitem,logged_hours,bilableAmount,start_timelog,end_timelog,breakstart_timelog,breakend_timelog) {
     // console.log(projectId); 
    return $http({
      method: 'POST',
      url : 'index.php/saveTimelog/',
      params: {
        'timelog':timelog,
        'user_id':user_id,
        'projectId':projectId,
        'taskId':taskId,
        'selectdate':selectdate,
        'itemused[]':itemused,
        'totalsumitem':totalsumitem,
        'end_timelog':end_timelog,
        'start_timelog':start_timelog,
        'logged_hours':logged_hours,
        'bilableAmount':bilableAmount,
        'breakstart_timelog':breakstart_timelog,
        'breakend_timelog':breakend_timelog,
      }
    });
  };

  Timelog.updateTimelog = function(itemused,timelog,user_id,projectId,selectdate,totalsumitem,logged_hours,bilableAmount,start_timelog,end_timelog,breakstart_timelog,breakend_timelog) {
     // console.log(projectId); 
    return $http({
      method: 'POST',
      url : 'index.php/updateTimelog/',
      params: {
        'timelog':timelog,
        'user_id':user_id,
        'projectId':projectId,
        'selectdate':selectdate,
        'itemused[]':itemused,
        'totalsumitem':totalsumitem,
        'end_timelog':end_timelog,
        'start_timelog':start_timelog,
        'logged_hours':logged_hours,
        'bilableAmount':bilableAmount,
        'breakstart_timelog':breakstart_timelog,
        'breakend_timelog':breakend_timelog,
      }
    });
  };

    Timelog.sendTimelog = function(startDate,endDate,timelogId,projectId,statusId) {
     // console.log(priorityId) 
    return $http({
      method: 'POST',
      url : 'index.php/sendTimelog/',
      params: {
        // '$index':$index,
        'timelogId':timelogId,
        'projectId':projectId,
        'statusId':statusId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };
  Timelog.acceptTimelog = function(startDate,endDate,timelog,timelogId,projectId,statusId) {
    // console.log(priorityId) 
   return $http({
     method: 'POST',
     url : 'index.php/acceptTimelog/',
     params: {
       'timelog':timelog,
       'timelogId':timelogId,
       'projectId':projectId,
       'statusId':statusId,
       'startDate':startDate,
       'endDate':endDate,
     }
   });
 };

 Timelog.denieTimelog = function(startDate,endDate,timelog,timelogId,projectId) {
  // console.log(priorityId) 
 return $http({
   method: 'POST',
   url : 'index.php/denieTimelog/',
   params: {
     // '$index':$index,
     'timelog':timelog,
     'timelogId':timelogId,
     'projectId':projectId,
     'startDate':startDate,
     'endDate':endDate,
   }
 });
};

   Timelog.usertimelog = function(startDate,endDate,projectId,userId) {
     // console.log(priorityId) 
    return $http({
      method: 'GET',
      url : 'index.php/usertimelog/',
      params: {
        'projectId':projectId,
        'userId':userId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };

    Timelog.getTimelogs = function(startDate,endDate,projectId) {
    return $http({
      url : 'index.php/getTimelogs/',
      params: {
        'projectId':projectId,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };
 

});
