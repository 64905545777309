'use strict';
 main.controller('RolesController', function($rootScope,RoleService,globalService, $scope,$http, actions, $modal, $log, notify, modalService, NgTableParams) {
    $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}

		$scope.roles = function () {
           
			RoleService.getRoles().success(function(data, status) {   
				
            $scope.roles = data.roles;
            //   $scope.rolestable = new NgTableParams({
            //         page: 1,
            //         count: 10,
            //         // sorting: { effective_dt: "asc" },
            //     },
            //     {
            //         total: $scope.roles.length,
            //         // counts: [10, 20, 50, 100,$scope.roles.length],
            //         getData: function( params) {
            //           params.total($scope.roles.length);
            //         $scope.data = $scope.roles.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
            //         return $scope.data;
   
            //         }
            //     // $scope: $scope
            // });  
				
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

        //disply modalform.
        $scope.toggleRole = function(modalstate, id) {
        $scope.modalstate = modalstate;
        RoleService.toggleRole(id).success(function(data, status) {  
      $scope.role = data.role;
      })

        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Role";
                $('#myModal').modal('show');
                break;

                case 'edit':
                $scope.form_title = "Edit Role";
                $('#myModal1').modal('show');
                break;
 
        }
        // console.log(id)
         
    }


   

     // save new record & update existing record
  $rootScope.save = function(modalstate,id,roledata) {
   
        var url = "roles";
        $http({
            method: 'POST',
            url: url,
            data: $.param(roledata),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });

    }
     
 $rootScope.updateR = function(modalstate,id) {
        var url = "updateR";
        $http({
            method: 'POST',
            url: url,
            data: $.param($scope.role),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });

    }

$scope.deleteItem = function(role_id,modelname){
  console.log(role_id);
    globalService.deleteItem(role_id,modelname)
  }


	}); 

  