main.service('ProjectService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Project = this;

Project.getProjects = function(departmentId) {
    return $http({
      url : 'index.php/getProjects/',
      params: {
        'departmentId':departmentId,
      }
    });
  };

  Project.logs = function() {
    return $http({
      url : 'index.php/logs/',
      params: {
        // 'companyId':companyId,
      }
    });
  };

  Project.downloadExcel = function(projects,projectId) {
    return $http({
      url : 'index.php/downloadExcel/xls',
      params: {
        'projects':projects,
        'projectId':projectId
      }
    });
  }; 
  Project.printjob = function(jobcostings,projectId,jobcostingId) {
    return $http({
      method: 'GET',
      url : 'index.php/printjob',
      params: {
        'jobcostings':jobcostings,
        'projectId':projectId,
        'jobcostingId':jobcostingId
      }
    });
  }; 
    Project.activateDeactivate = function(projectId,actDeaId) {
    return $http({
        method: 'POST',
      url : 'index.php/activateDeactivateP/',
      params: {
        'projectId':projectId,
        'actDeaId':actDeaId,
      }
    });
  };

    Project.getTasks = function(projectId) {
    return $http({
      url : 'index.php/getTasks/',
      params: {
        'projectId':projectId,
      }
    });
  }; 

  Project.getAllTaskType = function(projectId) {
    return $http({
      url : 'index.php/getAllTaskType/',
      params: {
        'projectId':projectId,
      }
    });
  };

    Project.updateP = function(project,id,startDate,endDate) {
    return $http({
      method: 'POST',
      url : 'index.php/updateP/',
      params: {
        'project':project,
        'id':id,
         'startDate':startDate,
        'endDate':endDate,
      }
    });
  };

  Project.save = function(project,id,startDate,endDate) {
    return $http({
      method: 'POST',
      url : 'index.php/projects/',
      params: {
        'project':project,
        'id':id,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };

  Project.getAllTaskStatus = function(projectId) {
    return $http({
      url : 'index.php/getAllTaskStatus/',
      params: {
        'projectId':projectId,
      }
    });
  }; 

   Project.getProjectUsers = function(projectId,id) {
    return $http({
      method: 'GET',
      url : 'index.php/getProjectUsers/',
      params: {
        'projectId':projectId,
        'id':id,
      }
    });
  };

  Project.getTypes = function() {
    return $http({
      url : 'index.php/getTypes/',
    });
  };

Project.getPriorities = function() {
    return $http({
      url : 'index.php/getPriorities/',
    });
  };

  Project.getUsersNotInProject = function(projectId) {
    // console.log(projectId);
    
    return $http({
      url : 'index.php/getUsersNotInProject/',
       params: {
        'projectId':projectId,
      }
    });
  };

  Project.getCompanies = function() {
    return $http({
      url : 'index.php/getCompanies/',
    });
  };

   Project.getCompany = function() {
    return $http({
      url : 'index.php/getCompany/',
    });
  };
     
    Project.assignusers = function(list,projectId) {
      return $http({
        method: 'POST',
        url: 'index.php/project_users',
      params: {
          'list[]' : list,
          'projectId' : projectId,
        }
      });
    },
    Project.removeusers = function(list,projectId) {
    console.log(projectId);
      
      return $http({
        method: 'POST',
        url: 'index.php/removeusers',
      params: {
          'list[]' : list,
          'projectId' : projectId,
        }
      });
    },

  Project.getProject = function(projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/getProject/',
      params: {
        'projectId':projectId,
      }
    });
  };

    Project.toggleProject = function(projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleProject/',
      params: {
        'projectId':projectId,
      }
    });
  };

 });

