  ' use strict';

  var dependencies = [ 'ngSanitize','daterangepicker',
  'ui.router','xeditable',
  'ui.bootstrap','angular-loading-bar',
  'uiNotify','angularFileUpload','ui.calendar',
  'chart.js','angularUtils.directives.dirPagination','textAngular','ngTable','ngMessages', 'timepickerPop','checklist-model'
  ];

  var main = angular.module('main', dependencies);

  main.config(function($stateProvider, $urlRouterProvider) {

    $stateProvider
        .state('mail', {
      url: "/mail",
      templateUrl : 'templates/mail.html', 
    })

    .state('dashboard', {
      url: "/dashboard",
      templateUrl : 'templates/dashboard.html',
    })
    .state('dailycatchup', {
      url: "/dailycatchup",
      templateUrl : 'templates/dailycatchup.html',
    })
   
     .state('users', {
      url: "/users",
      templateUrl : 'templates/users.html',
    })

     .state('changepassword', {
      url: "/changepassword",
      templateUrl : 'templates/changepassword.html',
    })

     .state('files', {
      url: "/files",
      templateUrl : 'templates/files.html',
    })
      
      .state('superAdmins', {
      url: "/superAdmins",
      templateUrl : 'templates/superAdmins.html',
    })

       .state('milestones', {
      url: "/milestones",
      templateUrl : 'templates/milestones.html',
    })
         .state('ganttchart', {
      url: "/ganttchart",
      templateUrl : 'templates/ganttchart.html',
    })
      .state('timelogs', {
      url: "/timelogs",
      templateUrl : 'templates/timelogs.html',
    })
      .state('timelogsreport', {
      url: "/timelogsreport",
      templateUrl : 'templates/timelogsreport.html',
    })

       .state('tasks', {
      url: "/tasks",
      templateUrl : 'templates/tasks.html',
    })
         .state('archives', {
      url: "/archives",
      templateUrl : 'templates/archives.html',
    })

          .state('budgets', {
      url: "/budgets",
      templateUrl : 'templates/budgets.html',
    })

          .state('jobcosting', {
      url: "/jobcosting",
      templateUrl : 'templates/jobcosting.html',
    })

      .state('user', {
      url: "/user/:userId",
      templateUrl : 'templates/user.html',
    })

      .state('milestone', {
      url: "/milestone/:milestoneId",
      templateUrl : 'templates/milestone.html',
    })

      .state('profile', {
      url: "/profile",
      templateUrl : 'templates/profile.html',
    })
        .state('notification', {
      url: "/notification",
      templateUrl : 'templates/notification.html',
    })
       .state('mycompany', {
      url: "/mycompany",
      templateUrl : 'templates/mycompany.html',
    })
     .state('roles', {
      url: "/roles",
      templateUrl : 'templates/roles.html'
    })
    .state('departments', {
      url: "/departments",
      templateUrl : 'templates/departments.html',
    })
     .state('types', {
      url: "/types",
      templateUrl : 'templates/types.html',
    })
      .state('units', {
      url: "/units",
      templateUrl : 'templates/units.html',
    })
       .state('rates', {
      url: "/rates",
      templateUrl : 'templates/rates.html',
    })
    .state('logs', {
      url: "/logs",
      templateUrl : 'templates/logs.html',
    })
     .state('companies', {
      url: "/companies",
      templateUrl : 'templates/companies.html',
    })
     .state('company', {
      url: "/company/:companyId",
      templateUrl : 'templates/company.html',
    })
     .state('task', {
      url: "/task/:taskId",
      templateUrl : 'templates/task.html',
    })
     .state('projects', {
      url: "/projects",
      templateUrl : 'templates/projects.html',
    })

       .state('project', {
      url: "/project/:projectId",
      templateUrl : 'templates/project.html',
    })

    /* messaging*/
    $urlRouterProvider.otherwise('/dashboard');
  });

  main.run(function(editableOptions, editableThemes) {
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-sm';
    editableOptions.theme = 'bs3';
  });
