'use strict';
 main.controller('BudgetsController', function($rootScope,$window,BudgetService,ProjectService,DepartmentService,UnitService,TaskService,globalService, $stateParams,$scope,$http, actions, $modal, $log, notify, modalService,NgTableParams) {
       
  $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  $('#jobstartDate').datepicker({
            uiLibrary: 'bootstrap',
     
        });
  $('#jobendDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            minDate: function () {
                return $('#jobstartDate').val();
            }
        });
var departmentId = $scope.departmentId; 
        $scope.projectDep = function (departmentId) {     
          ProjectService.getProjects(departmentId).success(function(data, status) {   
            $scope.active = data.activePage;
            // console.log($scope.active);
             // $scope.projects = new NgTableParams({}, { dataset:data.projects })
              $scope.projects = data.projects;
              $scope.projectstable = new NgTableParams({
                        page: 1,
                        count: 10,
                        // sorting: { effective_dt: "asc" },
                    },
                    {
                           total: $scope.projects.length,
                        counts: [10, 20, 50, 100,$scope.projects.length],
                        getData: function( params) {
                          params.total($scope.projects.length);
                        $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                        return $scope.data;
       
                        }
                    // $scope: $scope
                });
            // console.log(data.projects);
    
            // $scope.searchItem = function(searchedItem){
            //         $scope.searchedItem = searchedItem;
            //         $scope.projects.reload();
            //     }
            //     $scope.projects = new NgTableParams({
            //         page: 1,
            //         count: 10,
            //     },
            //     {
            //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
            //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
            //     getData: function($defer, params) {
            //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
            //         var orderedData = params.sorting() ?
            //         $filter('orderBy')(filteredData, params.orderBy()) :
            //         filteredData;
            //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            //     },
            //     $scope: $scope
            // });
          }).error(function(data, status) {
            alert('something went wrong');
          }); 
          var projectId = -1;
          // console.log(projectId);    
           // $scope.budgets = function (projectId){

           BudgetService.getBudgets(projectId).success(function(data, status) {  
           $scope.budgets = data.budgets;
           $scope.Estimated = data.Estimated;
           $scope.EstimatedTime = data.EstimatedTime;
            $scope.createdat = data.created_at.created_at;
           $d = new Date ($scope.createdat);
           $scope.created_at = $d.toDateString()
           // console.log( $date )
          $scope.updatedat = data.updated_at.updated_at;
          $d = new Date ($scope.updatedat);
           $scope.updated_at = $d.toDateString()
           $scope.budgetsCreator = data.budgetsCreator;
           $scope.projectManager = data.projectManager.name;

           $scope.budgetstable = new NgTableParams({
                   page: 1,
                   count: 10,
                   // sorting: { effective_dt: "asc" },
               },
               {
                   total: $scope.budgets.length,
                   counts: [10, 20, 50, 100,$scope.budgets.length],
                   getData: function( params) {
                     params.total($scope.budgets.length);
                   $scope.data = $scope.budgets.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                   return $scope.data;
  
                   }
               // $scope: $scope
           });
           }).error(function(data, status) {
               alert('something went wrong');
           });

             BudgetService.getJobcostings(projectId).success(function(data, status) {  
           $scope.jobcostings = data.jobcostings;
           $scope.jobcostingsCreator = data.jobcostingsCreator;
           $scope.Actualamount = data.Actualamount;
             $scope.createdat = data.created_at.created_at;
           $d = new Date ($scope.createdat);
           $scope.created_at = $d.toDateString()
           // console.log( $date )
          $scope.updatedat = data.updated_at.updated_at;
          $d = new Date ($scope.updatedat);
           $scope.updated_at = $d.toDateString()
           // $scope.EstimatedTime = data.EstimatedTime;
           // $scope.projectManager = data.projectManager.name;

           $scope.jobcostingstable = new NgTableParams({
                   page: 1,
                   count: 10,
                   // sorting: { effective_dt: "asc" },
               },
               {
                   total: $scope.jobcostings.length,
                   counts: [10, 20, 50, 100,$scope.jobcostings.length],
                   getData: function( params) {
                     params.total($scope.jobcostings.length);
                   $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                   return $scope.data;
  
                   }
               // $scope: $scope
           });
           }).error(function(data, status) {
               alert('something went wrong');
           });
          
          
          // ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
          // $rootScope.project_users = data.project_users;
          // console.log(data.project_users);
          // }).error(function(data, status) {
          //   alert('something went wrong');
          // }); 
          DepartmentService.updatedepcache(departmentId).success(function(data, status) {   
                  
          }).error(function(data, status) {
              alert('something went wrong');
          }); 
          // location.reload();
        }
        ProjectService.getProjects(departmentId).success(function(data, status) {    
        $scope.projects = data.projects;
        $scope.project_users = data.project_users;
        $scope.project_usseradded = data.project_usseradded;
        $scope.role = data.role;
        $scope.company_projects = data.company_projects;

  
        if($scope.project){

        var projectId = $scope.project.id;

            BudgetService.getBudgets(projectId).success(function(data, status) {  
            $scope.budgets = data.budgets;
            $scope.budgetsCreator = data.budgetsCreator;
            $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            $scope.Estimated = data.Estimated;
            $scope.EstimatedTime = data.EstimatedTime;
            $scope.projectManager = data.projectManager.name;

            $scope.budgetstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.budgets.length,
                    counts: [10, 20, 50, 100,$scope.budgets.length],
                    getData: function( params) {
                      params.total($scope.budgets.length);
                    $scope.data = $scope.budgets.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            }).error(function(data, status) {
                alert('something went wrong');
            });


            BudgetService.getJobcostings(projectId).success(function(data, status) {  
            $scope.jobcostings = data.jobcostings;
            $scope.jobcostingsCreator = data.jobcostingsCreator;
            $scope.Actualamount = data.Actualamount;
              $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            // $scope.EstimatedTime = data.EstimatedTime;
            // $scope.projectManager = data.projectManager.name;

            $scope.jobcostingstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.jobcostings.length,
                    counts: [10, 20, 50, 100,$scope.jobcostings.length],
                    getData: function( params) {
                      params.total($scope.jobcostings.length);
                    $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            }).error(function(data, status) {
                alert('something went wrong');
            });
        
        }

        else{
        $scope.project = data.project_user_cache;
        var projectId = data.project_user_cache.id;
           // console.log(projectId);    
            // $scope.budgets = function (projectId){

            BudgetService.getBudgets(projectId).success(function(data, status) {  
            $scope.budgets = data.budgets;
            $scope.Estimated = data.Estimated;
            $scope.EstimatedTime = data.EstimatedTime;
             $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            $scope.budgetsCreator = data.budgetsCreator;
            $scope.projectManager = data.projectManager.name;

            $scope.budgetstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.budgets.length,
                    counts: [10, 20, 50, 100,$scope.budgets.length],
                    getData: function( params) {
                      params.total($scope.budgets.length);
                    $scope.data = $scope.budgets.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            }).error(function(data, status) {
                alert('something went wrong');
            });

              BudgetService.getJobcostings(projectId).success(function(data, status) {  
            $scope.jobcostings = data.jobcostings;
            $scope.jobcostingsCreator = data.jobcostingsCreator;
            $scope.Actualamount = data.Actualamount;
              $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            // $scope.EstimatedTime = data.EstimatedTime;
            // $scope.projectManager = data.projectManager.name;

            $scope.jobcostingstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.jobcostings.length,
                    counts: [10, 20, 50, 100,$scope.jobcostings.length],
                    getData: function( params) {
                      params.total($scope.jobcostings.length);
                    $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            }).error(function(data, status) {
                alert('something went wrong');
            });
        // }   

    }
     }).error(function(data, status) {
        alert('something went wrong');
      });

         $scope.projectId=$stateParams.projectId;   
          
      // console.log( $scope.projectId);
       $scope.CreateHeader = function(pname) {
          showHeader = (pname!=$scope.currentprojectname); 
          $scope.currentprojectname = pname;
          return showHeader;
          }
// $scope.Labourperhour = [];
      $scope.daysrate = function() {
       var startDate = new Date($('#jobstartDate').val());
        var endDate = new Date($('#jobendDate').val());
        $scope.rate = (endDate - startDate)  / 1000 / 60 / 60 / 24 ;
       console.log($scope.project_users);

      //    ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
      // $scope.project_users = data.project_users;
      //  console.log($scope.project_users);

      //  $scope.rate = (endDate - startDate)  / 1000 / 60 / 60 / 24;
       if ($scope.rate == 0) {
        $scope.rate = '8 hours';
         angular.forEach($scope.project_users,function(key,value){
          // console.log(key);
            if(value){
               var perHour = key['value']* 8;
                 $scope.project_users.push(perHour)
                  // $scope.Labourperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
              }
        });

       }else {
        $scope.rate = (endDate - startDate)  / 1000 / 60 / 60 / 24 ;
             angular.forEach($scope.project_users,function(key,value){
          // console.log(key);
            if(value){
               $scope.project_users.dvalue = key['dvalue']* $scope.rate;
          // console.log($scope.project_users.dvalue);
               
                 // $scope.project_users.push(perHour)
                  // $scope.Labourperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
              }
        });
       }
       //     }).error(function(data, status) {
       //        alert('something went wrong');
       // }); 
        
        }

         $scope.pricetotal = function() {
            var price = $scope.budget.price;
            var quantity = $scope.budget.quantity;
             console.log($scope.budget.price);
            $scope.total = price * quantity;
             // body...
           }
$scope.rowdata = [];

      $scope.validate = function ($index) {
        console.log($index);

      $scope.valid = 'No';

      $scope.totalsum = $scope.total1;
 
      if ($scope.newItem[$index]) {
      $scope.total1 = 0;
      $scope.newItem[$index].total = $scope.newItem[$index].quantity * $scope.newItem[$index].price;
        if ($scope.newItem[$index].total) {

          for (var i = 0; i < $scope.newItem.length; i++) {

            $scope.total1 += Number($scope.newItem[i].total || 0);

            $scope.totalsum =  $scope.total1;

          }

        }

 

        if ($scope.newItem[$index].price &&

          $scope.newItem[$index].quantity &&
          $scope.newItem[$index].item &&
          $scope.newItem[$index].uname &&
          $scope.newItem[$index].total &&

          $scope.totalsum == 0) {

          $scope.newItem_validated = 'Yes';

        }
         // $scope.newItem[$index].push({});

      }
     if ($index > 0){
         $scope.rowdata.push($scope.newItem[$index-1]);

      }
      
        // console.log($scope.rowdata);

    }

 function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

     $scope.printjob = function(jobcostings,projectId,jobcostingId) {
            // ProjectService.printjob(jobcostings,projectId,jobcostingId).success(function(data, status) {
                var url = 'index.php/printjob/?data='+"&projectId="+projectId+"&jobcostingId="+jobcostingId;
                $window.open(url);
            // });
        };

        $scope.downloadjobExcel = function(jobcostings,projectId) {
            BudgetService.downloadjobExcel(jobcostings,projectId).success(function(data, status) {
                var url = 'index.php/downloadjobExcel/xls/?data='+jobcostings +"&projectId="+projectId;
                $window.open(url);
            });
        };

      DepartmentService.getDepartments().success(function(data, status) {   
        
        $scope.departments = data.departments;
        $scope.departmentId = data.departmentId;

        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

 $scope.toggleTask = function(modalstate,projectId,jobcostingId) {
  if (projectId == null){
    notify({
      message:"Please Select A Project First.",
      template:'templates/error.html',
      position:'center'
    });
  }else {
      $scope.jobcostingId = jobcostingId;
      ProjectService.getProject(projectId).success(function(data, status) {  
      $rootScope.project = data.project;
      $rootScope.loggeduser = data.loggeduser;
      $scope.rate = 1;
      $scope.promiseddate = data.project.end_date;
 // $('#effectiveDate').datepicker({
 //            uiLibrary: 'bootstrap',
 //            setValue: new Date() 

 //        });
 
//  function getDate(){

// document.getElementById("effectiveDate").value = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);


// }
       $('#editjobstartDate').datepicker({
            uiLibrary: 'bootstrap',
            // setValue: data.jobcosting.start_date
     
        });
  $('#editjobendDate').datepicker({
            uiLibrary: 'bootstrap',
            // setValue: data.jobcosting.end_date
            change: function (e) {
         },
            minDate: function () {
                return $('#editjobstartDate').val();
            }
        });
$('#editjobpromisedDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.project.end_date
        });
  $('#jobpromisedDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.project.end_date
        });
       }).error(function(data, status) {
        alert('something went wrong');
      }); 

   
       ProjectService.getProjectUsers(projectId,jobcostingId).success(function(data, status) {  
      $scope.project_users = data.project_users;
      $scope.jobcosting = data.jobcosting;
      $scope.balancedays = data.jobcosting.balance_days;
      $scope.startDate = data.jobcosting.start_date;
      $scope.endDate = data.jobcosting.end_date;
      console.log($scope.balancedays);


      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

      $scope.today = convert(today);
        // var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

       $('#effectiveDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            maxDate: function () {
                return $scope.endDate;
            },
             minDate: function () {
                return $scope.startDate;
            }
        });
        $('#effectiveDate2').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            maxDate: function () {
                return $scope.endDate;
            },
             minDate: function () {
                return $scope.startDate;
            }
        });
      $scope.jobcostingitems = data.jobcostingitems;
      $scope.jobcostinglabours = data.jobcostinglabours;
      $scope.jobcostingcasuals = data.jobcostingcasuals;
      $scope.days = $scope.jobcosting.days;
      $scope.totalsumcasual = 0;
      $scope.totalsumlabour = 0;
        //      angular.forEach($scope.jobcostingcasuals,function(key,value){
        //   // console.log(key);
        //     if(value){
        //        $scope.totalsumitem += key['totalcost'];               
        //          // $scope.project_users.push(perHour)
        //           // $scope.Labourperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
        //       }
        // });
             for (var i = 0; i < $scope.jobcostinglabours.length; i++) {
        $scope.totalsumlabour = $scope.totalsumlabour + $scope.jobcostinglabours[i].totalcost;
       }

       for (var i = 0; i < $scope.jobcostingcasuals.length; i++) {
        $scope.totalsumcasual = $scope.totalsumcasual + $scope.jobcostingcasuals[i].totalcost;
       }

          console.log($scope.totalsumcasual);

     //    $scope.days = function($index,totaldays) {
     //      console.log($index);
     //      console.log(totaldays);
     //      console.log($scope.project_users);
     //       angular.forEach($scope.project_users,function(key,value){
     //        // console.log(value);
     //        if(value == $index){
     //      console.log(key);

     //             $scope.Totalperday = key['dvalue']*totaldays;
     //             //  $scope.Totalperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
     //          }
     //    });
     //   //   for (var i = 0; i < $scope.project_users.length; i++) {
     //   //    if (i = $index) {
     //   //    console.log(i);

     //   //    }
     //   //   // $scope.perday = $scope.project_users.dvalue * $scope.totaldays;
     //   // }
     // }

      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      $scope.budget = "";
     $scope.pricetotal = function() {
      console.log($scope.budget.quantity);
            var price = $scope.budget.price;
            var quantity = $scope.budget.quantity;

            $scope.total = price * quantity;
             // body...
           }
     switch (modalstate) {
                case 'addbudget':
                $scope.form_title = "Add budget Item";
                 $('#myModal10').modal('show');
                break;

                  case 'labourrate':
                $scope.form_title = "Select Labour Rate";
                 $('#JobmyModal').modal('show');
                break;
                case 'addjobcosting':
                $scope.form_title1 = "Add Jobcosting";
                $('#jobcostingModal').modal('show');
                break;
                case 'viewjobcosting':
                $scope.form_title_view = "Jobcosting Details";
                $('#jobcostingviewModal').modal('show');
                break;
                 case 'editjobcosting':
                $scope.form_title_view = "Jobcosting Details";
                $('#editjobcosting').modal('show');
                break;
                 case 'addjobcostingitem':
                $scope.form_title2 = "Add Items";
                $('#jobcostingModalitem').modal('show');
                break;
                 case 'addDirectLabour':
                $scope.form_title_L = "Add Direct Labour";
                $('#DLModal').modal('show');
                break;
                  case 'addItem':
                $scope.form_title4 = "Add Item Used";
                // $scope.projectId = id;
                 $('#itemmyModal').modal('show');
                break;
             }

       }
      }

    $scope.remove = {};
  
    $scope.saveDL = function(casuallabour,projectId,jobcostingId) {

    $scope.albumNameArray = $scope.project_users.filter(function(project_user){
    return project_user.selected;
  });


 $scope.Totalperday = 0;
          angular.forEach($scope.albumNameArray,function(key,value){
            console.log(value);
            // if(value){
                 var perday = key['dvalue']*key['totaldays'];
            // if(value){
                key.perday = perday;
                 // $scope.albumNameArray.push({perday})
               // }
                 $scope.Totalperday = perday + $scope.Totalperday ;
                 //  $scope.Totalperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
              // }
        });

    var effectiveDate = new Date($('#effectiveDate2').val());

    console.log($scope.albumNameArray);
    console.log($scope.totalsum);
 
              if ($scope.albumNameArray=== undefined || $scope.albumNameArray.length == 0) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
           BudgetService.saveDL($scope.albumNameArray,casuallabour,$scope.totalsum,projectId,jobcostingId,effectiveDate).success(function(data){
          // console.log(projectId)
             notify({
              message:"Direct Labours added successful.",
              template:'templates/success.html',
              position:'center'
            });
             $scope.albumNameArray == [];
              $scope.jobcostings = data.jobcostings;
                 $scope.jobcostingstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.jobcostings.length,
                    counts: [10, 20, 50, 100,$scope.jobcostings.length],
                    getData: function( params) {
                      params.total($scope.jobcostings.length);
                    $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                  }
                // $scope: $scope
            }); 
         
            location.reload();
          })
       }

    // BudgetService.saveDL($scope.albumNameArray,casuallabour,$scope.totalsum,projectId,jobcostingId,effectiveDate).success(function(data){
    //       // console.log(projectId)
    //          notify({
    //           message:"Direct Labours added successful.",
    //           template:'templates/success.html',
    //           position:'center'
    //         });
    //           $scope.jobcostings = data.jobcostings;
    //              $scope.jobcostingstable = new NgTableParams({
    //                 page: 1,
    //                 count: 10,
    //                 // sorting: { effective_dt: "asc" },
    //             },
    //             {
    //                 total: $scope.jobcostings.length,
    //                 counts: [10, 20, 50, 100,$scope.jobcostings.length],
    //                 getData: function( params) {
    //                   params.total($scope.jobcostings.length);
    //                 $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
    //                 return $scope.data;
   
    //               }
    //             // $scope: $scope
    //         }); 
         
    //         // location.reload();
    //       })
                 // $scope.toggleTask();
          // console.log($scope.albumNameArray);

           }

    $scope.addItem = function (newrow) {
      $scope.newItem.push({});
      $scope.newItem_validated = 'No';
      $scope.validate(newrow);

           }
        $scope.removeItem = function($index) {
             $scope.newItem.splice($index,1)
             $scope.validate(0);;
            // var totalAm = $scope.total;
            // $scope.totalsum =  $scope.totalsum - totalAm;

             }
          $scope.newItem = [];
          // $scope.totalsum = 0;
    $scope.saveItem = function(additem,projectId,jobcostingId,action) {
    var effectiveDate = new Date($('#effectiveDate').val());

            var totalsum = $scope.totalsum;
               
    BudgetService.saveItem(additem,projectId,jobcostingId,totalsum,effectiveDate).success(function(data, status) {   
        if (action == 'close') {
                    notify({
                     message:"Items Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.newItem = "";
                      $scope.totalsum = 0;
                      notify({
                     message:"Items Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      // $('#taskstartDate').val();
                } 
        $scope.jobcostings = data.jobcostings;
                 $scope.jobcostingstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.jobcostings.length,
                    counts: [10, 20, 50, 100,$scope.jobcostings.length],
                    getData: function( params) {
                      params.total($scope.jobcostings.length);
                    $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            }); 
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
                 // $scope.toggleTask();

                // if (action == 'close') {
                //     notify({
                //      message:"Item Added successful.",
                //      template:'templates/success.html',
                //      position:'center'
                //       });
                //     // location.reload();
                // } else {
                //       $scope.budget = "";
                //       notify({
                //      message:"Item Added successful.",
                //      template:'templates/success.html',
                //      position:'center'
                //       });
                      
                //       // $('#taskstartDate').val();
                // } 
           }
    $scope.toggleTaskEdit = function(modalstate,id,projectId) {

         $scope.editpricetotal = function() {
            var price = $scope.budget.price;
            var quantity = $scope.budget.quantity;

            $scope.edittotal = price * quantity;
             // body...
           }

      BudgetService.toggleBudgetEdit(id,projectId).success(function(data, status) {  
      $scope.budget = data.budget;
      console.log($scope.budget);
      })
      switch (modalstate) {
                 case 'editBudget':
                $scope.form_title = "Edit Budget Item";
                $scope.budget={budgetId:id};
                $('#myModal11').modal('show');
                break;
 
        }
  }

        UnitService.getUnits().success(function(data, status) {   
        
        $scope.units = data.units;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 


    $scope.projectTask  = function (projectId) { 
      TaskService.updateprojectcache(projectId).success(function(data, status) {         
            }).error(function(data, status) {
                alert('something went wrong');
            });  

            BudgetService.getBudgets(projectId).success(function(data, status) {         
            $scope.budgets = data.budgets;
            $scope.budgetsCreator = data.budgetsCreator;
             $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            $scope.Estimated = data.Estimated;
            $scope.EstimatedTime = data.EstimatedTime;
            $scope.projectManager = data.projectManager.name;
            
            $scope.budgetstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.budgets.length,
                    counts: [10, 20, 50, 100,$scope.budgets.length],
                    getData: function( params) {
                    params.total($scope.budgets.length);
                    $scope.data = $scope.budgets.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

              
            }).error(function(data, status) {
                alert('something went wrong');
            });
           BudgetService.getJobcostings(projectId).success(function(data, status) {  
            $scope.jobcostings = data.jobcostings;
            $scope.jobcostingsCreator = data.jobcostingsCreator;
            $scope.Actualamount = data.Actualamount;
              $scope.createdat = data.created_at.created_at;
            $d = new Date ($scope.createdat);
            $scope.created_at = $d.toDateString()
            // console.log( $date )
           $scope.updatedat = data.updated_at.updated_at;
           $d = new Date ($scope.updatedat);
            $scope.updated_at = $d.toDateString()
            // $scope.EstimatedTime = data.EstimatedTime;
            // $scope.projectManager = data.projectManager.name;

            $scope.jobcostingstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.jobcostings.length,
                    counts: [10, 20, 50, 100,$scope.jobcostings.length],
                    getData: function( params) {
                      params.total($scope.jobcostings.length);
                    $scope.data = $scope.jobcostings.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            }).error(function(data, status) {
                alert('something went wrong');
            });
        }
      // $rootScope.calculateRate = function(budget,projectId) {
      //   console.log(budget)
      //   console.log(projectId)
      //    }

       $scope.Rate = "";
        // $scope.albumNameArray = [];
      $scope.Totalperday = 0;
      $scope.Totalperhour = 0;
        $scope.calculateRate = function (rate,projectId) {
      $scope.Rate = rate;
       
        console.log($scope.Rate );
      ProjectService.getProjectUsers(projectId).success(function(data, status) {  
      $scope.project_users = data.project_users;
       BudgetService.getBudgets(projectId).success(function(data, status) {  
            $scope.EstimatedTimeDayRate = data.EstimatedTimeDayRate;
            $scope.EstimatedTimeHourRate = data.EstimatedTimeHourRate;
   if ($scope.Rate == "Day") {
 
    angular.forEach($scope.project_users,function(key,value){
          console.log(key);
            if(value){
                 var perday = key['dvalue']*$scope.EstimatedTimeDayRate;
                  $scope.Totalperday = $scope.Totalperday + key['dvalue']*$scope.EstimatedTimeDayRate;
              }
        });
                // $scope.albumNameArray.push($scope.Totalperday)
        console.log($scope.Totalperday);

   } 
   if ($scope.Rate == "Hour") {
      angular.forEach($scope.project_users,function(key,value){
          // console.log(value);
            if(value){
                 var perhour = key['value']*$scope.EstimatedTimeHourRate;
                  $scope.Totalperhour = $scope.Totalperhour + key['dvalue']*$scope.EstimatedTimeHourRate;
        console.log($scope.EstimatedTimeHourRate);
             
            }
        });
            // $scope.albumNameArray.push($scope.Totalperhour)
        console.log($scope.Totalperhour);

        };
       
      }).error(function(data, status) {
        alert('something went wrong');
      });
    
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

    };
    
$scope.totalBudgetLabour = function(){
        console.log($scope.Totalperhour);
        console.log($scope.Totalperday);

 
}

      $rootScope.saveJobcosting = function(projectId,action) {

        var startDate = new Date($('#jobstartDate').val());
        var endDate = new Date($('#jobendDate').val());
      
       // console.log(budget.item);
       if (startDate==null || endDate==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
            BudgetService.saveJobcosting(startDate,endDate,projectId).success(function(response) {
            // location.reload();
            if (action == 'close') {
               notify({
                     message:"Jobcosting Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      // $scope.budget = "";
                    notify({
                     message:"Jobcosting Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                }
      }).error(function(response) {
        notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      });
       }
       // console.log($scope.total);
    
      }

            $rootScope.editJobcosting = function(projectId,jobcostingId) {

        var startDate = new Date($('#editjobstartDate').val());
        var endDate = new Date($('#editjobendDate').val());
      
       console.log(projectId);
       console.log(jobcostingId);
       console.log(startDate);
       console.log(endDate);
       if (startDate==null || endDate==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
            BudgetService.editJobcosting(startDate,endDate,projectId,jobcostingId).success(function(response) {
            // location.reload();
               notify({
                     message:"Jobcosting Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
         
      }).error(function(response) {
        notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      });
       }
       // console.log($scope.total);
    
      }

      $rootScope.saveBudget = function(budget,projectId,action) {
       var total = $scope.total;
       var item = $scope.budget.item;
       var price = $scope.budget.price;
       var unit_id = $scope.budget.unit_id;
       var quantity = $scope.budget.quantity;
       // console.log(budget.item);
       if (budget==null || item==null || price==null || unit_id==null || quantity==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
            BudgetService.saveBudget(budget,projectId,total).success(function(response) {
            // location.reload();
            if (action == 'close') {
               notify({
                     message:"Budget Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.budget = "";
                    notify({
                     message:"Budget Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                }
      }).error(function(response) {
        notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      });
       }
       // console.log($scope.total);
    
      }
            
      $rootScope.updateBudget = function(budget,projectId) {
       var edittotal = $scope.edittotal;
       var item = $scope.budget.item;
       var price = $scope.budget.price;
       var unit_id = $scope.budget.unit_id;
       var quantity = $scope.budget.quantity;
       // console.log(item);
          if (budget==null || item==null || price==null || unit_id==null || quantity==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else{
             BudgetService.updateBudget(budget,projectId,edittotal).success(function(data, status) {
        // $scope.budget =data.budget;
        location.reload();
        // console.log($scope.milestone );
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
       }
    }

  $scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id,modelname)
  }
	}); 

  