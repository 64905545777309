'use strict';
 main.controller('CompaniesController',	function($rootScope,$stateParams,TaskService,UserService,CompanyService,globalService, $scope,$http, actions, $modal, $log, notify, modalService,$upload,NgTableParams) {
    $scope.companyId=$stateParams.companyId;
    $scope.id=$stateParams.id;

       $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}

  $('#companiestartDate').datepicker({
        uiLibrary: 'bootstrap',
        });
   $('#companiesendDate').datepicker({
        uiLibrary: 'bootstrap',
        });

      CompanyService.myCompany($scope.companyId).success(function(data, status){  
      $rootScope.company = data.company;
      $rootScope.companyUsers = data.companyUsers;
      $scope.role = data.role;
      $scope.company.contact_phone = Number(data.company.contact_phone);
      console.log(data.role);
      }).error(function(data, status) {
        alert('something went wrong');
      });
      // CompanyService.getCompany($scope.companyId).success(function(data, status){  
      // $rootScope.company = data;
      // $scope.role = data.role;
      // $scope.company.contact_phone = Number(data.contact_phone);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
      $rootScope.getCompany = function(companyId) {
        console.log(companyId);
      CompanyService.getCompany(companyId).success(function(data, status){  
      $rootScope.company = data;
      $scope.role = data.role;
      $scope.company.contact_phone = Number(data.contact_phone);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    }

       $rootScope.ChangeCompany = function(companyId) {
        // console.log(companyId);
      CompanyService.ChangeCompany(companyId).success(function(data, status){  
      $rootScope.company = data;
      $scope.role = data.role;
      $scope.company.contact_phone = Number(data.contact_phone);
      location.reload();
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      // CompanyService.getCompany(companyId).success(function(data, status){  
      // $rootScope.company = data;
      // $scope.role = data.role;
      // $scope.company.contact_phone = Number(data.contact_phone);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
    }

        TaskService.getTasks().success(function(data, status) {   
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
         // $scope.files = data.files;
         //  $scope.filestable = new NgTableParams({
         //            page: 1,
         //            count: 10,

         //            // sorting: { effective_dt: "asc" },
         //        },
         //        {
         //            total: $scope.files.length,
         //            counts: [10, 20, 50, 100,$scope.files.length],
         //            getData: function( params) {
         //            params.total($scope.files.length);
         //            $scope.data = $scope.files.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
         //            return $scope.data;
   
         //            }
         //        // $scope: $scope
         //    });
          // $scope.tasks = data.tasks;
          // $scope.taskstable = new NgTableParams({
          //           page: 1,
          //           count: 10,
          //            // filter: { task: "" }, 
          //           // sorting: { task: "asc", created_by: "asc", task_type: "asc",assigned_to: "asc" },
          //       },
          //       {
          //           total: $scope.tasks.length,
          //           counts: [10, 20, 50, 100,$scope.tasks.length],
          //           getData: function (params) {
          //             params.total($scope.tasks.length);
          //               // params.total($filter('filter')($scope.tasks.length));
          //               //   $scope.data = $filter('filter')( $scope.SearchedItem);
          //                $scope.data =  $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());
          //                   return $scope.data;
          //                }
         
          //   });
        // $scope.tasks = data.tasks;
        // $scope.projecttasks = data;
        // $scope.tasksArchived = data.tasksArchived;
         // $scope.tasksArchived = new NgTableParams({}, { dataset:data.tasksArchived })
         $scope.tasksTodeadline = data.tasksTodeadline;
         // console.log(data.tasksTodeadline);
          $scope.tasksTodeadlinetable = new NgTableParams({
                    page: 1,
                    count: 4,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.tasksTodeadline.length,
                    // counts: [10, 20, 50, 100,$scope.tasksTodeadline.length],
                    getData: function( params) {
                      params.total($scope.tasksTodeadline.length);
                    $scope.data = $scope.tasksTodeadline.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log($scope.tasks);
      
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

     CompanyService.getRoles().success(function(data, status) {   
        
        $scope.roles = data.roles;
        $scope.role = data.role;
        // console.log(data.role)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

      CompanyService.getProjects().success(function(data, status) {   
        
        $scope.projects = data.projects;
        $scope.projectsStatus = data.projectcount;
        // $scope.projectsInprogress = data.projectsInprogress;
        // console.log(data.roles)   
    new Chart(document.getElementById("doughnut"), {
    type: 'doughnut',
    data: {
      labels: ["In Progress", "Completed"],
      datasets: [{
        label: "",
        backgroundColor: ["#f7941e", "#00a79d"],
        data: data.projectcount
      }]
    },
    // options: {
    //   title: {
    //     display: false,
    //     text: ''
    //   }
    // }
     options: {
      legend: {
            labels: {
                fontColor: "white",
                fontSize: 15
            }
        },
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI
    }
  });
        
      }).error(function(data, status) {
        alert('something went wrong');
      });



      CompanyService.getCompanies().success(function(data, status) {    
        $scope.companies = data.companies;


        // console.log(data.companies) 
        $scope.companiestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.companies.length,
                    counts: [10, 20, 50, 100,$scope.companies.length],
                    getData: function( params) {
                      params.total($scope.companies.length);
                    $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });       
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

            $rootScope.activateDeactivate = function (companyId,actDeaId) {
    CompanyService.activateDeactivate(companyId,actDeaId).success(function(data, status) {   
        // $rootScope.profile = data;
     $scope.companies = data.companies;


        // console.log(data.companies) 
        $scope.companiestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.companies.length,
                    counts: [10, 20, 50, 100,$scope.companies.length],
                    getData: function( params) {
                      params.total($scope.companies.length);
                    $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });       
          
      }).error(function(data, status) {
         alert('something went wrong');
      }); 
     }



 CompanyService.getUsers().success(function(data, status) {    
        $scope.users = data.users;
        $scope.role = data.role;
        $scope.allUsers = data.allUsers;
        $scope.CompUsers = data.CompUsers;
        $scope.Admins =data.Admins;
        $scope.SuperAdmins =data.SuperAdmins;
        $scope.allSuperUsers = data.allSuperUsers;
        console.log(data.Admins)       

    var chart1 = new Chart(document.getElementById("bar"), {
    type: 'bar',
    data: {
      labels: ["Activated Users", "Deactivated Users","Admins"],
      datasets: [{
        label: "Users",
        backgroundColor: ["#f7941e", "#00a79d","#262262"],
        data: data.allUsers
      }]
    },
    options: {
       legend: {
            display: true,
            labels: {
                fontColor: 'white',
                fontSize: 15

            }
        },
  scales: {
   xAxes: [{ 
                gridLines: {
                    display: true,
                },
                ticks: {
                  fontColor: "#fff", // this here
                fontSize: 15

                },
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    // color: "#fff", // this here
                },
                 ticks: {
                  fontColor: "#fff", // this here
                fontSize: 15  
                },
            }],
        }
      }
  });

  var chart2 = new Chart(document.getElementById("bar1"), {
    type: 'bar',
    data: {
      labels: ["Activated Users", "Deactivated Users","Admins", "Super Admins"],
      datasets: [{
        label: "Users",
        backgroundColor: ["#f7941e", "#00a79d","#262262","#fff"],
        data: data.allSuperUsers
      }]
    },
    options: {
       legend: {
            display: true,
            labels: {
                fontColor: 'white',
                fontSize: 15

            }
        },
  scales: {
   xAxes: [{ 
                gridLines: {
                    display: true,
                },
                ticks: {
                  fontColor: "#fff", // this here
                fontSize: 15

                },
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    display: true,
                    // color: "#fff", // this here
                },
                 ticks: {
                  fontColor: "#fff", // this here
                fontSize: 15  
                },
            }],
        }
      }
  });

    var chart = AmCharts.makeChart( "chartdiv", {
  "type": "funnel",
  "theme": "light",
  "dataProvider": $scope.CompUsers, 
  "balloon": {
    "fixedPosition": true
  },
  "valueField": "value",
  "titleField": "title",
  "marginRight": 240,
  "marginLeft": 50,
  "startX": -500,
  "depth3D": 100,
  "angle": 30,
  "outlineAlpha": 1,
  "outlineColor": "#FFFFFF",
  "outlineThickness": 0.5,
  "labelPosition": "right",
  "balloonText": "[[title]]: [[value]]",
  "export": {
    "enabled": false
  }
  
   } );

        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        //disply modalform.
        $scope.toggleCompany = function(modalstate, id) {
        $scope.modalstate = modalstate;
           CompanyService.toggleCompany(id).success(function(data, status) {  
      $scope.company = data.company;
      $scope.role = data.role;
      $scope.package = data.package;
      $scope.company.contact_phone = Number(data.company.contact_phone);
       $scope.date1 = data.company.start_date;
      $scope.date2 = data.company.end_date;
          $('#companiesupdatetartDate').datepicker({
        uiLibrary: 'bootstrap',
            setValue: data.company.start_date
        });
          $('#companiesupdateendDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.company.end_date
        });
      })
        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Company";
                $('#myModal').modal('show');
                break;
            case 'edit':
                $scope.form_title = "Edit Company";
                $('#myModal1').modal('show');
                break;
            case 'adduser':
                $scope.form_title = "Add Member";
                $scope.user={company_id:id};
                $('#myModal2').modal('show');
                break;
 
        }
        // console.log(id)
         
    }


   

     // save new record & update existing record
    // $rootScope.save = function(modalstate,id,file) {
    //   console.log(file);
    //     var url = "companies";
    //     $http({
    //         method: 'POST',
    //         url: url,
    //         data: $.param($scope.company),
    //         headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    //     }).success(function(response) {
    //         location.reload();
    //     }).error(function(response) {
    //         console.log(response);
    //         alert('An error has occured. Please check the log for details');
    //     });

    // }
     $('#birthDate').datepicker({
            uiLibrary: 'bootstrap',
        
        });
 $scope.onFileSelect = function($files){
    // console.log($files);
   //$scope.file = $files;
   $scope.datalogo={};
     //$scope.file = {};
        for (var i = $files.length - 1; i >= 0; i--){
             $scope.datalogo['file'+i]=$files[i];
        }
    $scope.datalogo['filecount']=$files.length;
    console.log($scope.datalogo);
   }
        

    $scope.saveCompany = function(company,action) {
  console.log(action);
    
     var startDate = $('#companiestartDate').val();
        var endDate = $('#companiesendDate').val();

    if ($scope.datalogo) {
       $scope.datalogo['startDate']=startDate;
    $scope.datalogo['endDate']=endDate;
    $scope.datalogo['company']=company;
    console.log($scope.datalogo);

           $scope.upload = $upload.upload({
                url: 'companies',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datalogo,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'Close') {
                    notify({
                     message:"Company Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.company = "";
                      notify({
                     message:"Company Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {

             var cname = $scope.company.cname;
          var website = $scope.company.website;
         var contact_phone = $scope.company.contact_phone;
         var package = $scope.company.package;
       // console.log(budget.item);
        if ( cname==null || website==null || contact_phone==null  || package==null || startDate==null || endDate==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
               CompanyService.saveCompany(company,startDate,endDate,action).success(function(response) { 
                  if (action == 'Close') {
                    notify({
                     message:"Company Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.company = "";
                      notify({
                     message:"Company Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                } 
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
          } 
            // location.reload();  
  // console.log($scope.data);
        };

    // $scope.submit = function(file, company) {

    //         if (file) {
    //             $scope.file = file;
    //             $scope.upload($scope.file, company);
    //         }
    //     };
              
  //   $scope.upload = function(file, company,action) {
  //         var cname = $scope.company.cname;
  //         var website = $scope.company.website;
  //        var contact_phone = $scope.company.contact_phone;
      
  //      // console.log(timelog.bilable);
  //      if ( cname==null || website==null || contact_phone==null) {
  //        notify({
  //             message:"Please Fill All The Fields Correct.",
  //             template:'templates/error.html',
  //             position:'center'
  //           });
  //      } else {
  //               $scope.upload = $upload.upload({
  //               url: 'companies',
  //               data: {myObj: $scope.myModelObj,'company':company},
  //               file: $scope.file
  //           }).then(function(resp) {
  //                     if (action == 'close') {
  //                   notify({
  //                    message:"Task Added successful.",
  //                    template:'templates/success.html',
  //                    position:'center'
  //                     });
  //                   location.reload();
  //               } else {
  //                     $scope.company = "";
  //                     notify({
  //                    message:"Task Added successful.",
  //                    template:'templates/success.html',
  //                    position:'center'
  //                     });
                      
  //                     // $('#taskstartDate').val();
  //               }
  //           }, function(resp) {
  //                     notify({
  //             message:"Please Fill All The Fields Correct.",
  //             template:'templates/error.html',
  //             position:'center'
  //           });
  //           }, function(evt) {
  //               // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
  //           });
  //   //   $scope.upload = $upload.upload({
  //   //   url: 'companies',
  //   //   data: {myObj: $scope.myModelObj},
  //   //   file: $scope.file
  //   // });
  //   // console.log($scope.company);
  //   }
  // };

   

    $rootScope.save = function(user,id,action) {
         var birthDate = $('#birthDate').val();

       if ($scope.dataprofile) {
    $scope.dataprofile['user']=user;
    $scope.dataprofile['birthDate']=birthDate;
    console.log($scope.dataprofile);

           $scope.upload = $upload.upload({
                url: 'users',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'Close') {
                    location.reload();
                } else {
                      $scope.user = "";
                      notify({
                     message:"User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
       var url = "users";
       var name = $scope.user.name;
       var email = $scope.user.email;
       var password = $scope.user.password;
       var gender = $scope.user.gender;
       var role_id = $scope.user.role_id;
       var nationality = $scope.user.nationality;
       var marital_status = $scope.user.marital_status;
      
       if (user==null || name==null || email==null || password==null || role_id==null || gender==null || nationality==null || marital_status==null || birthDate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {

          UserService.save(user,id,birthDate).success(function(data, status) {   
        // $scope.user = data.user;
         if (action == 'close') {
                notify({
                     message:"User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
            // console.log("nimefika");
                      $scope.user = ""; 
                        notify({
                     message:"User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });     
                }
      }).error(function(data, status) {
        alert('something went wrong');
      });
      }
   }
    }
    
     
  // $rootScope.updateC = function(modalstate,id) {
  //       var url = "updateC";
  //       $http({
  //           method: 'POST',
  //           url: url,
  //           data: $.param($scope.company),
  //           headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  //       }).success(function(response) {
  //           console.log(response);
  //           location.reload();
  //       }).error(function(response) {
  //           console.log(response);
  //           alert('An error has occured. Please check the log for details');
  //       });

  //   }
      $rootScope.updateC = function(company) {

        var startDate = $('#companiesupdatetartDate').val();
        var endDate = $('#companiesupdateendDate').val();

      if ($scope.datalogo) {
           $scope.datalogo['startDate']=startDate;
    $scope.datalogo['endDate']=endDate;
    $scope.datalogo['company']=company;

           $scope.upload = $upload.upload({
                url: 'updateC',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datalogo,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
            location.reload();
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          } else {
          var cname = $scope.company.cname;
          var website = $scope.company.website;
         var contact_phone = $scope.company.contact_phone;
         var package = $scope.company.package;
       // console.log(budget.item);
        if ( cname==null || website==null || contact_phone==null || package==null || startDate==null || endDate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
               CompanyService.updateC(company,startDate,endDate).success(function(response) { 
               location.reload();
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
    }
    }

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id, modelname)
  }

    $scope.removelogo = function(logoId,id){
  console.log(id);
    CompanyService.removelogo(logoId,id).success(function(data, status) {   
        $scope.company = data.company;
      }).error(function(data, status) {
        alert('something went wrong');
      });
  }

	}); 

  