'use strict';
 main.controller('TasksController', function($scope,$rootScope,TaskService,ProjectService,DepartmentService,UnitService,TimelogService ,$stateParams,globalService, $scope,$http, actions, $modal, $filter, $log, notify, modalService,$upload,NgTableParams) {
 


    // console.log($scope.tasks);

   $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
   }

         $scope.statusid=$stateParams.statusid; 
         $scope.projectId=$stateParams.projectId;   
         $scope.taskId=$stateParams.taskId;   
         $scope.milestoneId=$stateParams.milestoneId;   
		     
         // $scope.getAllTasks = function(){
         //     TaskService.getAllTasks().success(function(data, status) {         
         //    $scope.alltasks = data.alltasks;
         //         console.log( $scope.alltasks);
         //    }).error(function(data, status) {
         //        alert('something went wrong');
         //    }); 
         // }
         // $scope.tasks = new NgTableParams({}, {dataset:[]});
            // $scope.tasks= globalService.intiateNgTable(TaskService.getTasks(), $scope);
         $scope.IsVisible = false;


          $scope.tasks = function (projectId) {
        //  $('select').selectpicker();

                //   window.onscroll = function() {myFunction()};
                // var header = document.getElementById("myHeader");
                // var sticky = header.offsetTop;

                // function myFunction() {
                //   if (window.pageYOffset > sticky) {
                //     header.classList.add("sticky");
                //   } else {
                //     header.classList.remove("sticky");
                //   }
                // }
     

        $rootScope.display = function (displayIsactive) {
        $scope.displayIsactive = displayIsactive;
         $scope.IsVisible = true;
         console.log($scope.IsVisible);
         }
            // console.log(projectId);
          // $scope.projectTask  = function (projectId) {                  
  
        }

          $scope.uiConfig = {
                calendar:{
                height: 450,
                editable: true,
                header:{
                  left: 'month basicWeek basicDay agendaWeek agendaDay',
                  center: 'title',
                  right: 'today prev,next'
                },
                eventClick: $scope.alertEventOnClick,
                eventDrop: $scope.alertOnDrop,
                eventResize: $scope.alertOnResize
                }
              };

        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('#startDate').datepicker({
            uiLibrary: 'bootstrap',
            minDate: today,
           change: function (e) {

         },
            maxDate: function () {
                return $('#endDate').val();
            }
        });
      
     
         $('#taskstartDate').datepicker({
            uiLibrary: 'bootstrap',
            minDate: today,
           change: function (e) {

         },
            maxDate: function () {
                return $('#taskendDate').val();
            }
        });
              $('#taskendDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            minDate: function () {
                return $('#taskstartDate').val();
            }
        });
    
     
        $('#endDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            minDate: function () {
                return $('#startDate').val();
            }
        });
      
        $scope.callme =function(start_time,end_time,breakstart_time,breakend_time){
          // console.log(breaktime );
     //        var start_Time =$rootScope.start_time;
     // var end_Time = $rootScope.end_time;
      // var break_time = $scope.hourbreak_time;
     // var minutesbreak_time =  $scope.minutesbreak_time;
     // var rate_value =  $scope.rate.value;

    
     var startTime=moment(start_time, "HH:mm:ss a");
     var breakstartTime=moment(breakstart_time, "HH:mm:ss a");
     var endTime=moment(end_time, "HH:mm:ss a");
     var breakendTime=moment(breakend_time, "HH:mm:ss a");
     // var breakTime=moment(breaktime, "HH:mm:ss a");
    
    

     var duration = moment.duration(endTime.diff(startTime));
     var duration2 = moment.duration(breakendTime.diff(breakstartTime));
    $scope.result = (0+":"+ 0);

     if (duration > duration2) {
      var duration3 = moment.duration(duration - duration2);
       $scope.timedecimal = moment.duration(duration3).asHours();
       var hours = parseInt(duration3.asHours());
     var minutes = parseInt(duration3.asMinutes())-hours*60;
    $scope.result = (hours+":"+ minutes);

     }else{
       $scope.timedecimal = 0;

     }
     
     // var duration4 = moment.utc(moment.duration(duration3, "seconds").asMilliseconds()).format("HH:mm");

     // var duration2 = moment.duration(duration1.diff(breakTime));

     // var duration2 = moment(breakTime, "HH:mm:ss a");
    

     // var hours2 = parseInt(duration2.asHours());
     // var minutes2 = parseInt(duration2.asMinutes())-hours2*60;

     // var hours = hours1 - hours2;
     // var minutes = minutes1 - minutes2;

    
   

     // console.log(hours1)
    // console.log(minutes1)
     // var hours1 = Number(hours - hourbreak_time)-1;
     // var minutes1 = Number(minutes - minutesbreak_time) + 60;
    return $scope.result;
     
      }
        

      //  $('#input_endtime').on('changeTime.timepicker', function(e) {
      //   $('#timeDisplayend').text(e.time.value);
      //   end = e.time.value;
      //   $rootScope.end_time = e.time.value;
      //   $scope.callme();
      // });


  $('#input_breaktime').timepicker({
   minuteStep: 1,
          template: 'modal',
          appendWidgetTo: 'body',
          // showSeconds: true,
          showMeridian: false,
          defaultTime: false
  });
       setTimeout(function() {
    $('#timeDisplaybreaktime').text($('#input_breaktime').val());
      }, 100);     
   //      setTimeout(function() {
   //  $('#timeDisplayend').text($('#input_endtime').val());
   //    }, 100);
       var start = "";
   //     var end = "";
      $('#input_breaktime').on('changeTime.timepicker', function(e) {
        $('#timeDisplaybreaktime').text(e.time.value);
        // console.log(e.time.value);
       start = e.time.value;
        $rootScope.breaktime = e.time.value;
        $scope.callme();

      });
           
       
     
          
         
        $rootScope.saveMilestone = function(milestone,projectId,companyId,action) {
        var startDate = $('#startDate').val();
        var endDate = $('#endDate').val();
        // var title = $scope.milestone.title;
       // console.log(task);
        if ( $scope.milestone.title==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
        // console.log(startDate);
       TaskService.saveMilestone(milestone,projectId,companyId,startDate,endDate).success(function(response) {
            // location.reload();
            if (action == 'close') {
               notify({
                     message:"Milestone Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.milestone = "";
                       notify({
                     message:"Milestone Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                }
      }).error(function(response) {
        notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      }); 
      }
    }

      function toTime(timeString){
    var timeTokens = timeString.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
}




        $scope.toggleTaskEdit = function(modalstate,id,projectId) {
           $scope.timeDifference = function () {
            var start_Time = new moment($scope.start_time).format("HH:mm:ss a");
           var end_Time = new moment($scope.end_time).format("HH:mm:ss a");
            var hourbreak_time = $scope.hourbreak_time;
           var minutesbreak_time =  $scope.minutesbreak_time;

          // console.log($scope.hourbreak_time)
          
           var startTime=moment(start_Time, "HH:mm:ss a");
           var endTime=moment(end_Time, "HH:mm:ss a");
          
           var duration = moment.duration(endTime.diff(startTime));
           var hours = parseInt(duration.asHours());
           var minutes = parseInt(duration.asMinutes())-hours*60;

           var hours1 = Number(hours - hourbreak_time)-1;
           var minutes1 = Number(minutes - minutesbreak_time) + 60;

           if (minutes1>= 60) {
               var hr = hours1+1;
               var minutes1 = 00;
            $scope.result = (hr+":"+ minutes1);
           }
           else {
             $scope.result = (hours1+":"+ minutes1);
           }
             if (hr < 0 || hours1 < 0) {
            alert('Break time can not exceed Total spent hours'); 
            return;   
              }else {
            $scope.result = $scope.result;
              }
         
          }
        $scope.modalstate = modalstate;

      

      TaskService.toggleTimelogEdit(id,projectId).success(function(data, status) {  
      $scope.project = data.project;
      $scope.project_user = data.project_user;
      $scope.task = data.task;
      $scope.company_users = data.company_users;
    
      $scope.timelog = data.timelog;
      // console.log($scope.date1);
      $scope.start_time = toTime(data.timelog.start_time);
      $scope.end_time = toTime(data.timelog.end_time);
      $scope.date = data.timelog.date;
      $scope.hourbreak_time = Number(data.timelog.hrbreak_time);
      $scope.minutesbreak_time = Number(data.timelog.minbreak_time);
     if (data.timelog.bilable == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }
       $('#editselectdate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.timelog.date
        });
      })

      TaskService.toggleMilestoneEdit(id,projectId).success(function(data, status) {  
      $scope.project = data.project;
      $scope.project_user = data.project_user;
      $scope.task = data.task;
      $scope.company_users = data.company_users;
      $scope.milestone = data.milestone;
       $scope.date3 = data.milestone.start_date;
      $scope.date4 = data.milestone.end_date;
      // console.log($scope.date1);
  
          $('#UpdatestartDate').datepicker({
             uiLibrary: 'bootstrap',
            setValue: data.milestone.start_date
        });
          $('#UpdateendDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.milestone.end_date
        });
      })

      TaskService.toggleTaskEdit(id,projectId).success(function(data, status) {  
      $scope.project = data.project;
      $scope.project_user = data.project_user;
      $scope.task = data.task;
      $scope.attachments = data.attachments;
      // $scope.file = data.attachments.name;
      // console.log($scope.attachments);
      console.log(data.task);
      $scope.date1 = data.task.start_date;
      $scope.date2 = data.task.end_date;
      $scope.company_users = data.company_users;
      $scope.milestone = data.milestone;
      // $scope.budget = data.budget;
  
          $('#taskUpdatestartDate').datepicker({
        uiLibrary: 'bootstrap',
            setValue: data.task.start_date
        });
              $('#taskUpdateendDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.task.end_date
        });
      })
        switch (modalstate) {
                case 'edit':
                $scope.form_title = "Edit Task";
                 $scope.task={taskId:id};
                $('#myModal1').modal('show');
                break;
                 case 'editMilestone':
                $scope.form_title = "Edit Milestone";
                $scope.milestone={milestoneId:id};
                $('#myModal3').modal('show');
                break;
                 case 'editTimelog':
                $scope.form_title = "Edit Timelog";
                $scope.timelog={timelogId:id};
                $('#myModal8').modal('show');
                break;
                
 
        }
         
    }

      $rootScope.SearchItem = function(SearchedItem){
                 $scope.SearchedItem = SearchedItem;
                 console.log(SearchedItem);
                $scope.taskstable.reload();
             }

          // $('.selectpicker').selectpicker('val', 'projectId');
          // $('#myselect').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            // do something...
            // console.log('ndani');
          // });
          // $('select').selectpicker();
          // $(function() {

 

          //   $('select').on('change', function(e){
          //     // var targets = [];
          //     // $.each($(".selectpicker option:selected"), function(){
          //     // targets.push($(this).val());
          //     // });
          //     // alert("You have selected the targets - " + targets.join(", "))
          //     // console.log(e);
          //     // console.log(this.value,
          //     //             this.options[this.selectedIndex].value,
          //     //             $(this).find("option:selected").val(),);
          //                 return $scope.projectTask($(this).find("option:selected").val());
          //   });
            
          // });


    $scope.projectTask  = function (projectId) {  

      // console.log(projectId);
    TaskService.getTasks(projectId).success(function(data, status) {       

        $scope.projecttasks = data;
         // $scope.tasksArchived = new NgTableParams({}, { dataset:data.tasksArchived })
        // $scope.tasksArchived = data.tasksArchived;
          $scope.files = data.files;
          $scope.filestable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.files.length,
                    counts: [10, 20, 50, 100,$scope.files.length],
                    getData: function( params) {
                    params.total($scope.files.length);
                    $scope.data = $scope.files.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
         $scope.tasksArchived = data.tasksArchived;
          $scope.tasksArchivedtable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.tasksArchived.length,
                    counts: [10, 20, 50, 100,$scope.tasksArchived.length],
                    getData: function( params) {
                    params.total($scope.tasksArchived.length);
                    $scope.data = $scope.tasksArchived.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }
       $scope.taskcategory = {};
           $scope.taskcategory.categoryId = "1";
           $scope.taskcategory.Categories = [{
             id: "1",
             name: "All Tasks"
           }, {
             id: "2",
             name: "My Tasks"
           },{
             id: "3",
             name: "Favourites"
           }, {
             id: "4",
             name: "Overdue"
           }];
     if ($scope.taskcategory.categoryId !== "1") {
     $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;
      // console.log( $scope.taskcategory.categoryId );

      }else {
        $scope.taskcategory.categoryId = "1";

      }
      

        // $scope.taskcategory.Categories = [{
        //   id: "1",
        //   name: "All Tasks"
        // }, {
        //   id: "2",
        //   name: "My Tasks"
        // },{
        //   id: "3",
        //   name: "Favourites"
        // }, {
        //   id: "4",
        //   name: "Overdue"
        // }];

    TaskService.taskCategory(projectId,$scope.taskcategory.categoryId).success(function(data, status) {   
        $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;

                 // console.log($scope.taskcategory.categoryId);

         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
          $scope.tasks = data.tasks;
            // console.log(data.tasks);
          
          // $scope.currentprojectname = data.tasks.pname;
            // console.log($scope.currentprojectname);

          $rootScope.CreateHeader = function(pname) {
          showHeader = (pname!=$scope.currentprojectname); 
          $scope.currentprojectname = pname;
            // console.log($scope.currentprojectname);
          
          return showHeader;
          }
            // $scope.userId = data.userId;
              $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // filter: { task: "" }, 
                    // sorting: { task: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
                    }
                // $scope: $scope
            });

      TaskService.getTasksCatNew(projectId,$scope.taskcategory.categoryId,'New').success(function(data, status) {           
        $scope.tasksnew = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatClosed(projectId,$scope.taskcategory.categoryId,'Closed').success(function(data, status) {   
        $scope.tasksClosed = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatInProgress(projectId,$scope.taskcategory.categoryId,'In Progress').success(function(data, status) {          
        $scope.tasksInProgress= data.statusesCat;
                 console.log(  $scope.tasksInProgress);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatResolved(projectId,$scope.taskcategory.categoryId,'Resolved').success(function(data, status) {         
        $scope.tasksResolved = data.statusesCat;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            });
      }).error(function(data, status) {
        alert('something went wrong');
      });
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        
         TaskService.updateprojectcache(projectId).success(function(data, status) {         
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
    
       TaskService.getMilestones(projectId).success(function(data, status) {         
         // $scope.milestones = new NgTableParams({}, { dataset:data.milestones })
         $scope.milestones = data.milestones;
          $scope.milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.milestones.length,
                    counts: [10, 20, 50, 100,$scope.milestones.length],
                    getData: function( params) {
                      params.total($scope.milestones.length);
                    $scope.data = $scope.milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

    
                 // console.log( $scope.milestones);
            }).error(function(data, status) {
                alert('something went wrong');
            });
             TaskService.getTasksNew('New',projectId,$scope.milestoneId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
        $scope.tasksnewmil = data.task_milestones_status;
        $scope.tasksnewarch = data.archv_statuses;

                 // console.log(  $scope.tasksnewmil);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksClosed('Closed',projectId,$scope.milestoneId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
        $scope.tasksClosedmil = data.task_milestones_status;
        $scope.tasksClosedarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId,$scope.milestoneId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
        $scope.tasksInProgressmil= data.task_milestones_status;
        $scope.tasksInProgressarch = data.archv_statuses;
        
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId,$scope.milestoneId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
        $scope.tasksResolvedmil = data.task_milestones_status;
        $scope.tasksResolvedarch = data.archv_statuses;
                 
            }).error(function(data, status) {
                alert('something went wrong');
            });  

          }

          $scope.folder2 = {};
    $scope.albumNameArray2 = [];
// $scope.checkall = function(checked) {
//  if (checked == true) {
//     TaskService.getTask($scope.taskId).success(function(data, status){  
//       $scope.project_users = data.project_users;
// console.log($scope.project_users)

//       }).error(function(data, status) {
//                 alert('something went wrong');
//             });  
//  }else if (checked == false){
// console.log('sipo')
//  }
// };



      
      $rootScope.savecomment = function(comment,taskId,tasksassign,priorityId,statusId) {
        // console.log(notifyArray);
          // $scope.datatasks={};
    //  //$scope.file = {};
    //     for (var i = $scope.folder2.length - 1; i >= 0; i--){
    //          $scope.datatasks['file'+i]=$files[i];
    //     }
    // $scope.datatasks['filecount']=$files.length;
    // console.log($scope.datatasks);
          angular.forEach($scope.folder2,function(key,value){
            if(key){
                $scope.albumNameArray2.push(value)
              }
        });
  //          for (var i = $scope.albumNameArray2.length - 1; i >= 0; i--){
  //            $scope.datatasks['id'+i]=$scope.albumNameArray2[i];
  //       }
  //   $scope.datatasks['filecount']=$scope.albumNameArray2..length;
  //          // $scope.albumNameArray2['filecount']=$scope.folder2.length
  // console.log($scope.albumNameArray2.length);
  // console.log($scope.datatasks);
        
        if (statusId == '0') {

    if ($scope.datatasks) {
    $scope.datatasks['comment']=comment;
      $scope.datatasks['tasksassign']=tasksassign;
    $scope.datatasks['notify']=$scope.albumNameArray2;
    $scope.datatasks['priorityId']=priorityId;
    $scope.datatasks['taskId']=taskId;
        console.log($scope.datatasks);

              $scope.upload = $upload.upload({
                url: 'savecomment',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,

                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).success(function(data, status) {
            $scope.comments = data.comments;
            // $scope.commentfile = data.commentfile;
           $scope.tasksDetails = data.tasksDetails;
           $scope.tasksData = data.tasksData;
            $scope.tasksassign = data.tasksassign;
           $scope.comment ="";
            $scope.albumNameArray2 = [];     
            });
        } else {
      TaskService.savecomment(comment,taskId,tasksassign,priorityId,statusId,$scope.albumNameArray2).success(function(data, status) {  
            $scope.comments = data.comments; 
            $scope.tasksDetails = data.tasksDetails;
            $scope.tasksData = data.tasksData;
            $scope.tasksassign = data.tasksassign; 
            $scope.comment = ""; 
            $scope.albumNameArray2 = [];         
              }).error(function(data, status) {
                alert('something went wrong');
          });
    }
    
        // console.log($scope.datatasks);
     
         
  
        } else {
  
    //       $scope.datatasks['comment']=comment;
    // $scope.datatasks['taskId']=taskId;
    // $scope.datatasks['statusId']=statusId;
    // $scope.datatasks['tasksassign']=tasksassign;
    // // $scope.datatasks['statusid']=statusid;
    // $scope.datatasks['priorityId']=priorityId;

    if ($scope.datatasks) {
    $scope.datatasks['comment']=comment;
      $scope.datatasks['tasksassign']=tasksassign;
    $scope.datatasks['statusid']=statusId;
    $scope.datatasks['notify']=$scope.albumNameArray2;

    $scope.datatasks['priorityId']=priorityId;
    $scope.datatasks['taskId']=taskId;
        console.log($scope.datatasks);

              $scope.upload = $upload.upload({
                url: 'savecomment',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data: $scope.datatasks,

                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).success(function(data, status) {
            $scope.comments = data.comments;
            // $scope.commentfile = data.commentfile;
           $scope.tasksDetails = data.tasksDetails;
           $scope.tasksData = data.tasksData;
            $scope.tasksassign = data.tasksassign;
           $scope.comment = "";
            $scope.albumNameArray2 = [];     

            });
  } else {
      TaskService.savecomment(comment,taskId,tasksassign,priorityId,statusId,$scope.albumNameArray2).success(function(data, status) {  
            $scope.comments = data.comments; 
             $scope.tasksDetails = data.tasksDetails;
           $scope.tasksData = data.tasksData;
            $scope.tasksassign = data.tasksassign; 
            $scope.comment = "";
            $scope.albumNameArray2 = [];     
            }).error(function(data, status) {
                alert('something went wrong');
            });
  }
          
    
        }

   
      // TaskService.savecomment(comment,taskId,tasksassign,statusid,priorityId,$file).success(function(data, status){  
      // $scope.comments = data.comments;
      // $scope.tasksDetails = data.tasksDetails;
      // $scope.tasksassign = data.tasksassign;
      // $scope.comment = "";

      // // console.log($scope.comments);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
    }
    

$scope.commentfileArray =[];
$scope.notifyArray =[];
      $rootScope.getTask = function(taskId) { 
         
        // console.log(taskId);
      TaskService.getTask(taskId).success(function(data, status){  
      $scope.tasksData = data.tasksData;
      $scope.tasksDetails = data.tasksDetails;
      $scope.commentfiles = data.commentfiles;
      $scope.tasksassign = data.tasksassign;
      $scope.comments = data.comments;
      $scope.commentfiles = data.commentfiles;
      $scope.project_users = data.project_users;
      console.log($scope.comments);

 // $scope.checkAll = function() {
  
 //    $scope.notifyArray = $scope.project_users.map(function(item) { return item.id; });
 //        console.log($scope.notifyArray);

 //  };

 //  $scope.uncheckAll = function() {
 //    $scope.notifyArray = [];
 //     //  // $scope.project_users = data.project_users;
 //     // angular.forEach($scope.project_users, function () {
         
 //     //       $scope.project_users.id = false;
 //     //    });
 //        console.log($scope.notifyArray);

 //  };

  
      if ($scope.tasksDetails.sname == 'New') {
        $scope.statusId = "0";
        $scope.status = [
        {
          id: "0",
          name: $scope.tasksDetails.sname
        },{
          id: "4",
          name: "Start"
        }, {
          id: "5",
          name: "Resolve"
        },{
          id: "3",
          name: "Close"
        }];
      }else if ($scope.tasksDetails.sname == 'In Progress') {
        $scope.statusId = "0";
           $scope.status = [
           {
          id: "0",
          name: $scope.tasksDetails.sname
        },{
          id: "5",
          name: "Resolve"
        },{
          id: "3",
          name: "Close"
        }];
      }else if ($scope.tasksDetails.sname == 'Resolved') {
        $scope.statusId = "0";
             $scope.status = [
             {
          id: "0",
          name: $scope.tasksDetails.sname
        },{
          id: "4",
          name: "In Progress"
        },{
          id: "3",
          name: "Close"
        }];
      }
   
      // console.log(($scope.commentfiles[0])[0].name);
      // console.log(data.tasksData);
      // console.log(data.tasksDetails);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    }

 
    $scope.projectDep = function (departmentId) {     
      ProjectService.getProjects(departmentId).success(function(data, status) {   
        $scope.active = data.activePage;

        // console.log($scope.active);
         // $scope.projects = new NgTableParams({}, { dataset:data.projects })
          // $scope.projects = data.projects;
          $scope.projects = data.projects;
          $scope.project_users = data.project_users;
          // console.log(data.project_users);
          $scope.project_usseradded = data.project_usseradded;
          console.log($scope.project_usseradded);
          $scope.role = data.role;
          $scope.company_projects = data.company_projects;
          $scope.projectstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.projects.length,
                    counts: [10, 20, 50, 100,$scope.projects.length],
                    getData: function( params) {
                      params.total($scope.projects.length);
                    $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log(data.projects);

        // $scope.searchItem = function(searchedItem){
        //         $scope.searchedItem = searchedItem;
        //         $scope.projects.reload();
        //     }
        //     $scope.projects = new NgTableParams({
        //         page: 1,
        //         count: 10,
        //     },
        //     {
        //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
        //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
        //     getData: function($defer, params) {
        //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
        //         var orderedData = params.sorting() ?
        //         $filter('orderBy')(filteredData, params.orderBy()) :
        //         filteredData;
        //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        //     },
        //     $scope: $scope
        // });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      var projectId = -1;

      TaskService.getTasks(projectId).success(function(data, status) {       

        $scope.projecttasks = data;
         // $scope.tasksArchived = new NgTableParams({}, { dataset:data.tasksArchived })
        // $scope.tasksArchived = data.tasksArchived;
          $scope.files = data.files;
          $scope.filestable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.files.length,
                    counts: [10, 20, 50, 100,$scope.files.length],
                    getData: function( params) {
                    params.total($scope.files.length);
                    $scope.data = $scope.files.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
         $scope.tasksArchived = data.tasksArchived;
          $scope.tasksArchivedtable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.tasksArchived.length,
                    counts: [10, 20, 50, 100,$scope.tasksArchived.length],
                    getData: function( params) {
                    params.total($scope.tasksArchived.length);
                    $scope.data = $scope.tasksArchived.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }
       $scope.taskcategory = {};
           $scope.taskcategory.categoryId = "1";
           $scope.taskcategory.Categories = [{
             id: "1",
             name: "All Tasks"
           }, {
             id: "2",
             name: "My Tasks"
           },{
             id: "3",
             name: "Favourites"
           }, {
             id: "4",
             name: "Overdue"
           }];
     if ($scope.taskcategory.categoryId !== "1") {
     $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;
      // console.log( $scope.taskcategory.categoryId );

      }else {
        $scope.taskcategory.categoryId = "1";

      }
      

        // $scope.taskcategory.Categories = [{
        //   id: "1",
        //   name: "All Tasks"
        // }, {
        //   id: "2",
        //   name: "My Tasks"
        // },{
        //   id: "3",
        //   name: "Favourites"
        // }, {
        //   id: "4",
        //   name: "Overdue"
        // }];

    TaskService.taskCategory(projectId,$scope.taskcategory.categoryId).success(function(data, status) {   
        $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;

                 // console.log($scope.taskcategory.categoryId);

         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
          $scope.tasks = data.tasks;
            // console.log(data.tasks);
          
          // $scope.currentprojectname = data.tasks.pname;
            // console.log($scope.currentprojectname);

          $rootScope.CreateHeader = function(pname) {
          showHeader = (pname!=$scope.currentprojectname); 
          $scope.currentprojectname = pname;
            // console.log($scope.currentprojectname);
          
          return showHeader;
          }
            // $scope.userId = data.userId;
              $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // filter: { task: "" }, 
                    // sorting: { task: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
                    }
                // $scope: $scope
            });

      TaskService.getTasksCatNew(projectId,$scope.taskcategory.categoryId,'New').success(function(data, status) {           
        $scope.tasksnew = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatClosed(projectId,$scope.taskcategory.categoryId,'Closed').success(function(data, status) {   
        $scope.tasksClosed = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatInProgress(projectId,$scope.taskcategory.categoryId,'In Progress').success(function(data, status) {          
        $scope.tasksInProgress= data.statusesCat;
                 console.log(  $scope.tasksInProgress);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatResolved(projectId,$scope.taskcategory.categoryId,'Resolved').success(function(data, status) {         
        $scope.tasksResolved = data.statusesCat;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            });
      }).error(function(data, status) {
        alert('something went wrong');
      });
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

      TaskService.getMilestones(projectId).success(function(data, status) {         
        // $scope.milestones = new NgTableParams({}, { dataset:data.milestones })
        $scope.milestones = data.milestones;
         $scope.milestonestable = new NgTableParams({
                   page: 1,
                   count: 10,
                   // sorting: { effective_dt: "asc" },
               },
               {
                   total: $scope.milestones.length,
                   counts: [10, 20, 50, 100,$scope.milestones.length],
                   getData: function( params) {
                     params.total($scope.milestones.length);
                   $scope.data = $scope.milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                   return $scope.data;
  
                   }
               // $scope: $scope
           });

   
                // console.log( $scope.milestones);
           }).error(function(data, status) {
               alert('something went wrong');
           });
            TaskService.getTasksNew('New',projectId,$scope.milestoneId).success(function(data, status) {           
       $scope.tasksnew = data.statuses;
       $scope.tasksnewmil = data.task_milestones_status;
       $scope.tasksnewarch = data.archv_statuses;

                // console.log(  $scope.tasksnewmil);
                // console.log( projectId );
           }).error(function(data, status) {
               alert('something went wrong');
           }); 
       TaskService.getTasksClosed('Closed',projectId,$scope.milestoneId).success(function(data, status) {   
       $scope.tasksClosed = data.statuses;
       $scope.tasksClosedmil = data.task_milestones_status;
       $scope.tasksClosedarch = data.archv_statuses;

           }).error(function(data, status) {
               alert('something went wrong');
           }); 
       TaskService.getTasksInProgress('In Progress',projectId,$scope.milestoneId).success(function(data, status) {          
       $scope.tasksInProgress= data.statuses;
       $scope.tasksInProgressmil= data.task_milestones_status;
       $scope.tasksInProgressarch = data.archv_statuses;
       
           }).error(function(data, status) {
               alert('something went wrong');
           }); 
           TaskService.getTasksResolved('Resolved',projectId,$scope.milestoneId).success(function(data, status) {         
       $scope.tasksResolved = data.statuses;
       $scope.tasksResolvedmil = data.task_milestones_status;
       $scope.tasksResolvedarch = data.archv_statuses;
                
           }).error(function(data, status) {
               alert('something went wrong');
           });  
      
      
      // ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
      // $rootScope.project_users = data.project_users;
      // console.log(data.project_users);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
      DepartmentService.updatedepcache(departmentId).success(function(data, status) {   
              
      }).error(function(data, status) {
          alert('something went wrong');
      }); 
      // location.reload();
    }
    // $scope.projectDep = function (departmentId) { 
     var departmentId = $scope.departmentId;
    ProjectService.getProjects(departmentId).success(function(data, status) {    
        $scope.projects = data.projects;
        $scope.project_users = data.project_users;
        // console.log(data.project_users);
        $scope.project_usseradded = data.project_usseradded;
        $scope.role = data.role;
        $scope.company_projects = data.company_projects;
        if($scope.project){
         console.log($scope.project_usseradded );
        // $scope.project = $scope.project;
      var projectId =$scope.project.id;
      $scope.projectId =$scope.project.id;
                  
      // $scope.tasks = function (projectId) {

        TaskService.getMilestones(projectId).success(function(data, status) {         
         // $scope.milestones = new NgTableParams({}, { dataset:data.milestones })
         $scope.milestones = data.milestones;
          $scope.milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.milestones.length,
                    counts: [10, 20, 50, 100,$scope.milestones.length],
                    getData: function( params) {
                      params.total($scope.milestones.length);
                    $scope.data = $scope.milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            // $scope.milestones = data.milestones;
                 // console.log( $scope.milestones);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

         $scope.IsVisible = false;
        $rootScope.display = function (displayIsactive) {
        // console.log(displayIsactive);
         $scope.IsVisible = true;
        $scope.displayIsactive = displayIsactive; 
         }
            // console.log(projectId);
          // $scope.projectTask  = function (projectId) {                  
         TaskService.getTasks(projectId).success(function(data, status) { 
          //  console.log("au hapa")  
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
        //  consolog.log('123');
        
         $scope.files = data.files;
          $scope.filestable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.files.length,
                    counts: [10, 20, 50, 100,$scope.files.length],
                    getData: function( params) {
                    params.total($scope.files.length);
                    $scope.data = $scope.files.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
          $scope.tasks = data.tasks;
          $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                     // filter: { task: "" }, 
                    // sorting: { task: "asc", created_by: "asc", task_type: "asc",assigned_to: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function (params) {
                      params.total($scope.tasks.length);
                        // params.total($filter('filter')($scope.tasks.length));
                        //   $scope.data = $filter('filter')( $scope.SearchedItem);
                         $scope.data =  $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());
                            return $scope.data;
                         }
         
            });
        // $scope.tasks = data.tasks;
        $scope.projecttasks = data;
        // $scope.tasksArchived = data.tasksArchived;
         // $scope.tasksArchived = new NgTableParams({}, { dataset:data.tasksArchived })
         $scope.tasksArchived = data.tasksArchived;
          $scope.tasksArchivedtable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.tasksArchived.length,
                    counts: [10, 20, 50, 100,$scope.tasksArchived.length],
                    getData: function( params) {
                      params.total($scope.tasksArchived.length);
                    $scope.data = $scope.tasksArchived.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log($scope.tasks);
        $scope.taskcategory = {};
        $scope.taskcategory.categoryId = "1";
        $scope.taskcategory.Categories = [{
          id: "1",
          name: "All Tasks"
        }, {
          id: "2",
          name: "My Tasks"
        },{
          id: "3",
          name: "Favourites"
        }, {
          id: "4",
          name: "Overdue"
        }];

        TaskService.taskCategory(projectId,$scope.taskcategory.categoryId).success(function(data, status) {   
          $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;
  
                   // console.log($scope.taskcategory.categoryId);
  
           // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
            $scope.tasks = data.tasks;
              console.log(data.tasks);
            
            // $scope.currentprojectname = data.tasks.pname;
              // console.log($scope.currentprojectname);
  
            $rootScope.CreateHeader = function(pname) {
            showHeader = (pname!=$scope.currentprojectname); 
            $scope.currentprojectname = pname;
              // console.log($scope.currentprojectname);
            
            return showHeader;
            }
              // $scope.userId = data.userId;
                $scope.taskstable = new NgTableParams({
                      page: 1,
                      count: 10,
                      // filter: { task: "" }, 
                      // sorting: { task: "asc" },
                  },
                  {
                      total: $scope.tasks.length,
                      counts: [10, 20, 50, 100,$scope.tasks.length],
                      getData: function( params) {
                        params.total($scope.tasks.length);
                      $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                      return $scope.data;
                      }
                  // $scope: $scope
              });
  
        TaskService.getTasksCatNew(projectId,$scope.taskcategory.categoryId,'New').success(function(data, status) {           
          $scope.tasksnew = data.statusesCat;
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatClosed(projectId,$scope.taskcategory.categoryId,'Closed').success(function(data, status) {   
          $scope.tasksClosed = data.statusesCat;
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatInProgress(projectId,$scope.taskcategory.categoryId,'In Progress').success(function(data, status) {          
          $scope.tasksInProgress= data.statusesCat;
                   console.log(  $scope.tasksInProgress);
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatResolved(projectId,$scope.taskcategory.categoryId,'Resolved').success(function(data, status) {         
          $scope.tasksResolved = data.statusesCat;
                   console.log( $scope.statuses);
              }).error(function(data, status) {
                  alert('something went wrong');
              });
        }).error(function(data, status) {
          alert('something went wrong');
        });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        TaskService.getTasksNew('New',projectId,$scope.milestoneId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
        $scope.tasksnewmil = data.task_milestones_status;
        $scope.tasksnewarch = data.archv_statuses;
                 // console.log(  $scope.tasksnewmil);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksClosed('Closed',projectId,$scope.milestoneId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
        $scope.tasksClosedmil = data.task_milestones_status;
        $scope.tasksClosedarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId,$scope.milestoneId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
        $scope.tasksInProgressmil= data.task_milestones_status;
        $scope.tasksInProgressarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId,$scope.milestoneId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
        $scope.tasksResolvedmil = data.task_milestones_status;
        $scope.tasksResolvearch = data.archv_statuses;

                 
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        }

        else{
        $scope.project = data.project_user_cache;
        var projectId = data.project_user_cache.id;
                  
      // $scope.tasks = function (projectId) {

        TaskService.getMilestones(projectId).success(function(data, status) {         
         // $scope.milestones = new NgTableParams({}, { dataset:data.milestones })
         $scope.milestones = data.milestones;
          $scope.milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.milestones.length,
                    counts: [10, 20, 50, 100,$scope.milestones.length],
                    getData: function( params) {
                      params.total($scope.milestones.length);
                    $scope.data = $scope.milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            // $scope.milestones = data.milestones;
                 // console.log( $scope.milestones);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

         $scope.IsVisible = false;
        $rootScope.display = function (displayIsactive) {
        // console.log(displayIsactive);
         $scope.IsVisible = true;
        $scope.displayIsactive = displayIsactive; 
         }
            // console.log(projectId);
          // $scope.projectTask  = function (projectId) {                  
         TaskService.getTasks(projectId).success(function(data, status) {   
          //  console.log("hapa tena");
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
         $scope.files = data.files;
          $scope.filestable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.files.length,
                    counts: [10, 20, 50, 100,$scope.files.length],
                    getData: function( params) {
                    params.total($scope.files.length);
                    $scope.data = $scope.files.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
          $scope.tasks = data.tasks;
         
          $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                     // filter: { task: "" }, 
                    // sorting: { task: "asc", created_by: "asc", task_type: "asc",assigned_to: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function (params) {
                      params.total($scope.tasks.length);
                        // params.total($filter('filter')($scope.tasks.length));
                        //   $scope.data = $filter('filter')( $scope.SearchedItem);
                         $scope.data =  $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());
                            return $scope.data;
                         }
         
            });
        // $scope.tasks = data.tasks;
        $scope.projecttasks = data;
        // $scope.tasksArchived = data.tasksArchived;
         // $scope.tasksArchived = new NgTableParams({}, { dataset:data.tasksArchived })
         $scope.tasksArchived = data.tasksArchived;
          $scope.tasksArchivedtable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.tasksArchived.length,
                    counts: [10, 20, 50, 100,$scope.tasksArchived.length],
                    getData: function( params) {
                      params.total($scope.tasksArchived.length);
                    $scope.data = $scope.tasksArchived.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log($scope.tasks);
        $scope.taskcategory = {};
        $scope.taskcategory.categoryId = "1";
        $scope.taskcategory.Categories = [{
          id: "1",
          name: "All Tasks"
        }, {
          id: "2",
          name: "My Tasks"
        },{
          id: "3",
          name: "Favourites"
        }, {
          id: "4",
          name: "Overdue"
        }];

        TaskService.taskCategory(projectId,$scope.taskcategory.categoryId).success(function(data, status) {   
          $scope.taskcategory.categoryId = $scope.taskcategory.categoryId;
  
                   // console.log($scope.taskcategory.categoryId);
  
           // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
            $scope.tasks = data.tasks;
              console.log(data.tasks);
            
            // $scope.currentprojectname = data.tasks.pname;
              // console.log($scope.currentprojectname);
  
            $rootScope.CreateHeader = function(pname) {
            showHeader = (pname!=$scope.currentprojectname); 
            $scope.currentprojectname = pname;
              // console.log($scope.currentprojectname);
            
            return showHeader;
            }
              // $scope.userId = data.userId;
                $scope.taskstable = new NgTableParams({
                      page: 1,
                      count: 10,
                      // filter: { task: "" }, 
                      // sorting: { task: "asc" },
                  },
                  {
                      total: $scope.tasks.length,
                      counts: [10, 20, 50, 100,$scope.tasks.length],
                      getData: function( params) {
                        params.total($scope.tasks.length);
                      $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                      return $scope.data;
                      }
                  // $scope: $scope
              });
  
        TaskService.getTasksCatNew(projectId,$scope.taskcategory.categoryId,'New').success(function(data, status) {           
          $scope.tasksnew = data.statusesCat;
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatClosed(projectId,$scope.taskcategory.categoryId,'Closed').success(function(data, status) {   
          $scope.tasksClosed = data.statusesCat;
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatInProgress(projectId,$scope.taskcategory.categoryId,'In Progress').success(function(data, status) {          
          $scope.tasksInProgress= data.statusesCat;
                   console.log(  $scope.tasksInProgress);
              }).error(function(data, status) {
                  alert('something went wrong');
              }); 
          TaskService.getTasksCatResolved(projectId,$scope.taskcategory.categoryId,'Resolved').success(function(data, status) {         
          $scope.tasksResolved = data.statusesCat;
                   console.log( $scope.statuses);
              }).error(function(data, status) {
                  alert('something went wrong');
              });
        }).error(function(data, status) {
          alert('something went wrong');
        });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        TaskService.getTasksNew('New',projectId,$scope.milestoneId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
        $scope.tasksnewmil = data.task_milestones_status;
        $scope.tasksnewarch = data.archv_statuses;

                 // console.log(  $scope.tasksnewmil);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksClosed('Closed',projectId,$scope.milestoneId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
        $scope.tasksClosedmil = data.task_milestones_status;
        $scope.tasksClosedarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId,$scope.milestoneId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
        $scope.tasksInProgressmil= data.task_milestones_status;
        $scope.tasksInProgressarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId,$scope.milestoneId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
        $scope.tasksResolvedmil = data.task_milestones_status;
        $scope.tasksResolvedarch = data.archv_statuses;
                 
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
    
        // }

        }

      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      DepartmentService.updatedepcache(departmentId).success(function(data, status) {         
      }).error(function(data, status) {
          alert('something went wrong');
      }); 
    // }

  // $(document).ready(function() {
  //   // console.log(location.pathname);
  // $('li.active').removeClass('active');
  // $('a[href="' + location.pathname + '"]').closest('li').addClass('active'); 
  //    });
        
      TaskService.getMilestoneTasks($scope.milestoneId).success(function(data, status) {  
        // $rootScope.task_milestones = data.task_milestones;
         // $scope.task_milestones = new NgTableParams({}, { dataset:data.task_milestones })
          $scope.task_milestones = data.task_milestones;
          $scope.task_milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.task_milestones.length,
                    counts: [10, 20, 50, 100,$scope.task_milestones.length],
                    getData: function( params) {
                      params.total($scope.task_milestones.length);
                    $scope.data = $scope.task_milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

      // $rootScope.task_milestones_status = data.task_milestones_status;

      $rootScope.milestone = data.milestone;
      // console.log( $rootScope.task_milestones);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 


    // } 
      //  TaskService.getTasksNotInMilestone($scope.milestoneId).success(function(data, status) {   
        
      //   $scope.tasks = data.tasks;
      //   // console.log(data.companies)   
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // });


    TaskService.getTypes().success(function(data, status) {   
        
        $scope.types = data.types;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      DepartmentService.getDepartments().success(function(data, status) {   
        
        $scope.departments = data.departments;
        $scope.departmentId = data.departmentId;

        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        UnitService.getUnits().success(function(data, status) {   
        
        $scope.units = data.units;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      TaskService.getPriorities().success(function(data, status) {   
        
        $scope.Priorities = data.Priorities;
        console.log($scope.Priorities)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

   $scope.toggleAddTaskMile = function(modalstate,milestoneId,projectId) {
      console.log(projectId);

       TaskService.getMilestoneTasks(milestoneId,projectId).success(function(data, status) {  
      // $rootScope.task_milestones = data.task_milestones;
         // $scope.task_milestones = new NgTableParams({}, { dataset:data.task_milestones })
          $scope.task_milestones = data.task_milestones;
          $scope.task_milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.task_milestones.length,
                    counts: [10, 20, 50, 100,$scope.task_milestones.length],
                    getData: function( params) {
                      params.total($scope.task_milestones.length);
                    $scope.data = $scope.task_milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
      // console.log($rootScope.task_milestones);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    // } 
       
    TaskService.getTasksNotInMilestone(milestoneId,projectId).success(function(data, status) {   
        
        $scope.tasks = data.tasks;

        $scope.milestone = data.milestone;
        console.log($scope.milestone )   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });
       switch (modalstate) {
            case 'addTaskToMilestone':
                $scope.form_title = "Add Task To Milestone";
                $scope.milestone= milestoneId;
                $scope.task=projectId;
                console.log($scope.task);
                $('#myModal4').modal('show');
                break;
            case 'addTaskToMilestone2':
                $scope.form_title = "Add Task To Milestone";
                $scope.milestone= milestoneId;
                $scope.task=projectId;
                console.log($scope.task);
                $('#myModal9').modal('show');
                break;
            
        }
     }

    $scope.folder = {};
    $scope.remove = {};
    $scope.albumNameArray = [];
    $rootScope.addTaskToMile = function(milestoneId,projectId) {
       // $scope.milestone=milestoneId
        // console.log(milestoneId)
        // console.log($scope.projectId)
      
        angular.forEach($scope.folder,function(key,value){
            if(key){
                $scope.albumNameArray.push(value)
              }
        });

       TaskService.addTaskToMile($scope.albumNameArray,milestoneId,projectId).success(function(data){
          // console.log(projectId)
            notify({
              message:"Tasks added to Milestone.",
              template:'templates/success.html',
              position:'center'
            });
            location.reload();
          })
       }

       var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        
       $scope.date2 = today;

       $('#selectdate').datepicker({
           uiLibrary: 'bootstrap',
           maxDate: function () {
             return today;
         },
          minDate: function () {
             return today;
         }
           // setValue: $scope.date2,
       
       });

        $scope.toggleTask = function(modalstate,id,milestoneId,taskId) {
       

          if (id == null){
            notify({
              message:"Please Select A Project First.",
              template:'templates/error.html',
              position:'center'
            });
          }else {

          $scope.timelog = "";
          $scope.resultAmount = 0;
          $scope.itemused = [];
          $scope.totalsum = 0;
          $scope.bilableAmount = 0;
           $scope.start_time = new Date();
     $scope.breakstart_time = new Date();
     $scope.end_time = new Date();
     $scope.breakend_time = new Date();
     // $scope.breaktime = "";
     $scope.showMeridian = true;
// var canvas = document.querySelector("canvas");

// var signaturePad = new SignaturePad(canvas);
// signaturePad.minWidth = 1;
// signaturePad.maxWidth = 1;
// signaturePad.penColor = "rgb(66, 133, 244)";

// var saveButton = document.getElementById('save');

// var cancelButton = document.getElementById('clear');
// //           saveButton.addEventListener('click', function (event) {
// //            var data = signaturePad.toDataURL();
// //          console.log(data);
// // // Send data to server instead...
// //             window.open(data);
// //           });

// saveButton.addEventListener("click", function (event) {
// if (signaturePad.isEmpty()) {
// alert("Please provide signature first.");
// } else {
// document.getElementById("hfSign").value = signaturePad.toDataURL();
// }
// });

// // protected void Save(object sender, EventArgs e)
// //      {
// //   string signature = hfSign.Value;
// // }
// cancelButton.addEventListener('click', function (event) {
// signaturePad.clear();
// });
// // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
// signaturePad.toDataURL(); // save image as PNG
// signaturePad.toDataURL("image/jpeg"); // save image as JPEG
// signaturePad.toDataURL("image/svg+xml"); // save image as SVG

// // Draws signature image from data URL.
// // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
// signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

// // Returns signature image as an array of point groups
// const data = signaturePad.toData();

// // Draws signature image from an array of point groups
// signaturePad.fromData(data);

// // Clears the canvas
// signaturePad.clear();

// // Returns true if canvas is empty, otherwise returns false
// signaturePad.isEmpty();

// // Unbinds all event handlers
// signaturePad.off();

// // Rebinds all event handlers
// signaturePad.on();


//  var signaturePad = new SignaturePad(canvas, {
//     minWidth: 5,
//     maxWidth: 10,
//     penColor: "rgb(66, 133, 244)"
// });
//  var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
//   backgroundColor: 'rgba(255, 255, 255, 0)',
//   penColor: 'rgb(0, 0, 0)'
// });
// var saveButton = document.getElementById('save');
// var cancelButton = document.getElementById('clear');

// saveButton.addEventListener('click', function (event) {
//   var data = signaturePad.toDataURL('image/png');

// // Send data to server instead...
//   window.open(data);
// });

// $scope.timelog = "";
// $scope.start_time = "";
// $scope.end_time = "";
// $scope.hourbreak_time = "";
//  $scope.minutesbreak_time = "";
//  $scope.result = "";
$scope.pricetotal = function() {
var price = $scope.budget.price;
var quantity = $scope.budget.quantity;

$scope.total = price * quantity;
 // body...
}  
          
          $('#datetimepicker').timepicker({
                format: 'HH:mm'
              });

          $scope.timeDifference = function () {
           var start_Time = new moment($scope.start_time).format("HH:mm:ss a");
           var end_Time = new moment($scope.end_time).format("HH:mm:ss a");
            var hourbreak_time = $scope.hourbreak_time;
           var minutesbreak_time =  $scope.minutesbreak_time;

          // console.log($scope.hourbreak_time)
          
           var startTime=moment(start_Time, "HH:mm:ss a");
           var endTime=moment(end_Time, "HH:mm:ss a");
          
           var duration = moment.duration(endTime.diff(startTime));
           var hours = parseInt(duration.asHours());
           var minutes = parseInt(duration.asMinutes())-hours*60;

           var hours1 = Number(hours - hourbreak_time)-1;
           var minutes1 = Number(minutes - minutesbreak_time) + 60;

           if (minutes1>= 60) {
               var hr = hours1+1;
               var minutes1 = 00;
            $scope.result = (hr+":"+ minutes1);
           }
           else {
             $scope.result = (hours1+":"+ minutes1);
           }
             if (hr < 0 || hours1 < 0) {
            alert('Break time can not exceed Total spent hours'); 
            return;   
              }else {
            $scope.result = $scope.result;
              }
            // console.log(result);
           }

          
       
      $scope.handleRadioClick = function (milestoneId) {
                  $scope.taskId= taskId;
                  $scope.projectId= id;
            TaskService.moveTaskToMile(taskId,milestoneId,projectId).success(function(data){
          // console.log($scope.albumNameArray)
            notify({
              message:"Task moved to Milestone.",
              template:'templates/success.html',
              position:'center'
            });
          })
                // console.log( milestoneId)  
                // alert(milestone.id);
            }; 
           
        $scope.moveTaskToProject = function (projectId) {
          // console.log(projectId)
           $scope.taskId= taskId;
            TaskService.moveTaskToProject(projectId,taskId).success(function(data){
            notify({
              message:"Task moved to Project.",
              template:'templates/success.html',
              position:'center'
            });
          })
                // console.log( milestoneId)  
                // alert(milestone.id);
            };
        var projectId = $scope.projectId
         var milestoneId =milestoneId
        $scope.modalstate = modalstate;
          TaskService.getMilestones(id).success(function(data, status) {         
         // $scope.milestones = new NgTableParams({}, { dataset:data.milestones })
            $scope.milestones = data.milestones;
               // console.log($scope.milestones);

             angular.forEach($scope.milestones,function(value,key){

          if (value.id == milestoneId) {
          $scope.milestones[key]['checked'] = true;

          }

        });     
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

 
         TaskService.getTasks(id).success(function(data, status) {  
          //  console.log('hapa') 
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
        //  console.log($scope.tasks);
        $scope.taskstimelog = data.taskstimelog;
        $scope.tasks = data.tasks;
        $scope.files = data.files;
        $scope.tasksArchived = data.tasksArchived;
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }

        //  console.log($scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      var taskId = $scope.taskId;
      TaskService.toggleTask(id,milestoneId,taskId).success(function(data, status) {  
        $scope.project = data.project;
        $scope.company_users = data.company_users;
        $scope.milestone = data.milestone;
        $scope.project_user = data.project_user;
        $scope.task = data.task;
        $scope.rates = data.rates;
        $scope.user_id = data.userId;
        $scope.role = data.rate.rname;
        $scope.name = data.rate.name;
        // console.log(data.project_user );
        // console.log(data.project );
        // console.log(data.task );
  
        // $scope.user_rate = data.rate;
        })

      // TaskService.toggleTask(id,milestoneId).success(function(data, status) {  
      // $scope.project = data.project;
      // $scope.company_users = data.company_users;
      // $scope.milestone = data.milestone;
      // $scope.project_user = data.project_user;
      // $scope.task = data.task;
      //   // console.log( $scope.company_users)

      // })
      $scope.$watch('user_id', function(newvalue) {

        angular.forEach($scope.project_user,function(key,value){
       if(key.id == newvalue){
       console.log(newvalue);

         $scope.user_rate = key.value;

         }
   });

     });
        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Task";
                $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                $('#myModal').modal('show');
                break;
           
            case 'addMilestone':
                $scope.form_title = "Add New Milestone";
                $scope.task={project_id:id};
                $('#myModal2').modal('show');
                break;

           case 'moveTaskToMilestone':
                $scope.form_title = "Move Task To Milestone";
                 $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                 $scope.milestone= taskId;
                // console.log(milestoneId);
                $('#myModal5').modal('show');
                break;
            case 'moveTaskToProject':
                $scope.form_title = "Move Task To Project";
                 $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                // console.log($scope.milestone);
                $('#myModal6').modal('show');
                break;
            case 'addtimelog':
                $scope.form_title = "Add Log Time";
                $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                $('#myModal7').modal('show');
                break;
         
 
        }

        $scope.isbillable = function (checked) {
          if (checked == true) {
            if ( $scope.timedecimal != 0) {
            $scope.resultAmount = $scope.timedecimal * $scope.user_rate;  
  
          } else{
                $scope.resultAmount = 0;
  
          }
          }else if (checked == false) {
                $scope.resultAmount = 0;
  
          }
         }
        $scope.addItem = function (newrow) {
          $scope.newItem.push({});
          $scope.newItem_validated = 'No';
          $scope.validate(newrow);
    
               }
            $scope.removeItem = function($index) {
                 $scope.newItem.splice($index,1)
                 $scope.validate(0);
                // var totalAm = $scope.total;
                // $scope.totalsum =  $scope.totalsum - totalAm;
    
                 }
              $scope.newItem = [];
              $scope.rowdata = [];
         $scope.validate = function ($index) {
            console.log($index);
    
          $scope.valid = 'No';
    
          $scope.totalsum = $scope.total1;
     
          if ($scope.newItem[$index]) {
          $scope.total1 = 0;
          $scope.newItem[$index].total = $scope.newItem[$index].quantity * $scope.newItem[$index].price;
            if ($scope.newItem[$index].total) {
    
              for (var i = 0; i < $scope.newItem.length; i++) {
    
                $scope.total1 += Number($scope.newItem[i].total || 0);
    
                $scope.totalsum =  $scope.total1;
    
              }
    
            }
    
     
    
            if ($scope.newItem[$index].price &&
    
              $scope.newItem[$index].quantity &&
              $scope.newItem[$index].item &&
              $scope.newItem[$index].uname &&
              $scope.newItem[$index].total &&
    
              $scope.totalsum == 0) {
    
              $scope.newItem_validated = 'Yes';
    
            }
             // $scope.newItem[$index].push({});
    
          }
         if ($index > 0){
             $scope.rowdata.push($scope.newItem[$index-1]);
    
          }
          
            // console.log($scope.rowdata);
    
        }
         
    } 
  }


     TaskService.getProjectUsers($scope.projectId).success(function(data, status) {  
      $rootScope.project_users = data.project_users;
      console.log(data.project_users);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    // } 
      
       TaskService.getUsersNotInProject($scope.projectId).success(function(data, status) {   
        
        $scope.users = data.users;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });
   $scope.onFileSelect = function($files){
    // console.log($files);
   //$scope.file = $files;
   $scope.datatasks={};
     //$scope.file = {};
        for (var i = $files.length - 1; i >= 0; i--){
             $scope.datatasks['file'+i]=$files[i];
        }
    $scope.datatasks['filecount']=$files.length;
    console.log($scope.datatasks);
   }
        

    $scope.saveTask = function(task,id,projectId,companyId,action) {
    


    if ($scope.datatasks) {
       var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

        var endDate = $('#taskendDate').val();
    $scope.datatasks['startDate']=startDate;
    $scope.datatasks['endDate']=endDate;
    $scope.datatasks['task']=task;
    $scope.datatasks['projectId']=projectId;
    $scope.datatasks['companyId']=companyId;
    console.log($scope.datatasks);

           $scope.upload = $upload.upload({
                url: 'tasks',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
               var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

               var endDate = $('#taskendDate').val();

             var title = $scope.task.title;
             var description = $scope.task.description;
             var assign_to = $scope.task.assign_to;
             var type_id = $scope.task.type_id;
             var priority = $scope.task.priority;
       // console.log(task);
                 if (task==null || title==null || description==null || assign_to==null || type_id==null || priority==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
               TaskService.saveTask(task,id,projectId,companyId,startDate,endDate,action).success(function(response) { 
                  if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                } 
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
          } 
            // location.reload();  
  // console.log($scope.data);
        };

        $rootScope.saveTimelog = function(itemused,timelog,user_id,projectId,taskId,action) {
          // console.log($scope.start_time);
          // console.log(user_id);
          // console.log(projectId);
          // console.log(taskId);
          // console.log($scope.resultAmount);
  
          var selectdate = $('#selectdate').val();
          var logged_hours = $scope.result;
          var bilableAmount = $scope.resultAmount;
         //  var hourbreak_time = $scope.hourbreak_time;
         //  var minutesbreak_time =  $scope.minutesbreak_time;
         //  // var break_time = (hourbreak_time+":"+ minutesbreak_time);
          var start_timelog = new moment($scope.start_time).format("HH:mm:ss");
          var breakstart_timelog = new moment($scope.breakstart_time).format("HH:mm:ss");
          var end_timelog = new moment($scope.end_time).format("HH:mm:ss");
          var breakend_timelog = new moment($scope.breakend_time).format("HH:mm:ss");
  
         var task_id = $scope.taskId;
         var user_id = $scope.user_id;
         var note = $scope.timelog.note;
         var progress = $scope.timelog.progress;
         var client = $scope.timelog.client;
         var isitemused = $scope.timelog.isitemused;
            if (client == 'no') {
         // console.log(itemused);
  
          $scope.timelog.adress = "";
        }
  
        if (isitemused == 'no') {
         // console.log(itemused);
  
          itemused = [];
          $scope.totalsum = 0;
        }
         // console.log(itemused);
         if ( task_id==null || note==null || progress==null|| client==null|| isitemused==null || selectdate==null || start_timelog==null || end_timelog==null ) {
           notify({
                message:"Please Fill All The Fields Correct.",
                template:'templates/error.html',
                position:'center'
              });
         } else {
         TimelogService.saveTimelog(itemused,timelog,user_id,projectId,taskId,selectdate,$scope.totalsum,logged_hours,bilableAmount,start_timelog,end_timelog,breakstart_timelog,breakend_timelog).success(function(response) {
              // location.reload();
              if (action == 'close') {
                notify({
                       message:"Timelog Added successful.",
                       template:'templates/success.html',
                       position:'center'
                        });
                      location.reload();
                  } else {
                          $scope.timelog = "";
                        $scope.resultAmount = 0;
                        $scope.itemused = [];
                        $scope.totalsum = 0;
                        $scope.bilableAmount = 0;
                         $scope.start_time = new Date();
                   $scope.breakstart_time = new Date();
                   $scope.end_time = new Date();
                   $scope.breakend_time = new Date();
                   // $scope.breaktime = "";
                   $scope.showMeridian = true;
                        notify({
                       message:"Timelog Added successful.",
                       template:'templates/success.html',
                       position:'center'
                        });
                  }
        }).error(function(response) {
               notify({
                message:"Please Fill All The Fields Correct.",
                template:'templates/error.html',
                position:'center'
              });
        }); 
      }
    }

  $scope.saveTaskFrmMile = function(task,id,milestoneId,action) {
       if ($scope.datatasks) {
       var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

        var endDate = $('#taskendDate').val();
    $scope.datatasks['startDate']=startDate;
    $scope.datatasks['endDate']=endDate;
    $scope.datatasks['task']=task;
    $scope.datatasks['projectId']=projectId;
    $scope.datatasks['companyId']=companyId;
    $scope.datatasks['milestoneId']=milestoneId;

    // console.log($scope.datatasks);

           $scope.upload = $upload.upload({
                url: 'saveTaskFrmMile',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
               var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

               var endDate = $('#taskendDate').val();

             var title = $scope.task.title;
             var description = $scope.task.description;
             var assign_to = $scope.task.assign_to;
             var type_id = $scope.task.type_id;
             var priority = $scope.task.priority;
       console.log(id);
      if (task==null || title==null || description==null || assign_to==null || type_id==null || priority==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
               TaskService.saveTaskFrmMile(task,id,milestoneId,startDate,endDate,action).success(function(response) { 
                  if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                } 
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
          } 
  // console.log(companyId);
  // console.log(milestoneId);
  //       var startDate = $('#taskstartDate').val();
  //       var endDate = $('#taskendDate').val();
  //   $scope.datatasks['startDate']=startDate;
  //   $scope.datatasks['endDate']=endDate;
  //   $scope.datatasks['task']=task;
  //   $scope.datatasks['projectId']=id;
  //   // $scope.datatasks['companyId']=companyId;
  //   $scope.datatasks['milestoneId']=milestoneId;

  //          $scope.upload = $upload.upload({
  //               url: 'saveTaskFrmMile',
  //               //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
  //               data:$scope.datatasks,
  //               //file: $scope.file,
  //                // file: $scope.file,
  //                headers: {'Content-Type': undefined},
  //           }).then(function(response) {
  //             if (action == 'close') {
  //               notify({
  //                    message:"Task Added successful.",
  //                    template:'templates/success.html',
  //                    position:'center'
  //                     });
  //                   location.reload();
  //               } else {
  //                     $scope.task = "";
  //                     notify({
  //                    message:"Task Added successful.",
  //                    template:'templates/success.html',
  //                    position:'center'
  //                     });
  //               }
  //           // location.reload();  
  //           }, function(response) {
  //                  notify({
  //             message:"Please Fill All The Fields Correct.",
  //             template:'templates/error.html',
  //             position:'center'
  //           });
  //           }, function(evt) {
  //               // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
  //           });
            // location.reload();  
  // console.log($scope.data);
        };

 

     
  $rootScope.UpdateTask = function(task) {
    if ($scope.datatasks) {
        var taskUpdatestartDate = $('#taskUpdatestartDate').val();
        var taskUpdateendDate = $('#taskUpdateendDate').val();
        // var file = $scope.datatasks;
         $scope.datatasks['taskUpdatestartDate']=taskUpdatestartDate;
         $scope.datatasks['taskUpdateendDate']=taskUpdateendDate;
         $scope.datatasks['task']=task;
        // console.log(taskUpdatestartDate);
        // console.log(task);      
    // console.log(file);
        // var url = "UpdateTask";

        $scope.upload = $upload.upload({
                url: 'UpdateTask',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(resp) {
            }, function(resp) {
                console.log('Error status: ' + resp.status);
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            

            });
          } else {
            var taskUpdatestartDate = $('#taskUpdatestartDate').val();
           var taskUpdateendDate = $('#taskUpdateendDate').val();
            var title = $scope.task.title;
             var message = $scope.task.message;
             var assign_to = $scope.task.assign_to;
             var type_id = $scope.task.type_id;
             var priority = $scope.task.priority;
       // console.log(task);
        if (task==null || title==null || message==null || assign_to==null || type_id==null || priority==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
            TaskService.UpdateTask(task,taskUpdatestartDate,taskUpdateendDate).success(function(response) { 
           
            location.reload();  
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
        }
    
    }
      $rootScope.UpdateStatus = function(taskId,projectId,assigneto,statusId) {
        // console.log(assigneto)
       TaskService.UpdateStatus(taskId,projectId,assigneto,statusId).success(function(data, status) {
            // location.reload();
        //$scope.statusId = data.statusId;
                 $scope.tasks = data.tasks;
                 $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // $scope.tasks[$index] = data.tasks[0];
        // console.log($scope.tasks[$index]);
      TaskService.getTask(taskId).success(function(data, status){  
      $scope.tasksData = data.tasksData;
      $scope.tasksDetails = data.tasksDetails;
      $scope.tasksassign = data.tasksassign;
      $scope.comments = data.comments;
      
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
        TaskService.getMilestoneTasks($scope.milestoneId).success(function(data, status) {  
        // $rootScope.task_milestones = data.task_milestones;
         // $scope.task_milestones = new NgTableParams({}, { dataset:data.task_milestones })
          $scope.task_milestones = data.task_milestones;
          $scope.task_milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.task_milestones.length,
                    counts: [10, 20, 50, 100,$scope.task_milestones.length],
                    getData: function( params) {
                      params.total($scope.task_milestones.length);
                    $scope.data = $scope.task_milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

      // $rootScope.task_milestones_status = data.task_milestones_status;

      $rootScope.milestone = data.milestone;
      // console.log( $rootScope.task_milestones);
      }).error(function(data, status) {
        alert('something went wrong');
      });
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
         TaskService.getTasksNew('New',projectId,$scope.milestoneId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
        $scope.tasksnewmil = data.task_milestones_status;
        $scope.tasksnewarch = data.archv_statuses;

                 console.log(  $scope.task_milestones_status);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksClosed('Closed',projectId,$scope.milestoneId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
        $scope.tasksClosedmil = data.task_milestones_status;
        $scope.tasksClosedarch = data.archv_statuses;

            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId,$scope.milestoneId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
        $scope.tasksInProgressmil= data.task_milestones_status;
        $scope.tasksInProgressarch = data.archv_statuses;
        
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId,$scope.milestoneId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
        $scope.tasksResolvedmil = data.task_milestones_status;
        $scope.tasksResolvedarch = data.archv_statuses;
                 
            }).error(function(data, status) {
                alert('something went wrong');
         });

    }

          $rootScope.UpdateStatusArchive = function(taskId,projectId,statusId) {
       TaskService.UpdateStatusArchive(taskId,projectId,statusId).success(function(data, status) {

               $scope.tasks = data.tasks;
          $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                     // filter: { task: "" }, 
                    // sorting: { task: "asc", created_by: "asc", task_type: "asc",assigned_to: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function (params) {
                      params.total($scope.tasks.length);
                        // params.total($filter('filter')($scope.tasks.length));
                        //   $scope.data = $filter('filter')( $scope.SearchedItem);
                         $scope.data =  $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());
                            return $scope.data;
                         }
         
            })
          $scope.tasksArchived = data.tasksArchived;
          $scope.tasksArchivedtable = new NgTableParams({
                    page: 1,
                    count: 10,

                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.tasksArchived.length,
                    counts: [10, 20, 50, 100,$scope.tasksArchived.length],
                    getData: function( params) {
                    params.total($scope.tasksArchived.length);
                    $scope.data = $scope.tasksArchived.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            // location.reload();
        //$scope.statusId = data.statusId;
        // $scope.tasks = data.tasks;
        // console.log($scope.tasks[$index]);
      TaskService.getTask(taskId).success(function(data, status){  
      $scope.tasksData = data.tasksData;
      $scope.tasksDetails = data.tasksDetails;
      $scope.tasksassign = data.tasksassign;
      console.log(data);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
         
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
       TaskService.getTasksNew('New',projectId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
                 console.log(  $scope.tasksnew);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

        TaskService.getTasksClosed('Closed',projectId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
                 console.log(  $scope.tasksInProgress);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

    }
     $rootScope.setFav = function(taskId,projectId,statusId) {
       TaskService.setFav(taskId,projectId,statusId).success(function(data, status) {
            // location.reload();
        //$scope.statusId = data.statusId;
        // $scope.tasks = data.tasks;
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
                 $scope.tasks = data.tasks;
                 $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

        // console.log(data.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
       TaskService.getTasksNew('New',projectId).success(function(data, status) {           
        $scope.tasksnew = data.statuses;
                 console.log(  $scope.tasksnew);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

        TaskService.getTasksClosed('Closed',projectId).success(function(data, status) {   
        $scope.tasksClosed = data.statuses;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksInProgress('In Progress',projectId).success(function(data, status) {          
        $scope.tasksInProgress= data.statuses;
                 console.log(  $scope.tasksInProgress);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
            TaskService.getTasksResolved('Resolved',projectId).success(function(data, status) {         
        $scope.tasksResolved = data.statuses;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

    }

       $rootScope.completeMile = function(milestoneId,projectId,isactiveId) {
        // console.log(milestoneId);

       TaskService.completeMile(milestoneId,projectId,isactiveId).success(function(data, status) {
            // location.reload();
        //$scope.statusId = data.statusId;
        $scope.milestones = data.milestones;
             $scope.milestonestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.milestones.length,
                    counts: [10, 20, 50, 100,$scope.milestones.length],
                    getData: function( params) {
                      params.total($scope.milestones.length);
                    $scope.data = $scope.milestones.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
         
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

    }
     $rootScope.UpdatePriority = function(taskId,projectId,priorityId) {
       TaskService.UpdatePriority(taskId,projectId,priorityId).success(function(data, status) {
        // $scope.tasks[$index] =data.tasks[0];
           $scope.tasks = data.tasks;
          $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log($index);
     TaskService.getTask(taskId).success(function(data, status){  
      $scope.tasksData = data.tasksData;
      $scope.tasksDetails = data.tasksDetails;
      $scope.tasksassign = data.tasksassign;
      $scope.comments = data.comments;
      console.log($scope.comments);

      // console.log(data);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
         
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

    }

    
       $rootScope.updateMilestone = function(milestone,id,projectId,companyId) {
         var UpdatestartDate = $('#UpdatestartDate').val();
        var UpdateendDate = $('#UpdateendDate').val();
        // console.log(id);
   if ( $scope.milestone.mtitle==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
       TaskService.updateMilestone(milestone,id,projectId,companyId,UpdatestartDate,UpdateendDate).success(function(data, status) {
        $scope.milestone =data.milestone;
        // console.log($scope.milestone );
         location.reload();
        
      }).error(function(data, status) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      }); 
      }
    }
    $scope.IsVisible = false;
    $rootScope.display = function (displaystatus,projectId) {
      $scope.displaystatus = displaystatus;
      $scope.IsVisible = true;
         console.log($scope.IsVisible);

        /*TaskService.getTasks(projectId).success(function(data, status) {   
        $scope.tasks = data.tasks;
         $scope.displaystatus = displaystatus;
         console.log(displaystatus);
      }).error(function(data, status) {
        alert('something went wrong');
      });   */  
    }
    $rootScope.taskCategory = function (projectId,categoryid) {
      TaskService.taskCategory(projectId,categoryid).success(function(data, status) {   
        $scope.taskcategory.categoryId = categoryid;
        $scope.projecttasks = data;
         
                //  console.log(data);

         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
          $scope.tasks = data.tasks;
            // $scope.userId = data.userId;
              $scope.taskstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // filter: { task: "" }, 
                    // sorting: { task: "asc" },
                },
                {
                    total: $scope.tasks.length,
                    counts: [10, 20, 50, 100,$scope.tasks.length],
                    getData: function( params) {
                      params.total($scope.tasks.length);
                    $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
                    }
                // $scope: $scope
            });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
             TaskService.getTasksCatNew(projectId,categoryid,'New').success(function(data, status) {           
        $scope.tasksnew = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatClosed(projectId,categoryid,'Closed').success(function(data, status) {   
        $scope.tasksClosed = data.statusesCat;
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatInProgress(projectId,categoryid,'In Progress').success(function(data, status) {          
        $scope.tasksInProgress= data.statusesCat;
                 console.log(  $scope.tasksInProgress);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
        TaskService.getTasksCatResolved(projectId,categoryid,'Resolved').success(function(data, status) {         
        $scope.tasksResolved = data.statusesCat;
                 console.log( $scope.statuses);
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
   }

       $rootScope.setFavcheck = function(taskId,projectId,favId) {  
       TaskService.setFavcheck(taskId,projectId,favId).success(function(data, status) {

      //     TaskService.taskCategory(projectId,categoryid).success(function(data, status) {   
      //   // $scope.tasks = data.tasks;
      //    // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
      //    $scope.tasks = data.tasks;
      //     $scope.taskstable = new NgTableParams({
      //               page: 1,
      //               count: 10,
      //               // sorting: { effective_dt: "asc" },
      //           },
      //           {
      //                  total: $scope.tasks.length,
      //               counts: [10, 20, 50, 100,$scope.tasks.length],
      //               getData: function( params) {
      //                 params.total($scope.tasks.length);
      //               $scope.data = $scope.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
      //               return $scope.data;
   
      //               }
      //           // $scope: $scope
      //       });

      //   $scope.userId = data.userId;
      //    TaskService.getTasksCatNew(projectId,categoryid,'New').success(function(data, status) {           
      //   $scope.tasksnew = data.statusesCat;
      //       }).error(function(data, status) {
      //           alert('something went wrong');
      //       }); 
      //   TaskService.getTasksCatClosed(projectId,categoryid,'Closed').success(function(data, status) {   
      //   $scope.tasksClosed = data.statusesCat;
      //            console.log( $scope.statuses);
      //       }).error(function(data, status) {
      //           alert('something went wrong');
      //       }); 
      //   TaskService.getTasksCatInProgress(projectId,categoryid,'In Progress').success(function(data, status) {          
      //   $scope.tasksInProgress= data.statusesCat;
      //            console.log(  $scope.tasksInProgress);
      //       }).error(function(data, status) {
      //           alert('something went wrong');
      //       }); 
      //   TaskService.getTasksCatResolved(projectId,categoryid,'Resolved').success(function(data, status) {         
      //   $scope.tasksResolved = data.statusesCat;
      //            console.log( $scope.statuses);
      //       }).error(function(data, status) {
      //           alert('something went wrong');
      //       }); 

      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
            // location.reload();
        //$scope.statusId = data.statusId;
        // $scope.tasks = data.tasks;

         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks})

        // console.log(data.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

    }
    
   
$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id,modelname)
  }
  $scope.removefile = function(id,taskId){
  console.log(taskId);
    TaskService.removefile(id,taskId).success(function(data, status) {   
        $scope.attachments = data.attachments;
      }).error(function(data, status) {
        alert('something went wrong');
      });
  }
	}); 