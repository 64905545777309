'use strict';
 main.controller('ProjectsController',function($rootScope,$window,ProjectService,DepartmentService,TaskService,$stateParams,globalService, actions, $filter, $scope,$http,$modal, $log, notify, modalService,$upload,NgTableParams) {
     
       $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}
     $scope.projectId=$stateParams.projectId;      
     $scope.companyId=$stateParams.companyId;      
     $scope.user_id=$stateParams.user_id; 
    $('#projectstartDate').datepicker({
        uiLibrary: 'bootstrap',
        });
   $('#projectendDate').datepicker({
        uiLibrary: 'bootstrap',
        });

   //  $('#projectUpdatestartDate').datepicker({
   //      uiLibrary: 'bootstrap',
   //      });
   // $('#projectUpdateendDate').datepicker({
   //      uiLibrary: 'bootstrap',
   //      });

   ProjectService.logs().success(function(data, status) {   
    $scope.logs = data.logs;
    $scope.role = data.role;
  
      $scope.logstable = new NgTableParams({
                page: 1,
                count: 10,
                // sorting: { effective_dt: "asc" },
            },
            {
                   total: $scope.logs.length,
                counts: [10, 20, 50, 100,$scope.logs.length],
                getData: function( params) {
                  params.total($scope.logs.length);
                $scope.data = $scope.logs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                return $scope.data;

                }
            // $scope: $scope
        });

  }).error(function(data, status) {
    alert('something went wrong');
  }); 


    $scope.projects = function (departmentId) {     
      ProjectService.getProjects(departmentId).success(function(data, status) {   
        $scope.active = data.activePage;
        // console.log(data.project_user_cache);
         // $scope.projects = new NgTableParams({}, { dataset:data.projects })
          $scope.projects = data.projects;
        console.log(data.projects.length);

          $scope.role = data.role;
          // var departmentId = data.project_user_cache.id;
          $scope.projectstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.projects.length,
                    counts: [10, 20, 50, 100,$scope.projects.length],
                    getData: function( params) {
                      params.total($scope.projects.length);
                    $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log(data.projects);

        // $scope.searchItem = function(searchedItem){
        //         $scope.searchedItem = searchedItem;
        //         $scope.projects.reload();
        //     }
        //     $scope.projects = new NgTableParams({
        //         page: 1,
        //         count: 10,
        //     },
        //     {
        //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
        //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
        //     getData: function($defer, params) {
        //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
        //         var orderedData = params.sorting() ?
        //         $filter('orderBy')(filteredData, params.orderBy()) :
        //         filteredData;
        //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        //     },
        //     $scope: $scope
        // });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      // ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
      // $rootScope.project_users = data.project_users;
      // console.log(data.project_users);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
    }

  $(document).ready(function() {
    console.log(location.pathname);
  $('li.active').removeClass('active');
  $('a[href="' + location.pathname + '"]').closest('li').addClass('active'); 
     });


    //   $rootScope.downloadExcel = function(projectId) {
    // // console.log(projectId);
    //   ProjectService.downloadExcel(projectId).success(function(data, status) {  
    //      var url = 'index.php/downloadExcel/xls/?data='+"&projectId="+projectId; 

    //        $window.open(url);
    //   }).error(function(data, status) {
    //     alert('something went wrong');
    //   }); 
    //   }

      $scope.downloadExcel = function(projects,projectId) {
            ProjectService.downloadExcel(projects,projectId).success(function(data, status) {
                var url = 'index.php/downloadExcel/xls/?data='+projects +"&projectId="+projectId;
                $window.open(url);
            });
        };
      $rootScope.activateDeactivate = function (projectId,actDeaId) {
    ProjectService.activateDeactivate(projectId,actDeaId).success(function(data, status) {   
        // $rootScope.profile = data;
      // console.log($rootScope.profile);
           $scope.projects = data.projects;
          $scope.projectstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.projects.length,
                    counts: [10, 20, 50, 100,$scope.projects.length],
                    getData: function( params) {
                      params.total($scope.projects.length);
                    $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
      }).error(function(data, status) {
         alert('something went wrong');
      }); 
     }
     $rootScope.projectTask = function(projectId) {
      $rootScope.getProject(projectId);
     }
      $rootScope.getProject = function(projectId) {
      ProjectService.getProject(projectId).success(function(data, status) {  
      $rootScope.project = data.project;
      $scope.projects = data.projects;
      $rootScope.loggeduser = data.loggeduser;
      $scope.projectId = projectId;
      // ProjectService.getProjects(departmentId).success(function(data, status) {   
      //   // $scope.projects = data.projects;
      //    // $scope.projects = new NgTableParams({}, { dataset:data.projects })
      //     $scope.projects = data.projects;
      //     var departmentId = data.project_user_cache.id;

      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 

      TaskService.updateprojectcache(projectId).success(function(data, status) {         
            }).error(function(data, status) {
                alert('something went wrong');
            }); 

           
        ProjectService.getAllTaskType(projectId).success(function(data, status) {     
        $scope.TypeNames = data.TypeNames;
        console.log(data.TypeNames);
  var chart = AmCharts.makeChart( "chartdiv", {
  "type": "funnel",
  "theme": "light",
  "dataProvider": $scope.TypeNames, 
  "balloon": {
    "fixedPosition": true
  },
  "valueField": "value",
  "titleField": "title",
  "marginRight": 240,
  "marginLeft": 50,
  "startX": -500,
  "depth3D": 100,
  "angle": 20,
  "outlineAlpha": 1,
  "outlineColor": "#FFFFFF",
  "outlineThickness": 2,
  "labelPosition": "right",
  "balloonText": "[[title]]: [[value]]n[[description]]",
  "export": {
    "enabled": false
  }
  
} );
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    
      console.log(data.project);
          ProjectService.getTasks(projectId).success(function(data, status) {   
        $scope.tasks = data.tasks;
        console.log(  $scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      });


    var validator = new FormValidator('project_Form', [{
    name: 'pname',
    display: 'project Name',
    rules: 'required'
}
// , {
//     name: 'alphanumeric',
//     rules: 'alpha_numeric'
// }, {
//     name: 'password',
//     rules: 'required'
// }, {
//     name: 'password_confirm',
//     display: 'password confirmation',
//     rules: 'required|matches[password]'
// }, {
//     name: 'email',
//     rules: 'valid_email',
//     depends: function() {
//         return Math.random() > .5;
//     }
// }, {
//     name: 'minlength',
//     display: 'min length',
//     rules: 'min_length[8]'
// }
], 
    function(errors, event) {
    if (errors.length > 0) {
        var errorString = '';

        for (var i = 0, errorLength = errors.length; i < errorLength; i++) {
            errorString += errors[i].message + '<br />';
        }

        el.innerHTML = errorString;
    }
}); 
    validator.setMessage('required', 'You must fill out the %s field.');


      ProjectService.getAllTaskStatus(projectId).success(function(taskcount, status) {           
    $scope.alltasksStatus = taskcount;
    new Chart(document.getElementById("pie-chart"), {
    type: 'pie',
    data: {
      labels: ["New", "In Progress", "Resolved", "Closed"],
      datasets: [{
        label: "",
        backgroundColor: ["red", "blue","yellow","green"],
        data: taskcount
      }]
    },
    options: {
      title: {
        display: false,
        text: ''
      }
    }
  });
          console.log( $scope.alltasksStatus);
                 // console.log( projectId );
            }).error(function(data, status) {
                alert('something went wrong');
            }); 
      

      }).error(function(data, status) {
        alert('something went wrong');
      });

      ProjectService.getProjectUsers(projectId).success(function(data, status) {  
      $rootScope.project_users = data.project_users;
      console.log(data.project_users);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      
    // } 
       ProjectService.getUsersNotInProject(projectId).success(function(data, status) {   
        
        $scope.users = data.users;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });
 
    }

    $scope.projectDep = function (departmentId) {     
      ProjectService.getProjects(departmentId).success(function(data, status) {   
        $scope.active = data.activePage;
        // console.log($scope.active);
         // $scope.projects = new NgTableParams({}, { dataset:data.projects })
          $scope.projects = data.projects;
          $scope.projectstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.projects.length,
                    counts: [10, 20, 50, 100,$scope.projects.length],
                    getData: function( params) {
                      params.total($scope.projects.length);
                    $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log(data.projects);

        // $scope.searchItem = function(searchedItem){
        //         $scope.searchedItem = searchedItem;
        //         $scope.projects.reload();
        //     }
        //     $scope.projects = new NgTableParams({
        //         page: 1,
        //         count: 10,
        //     },
        //     {
        //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
        //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
        //     getData: function($defer, params) {
        //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
        //         var orderedData = params.sorting() ?
        //         $filter('orderBy')(filteredData, params.orderBy()) :
        //         filteredData;
        //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        //     },
        //     $scope: $scope
        // });
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      // ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
      // $rootScope.project_users = data.project_users;
      // console.log(data.project_users);
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // }); 
      DepartmentService.updatedepcache(departmentId).success(function(data, status) {         
      }).error(function(data, status) {
          alert('something went wrong');
      }); 
    }
        
    
        ProjectService.getCompanies().success(function(data, status) {   
        
        $scope.companies = data.companies;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
       ProjectService.getCompany().success(function(data, status) {   
        
        $scope.company = data.company;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      ProjectService.getTypes().success(function(data, status) {   
        
        $scope.types = data.types;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      DepartmentService.getDepartments().success(function(data, status) {   
        
        $scope.departments = data.departments;
        $scope.departmentId = data.departmentId;
        // console.log(data.departmentId)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      ProjectService.getPriorities().success(function(data, status) {   
        
        $scope.Priorities = data.Priorities;
        console.log($scope.Priorities)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

       // $rootScope.getProjectUsers = function(projectId) {
 



  $scope.lst = [];
$scope.change = function(user, active){
    if (active){
        $scope.lst.push(user);
      // console.log($scope.lst);
    }
    else{
        $scope.lst.splice($scope.lst.indexOf(user), 1);
         console.log($scope.lst);
    }
};

   $scope.IsVisible = false;
        $rootScope.display = function (displaypriority) {
        // console.log(displayIsactive);
         $scope.IsVisible = true;
        $scope.displaypriority = displaypriority; 
         }
   $scope.toggleProject = function(modalstate, id,project_name="") {
      $scope.modalstate = modalstate;
      ProjectService.getProjectUsers(id).success(function(data, status) {  
      $rootScope.project_users = data.project_users;
      console.log(data.project_users);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    // } 
       ProjectService.getUsersNotInProject(id).success(function(data, status) {   
        
        $scope.users = data.users;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
       $('#task_startDate').datepicker({
            uiLibrary: 'bootstrap',
            minDate: today,
           change: function (e) {

         },
            maxDate: function () {
                return $('#task_endDate').val();
            }
        });
      $('#task_endDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            minDate: function () {
                return $('#task_startDate').val();
            }
        });

      ProjectService.toggleProject(id).success(function(data, status) {  
      $scope.project = data.project;
      $scope.proj_start = data.project.start_date;
      $scope.proj_end = data.project.end_date;
      console.log(data.project.start_date);
      // $scope.name = data.user.name;
      // $scope.email = data.user.email;
      // $scope.role_id = data.user.role_id;
      // $scope.company_id = data.user.company_id;
      // console.log(id);
           $('#projectUpdatestartDate').datepicker({
        uiLibrary: 'bootstrap',
            setValue: data.project.start_date
        });
              $('#projectUpdateendDate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.project.end_date
        });
      })



        switch (modalstate) {
         case 'edit':
                $scope.form_title = "Edit Project";
                 $('#myModal1').modal('show');
                break;

                case 'add':
                $scope.form_title = "Add New Project";
                 $('#myModal').modal('show');
                break;

                 case 'assignusers':
                $scope.form_title = "Assign User(s) to -";
                $scope.project_name = project_name;
                $scope.projectId = id;
                
                 $('#myModal4').modal('show');
                break;

                case 'removeusers':
                $scope.form_title = "Remove User(s) from -";
                 $scope.project_name = project_name;
                $scope.projectId = id;
                 $('#myModal3').modal('show');
                break;
                
                 case 'taskadd':
                $scope.form_title = "Add New Task";
                $scope.task={project_id:id};
                $('#myModal5').modal('show');
                break;
        }
        // console.log(id)
         // $('#myModal1').modal('show');
    }


        // console.log(modalstate);

        $scope.folder = {};
        $scope.remove = {};
    $scope.albumNameArray = [];
    $rootScope.assignusers = function(projectId) {
        console.log(projectId)
      
        angular.forEach($scope.folder,function(key,value){
            if(key)
                $scope.albumNameArray.push(value)
        });

       ProjectService.assignusers($scope.albumNameArray,projectId).success(function(data){
          console.log($scope.albumNameArray)
            notify({
              message:"Users added to project.",
              template:'templates/success.html',
              position:'center'
            });
            location.reload();  

          })
       
    }
     $scope.remove = {};
    $scope.albumArray = [];
    $rootScope.removeusers = function(projectId) {
        // console.log(projectId)

        angular.forEach($scope.remove,function(key,value){
            if(key)
                $scope.albumArray.push(value)
        });
        console.log($scope.albumArray)

       ProjectService.removeusers($scope.albumArray,projectId).success(function(data){
          console.log($scope.albumArray)
            notify({
              message:"Users removed from the project.",
              template:'templates/success.html',
              position:'center'
            });
            location.reload();  
            
          })
       
    }

    $scope.onFileSelect = function($files){
    console.log($files);
   //$scope.file = $files;
   $scope.datatasks={};
     //$scope.file = {};
        for (var i = $files.length - 1; i >= 0; i--){
             $scope.datatasks['file'+i]=$files[i];
        }
    $scope.datatasks['filecount']=$files.length;
    console.log($scope.datatasks);
   }

  $scope.saveTask = function(task,id,projectId,companyId,action) {
  // console.log(companyId);
  // console.log(task);
     var startDate = $('#task_startDate').val();
    var endDate = $('#task_endDate').val();
    $scope.datatasks['startDate']=startDate;
    $scope.datatasks['endDate']=endDate;
    $scope.datatasks['task']=task;
    $scope.datatasks['projectId']=projectId;
    $scope.datatasks['companyId']=companyId;
    // console.log($scope.datatasks);


   $scope.upload = $upload.upload({
                url: 'tasks',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
            
  // console.log($scope.data);
        };
   

     // save new record & update existing record
    $rootScope.save = function(project,id, action) {

        var startDate = $('#projectstartDate').val();
        var endDate = $('#projectendDate').val();

      var pname = $scope.project.pname;
       var short_name = $scope.project.short_name;
       var description = $scope.project.description;
       var project_type = $scope.project.project_type;
        // console.log(description);
       if (project==null || pname==null || short_name==null || description==null || project_type==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {
      ProjectService.save(project,id,startDate,endDate,action).success(function(data, status) {
                  if (action == 'close') {
                    notify({
                     message:"Project Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.project = "";
                      notify({
                     message:"Project Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                } 
      }).error(function(data, status) {
        alert('something went wrong');
      });
    }
    
    }
      
    $rootScope.updateP = function(project,id) {
       var startDate = $('#projectUpdatestartDate').val();
        var endDate = $('#projectUpdateendDate').val();
     var pname = $scope.project.pname;
       var short_name = $scope.project.short_name;
       var description = $scope.project.description;
       var project_type = $scope.project.project_type;
        // console.log(description);
       if (project==null || pname==null || short_name==null || description==null || project_type==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {
      ProjectService.updateP(project,id,startDate,endDate).success(function(data, status) {
         notify({
                     message:"Project Updated successful.",
                     template:'templates/success.html',
                     position:'center'
                      });     
        location.reload();
        // console.log($scope.milestone );
      }).error(function(data, status) {
        alert('something went wrong');
      });
        // var url = "updateP";
        // $http({
        //     method: 'POST',
        //     url: url,
        //     data: $.param($scope.project),
        //     headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        // }).success(function(response) {
        //    notify({
        //              message:"Project Updated successful.",
        //              template:'templates/success.html',
        //              position:'center'
        //               });     
        //     // console.log(response);
        //     // location.reload();
        // }).error(function(response) {
        //     // console.log(response);
        //     alert('An error has occured. Please check the log for details');
        // });
      }
    }
     
 

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id, modelname)
  
  }

  }); 