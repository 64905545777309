'use strict';
 main.controller('TypesController',function($rootScope,TypeService,globalService, $scope,$http, actions, $modal, $log, notify, modalService,NgTableParams) {
            
		$scope.types = function () {
           
			TypeService.getTypes().success(function(data, status) {   
				
        $scope.types = data.types;
            $scope.typestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.types.length,
                    counts: [10, 20, 50, 100,$scope.types.length],
                    getData: function( params) {
                      params.total($scope.types.length);
                    $scope.data = $scope.types.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
				
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

        //disply modalform.
        $scope.toggleType = function(modalstate, id) {
        $scope.modalstate = modalstate;
      TypeService.toggleType(id).success(function(data, status) {  
      $scope.type = data.type;
      })
        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Type";
                $('#myModal').modal('show');
                break;
                case 'edit':
                $scope.form_title = "Edit Type";
                $('#myModal1').modal('show');
                break;
 
        }
        // console.log(id)
         
    }


   

     // save new record & update existing record
    $rootScope.save = function(modalstate,id,typedata,action) {
            // console.log(typedata);

        var url = "types";
         var tname = $scope.typedata.tname;
          var short_name = $scope.typedata.short_name;
        if ( tname==null || short_name==null || typedata==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else {
        $http({
            method: 'POST',
            url: url,
            data: $.param(typedata),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data, status) {
        $scope.types = data.types;
        // console.log($scope.types);
                 if (action == 'close') {
                    notify({
                     message:"Type Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.typedata = "";
                      notify({
                     message:"Type Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
        }).error(function(data, status) {
                 notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
        });
      }
    }
     
     
  $rootScope.updateT = function(type,id) {
        var url = "updateT";

        var tname = $scope.type.tname;
          var short_name = $scope.type.short_name;
        if ( tname==null || short_name==null || type==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else {
        $http({
            method: 'POST',
            url: url,
            data: $.param($scope.type),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });
       }
    }

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id,modelname)
  }


	}); 

  