'use strict';
 main.controller('TimelogsController',function($rootScope,modalService,ProjectService,DepartmentService,UnitService,BudgetService,TimelogService,globalService,TaskService,$window,$upload, $scope,$http, actions, $modal, $log, notify, modalService,NgTableParams) {
                
                 $scope.start_time = new Date();
                 $scope.breakstart_time = new Date();
                 $scope.end_time = new Date();
                 $scope.breakend_time = new Date();
                 // $scope.breaktime = "";
                 $scope.showMeridian = true;
  
   $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}

        $scope.from = new Date();
        $scope.from.setMonth($scope.from.getMonth()-1);
        $scope.to = new Date();

    var n = new Date().getFullYear();
    var d = new Date(n,0,1);
    var searchModel = {};
    //var firstD = new Date(d.getFullYear(), d.getMonth(), 1);
    var firstD = moment([d.getFullYear(), d.getMonth()]).format('YYYY-MM-DD');
        // Clone the value before .endOf()
    var lastD = moment(firstD).endOf('year').format('YYYY-MM-DD');
    /*Date Picker starts*/
$scope.searchModel = {datePicker:{}};
$scope.searchModel.datePicker.date = {startDate: null, endDate: null};
$scope.options = {
  locale: {
    applyClass: 'btn-green',
    //applyLabel: "Apply",
    //fromLabel: "From",
      format: "YYYY-MM-DD", //will give you 2017-01-06
  //format: "D-MMM-YY", //will give you 6-Jan-17
  //format: "D-MMMM-YY", //will give you 6-January-17
  separator: " - ",
  //toLabel: "To",
  //cancelLabel: 'Cancel',
  //autoApply: true,
  //customRangeLabel: 'Custom range'
},
startDate: firstD,
endDate: lastD,
showDropdowns: true,
autoApply: true,
linkedCalendars: false,
ranges: {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
},
eventHandlers: {'apply.daterangepicker': function(ev, picker) { 
  
  if($scope.searchModel.datePicker.date.startDate !=null)
  {
      var date = $scope.searchModel.datePicker.date;
      var startDate = date.startDate.format('YYYY-MM-DD');
      var endDate = date.endDate.format('YYYY-MM-DD');
  }
  
  //$scope.LedgerHistory(date);
        /*console.log(startDate);
        console.log(date.startDate.format('YYYY-MM-DD'));
      console.log(date.endDate.format('YYYY-MM-DD'));*/}}
  }
  /*$scope.$watch('datePicker.date',function(){
    var date = $scope.datePicker.date;
    $scope.$on('apply.daterangepicker', function(ev, picker) {
  console.log(picker.startDate.format('YYYY-MM-DD'));
  console.log(picker.endDate.format('YYYY-MM-DD'));
});
    console.log(date);
  });
  */
/*Date Picker Ends*/
$rootScope.firstd = firstD;
$rootScope.lastd = lastD;

$scope.filterTimelog = function(month,projectId) { 

  if (projectId == null){
    notify({
      message:"Please Select A Project First.",
      template:'templates/error.html',
      position:'center'
    });
  }else {
  // $rootScope.get_rows_per_page();
  
  if (month.startDate != null) 
  {
    var date = month;
    var startDate = date.startDate.format('YYYY-MM-DD');
    var endDate = date.endDate.format('YYYY-MM-DD');
  }
  else
  {
    var startDate = $rootScope.firstd;
    var endDate = $rootScope.lastd;
  }
  
  TimelogService.filterTimelog(startDate,endDate,projectId).success(function(data, status, headers, config) {
    $scope.timelogs = data.timelogs;

            
    $scope.timelogstable = new NgTableParams({
              page: 1,
              count: 10,
              // sorting: { effective_dt: "asc" },
          },
          {
                 total: $scope.timelogs.length,
              counts: [10, 20, 50, 100,$scope.timelogs.length],
              getData: function( params) {
                params.total($scope.timelogs.length);
              $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
              return $scope.data;

              }
          // $scope: $scope
      });
      // $scope.timelogs = data.timelogs;
      // console.log($scope.timelogs);
      $scope.role = data.role;
      $scope.timelog_users = data.timelog_users;

      $scope.uiConfig = {
          calendar:{
          height: 450,
          editable: true,
          header:{
            left: 'month basicWeek basicDay agendaWeek agendaDay',
            center: 'title',
            right: 'today prev,next'
          },
          events: $scope.timelogs,
          // eventClick: $scope.alertEventOnClick,
          // eventDrop: $scope.alertOnDrop,
          // eventResize: $scope.alertOnResize
          }
        };
   
  }).error(function(data, status, headers, config) {
    alert('something went wrong, thats all we know');
  });
}
}



       $scope.callme =function(start_time,end_time,breakstart_time,breakend_time){
                // console.log(breaktime );
           //        var start_Time =$rootScope.start_time;
           // var end_Time = $rootScope.end_time;
            // var break_time = $scope.hourbreak_time;
           // var minutesbreak_time =  $scope.minutesbreak_time;
           // var rate_value =  $scope.rate.value;

          
           var startTime=moment(start_time, "HH:mm:ss a");
           var breakstartTime=moment(breakstart_time, "HH:mm:ss a");
           var endTime=moment(end_time, "HH:mm:ss a");
           var breakendTime=moment(breakend_time, "HH:mm:ss a");
           // var breakTime=moment(breaktime, "HH:mm:ss a");
          
          

           var duration = moment.duration(endTime.diff(startTime));
           var duration2 = moment.duration(breakendTime.diff(breakstartTime));
          $scope.result = (0+":"+ 0);

           if (duration > duration2) {
            var duration3 = moment.duration(duration - duration2);
             $scope.timedecimal = moment.duration(duration3).asHours();
             var hours = parseInt(duration3.asHours());
           var minutes = parseInt(duration3.asMinutes())-hours*60;
          $scope.result = (hours+":"+ minutes);

           }else{
             $scope.timedecimal = 0;

           }
           
           // var duration4 = moment.utc(moment.duration(duration3, "seconds").asMilliseconds()).format("HH:mm");

           // var duration2 = moment.duration(duration1.diff(breakTime));

           // var duration2 = moment(breakTime, "HH:mm:ss a");
          

           // var hours2 = parseInt(duration2.asHours());
           // var minutes2 = parseInt(duration2.asMinutes())-hours2*60;

           // var hours = hours1 - hours2;
           // var minutes = minutes1 - minutes2;

          
         

           // console.log(hours1)
          // console.log(minutes1)
           // var hours1 = Number(hours - hourbreak_time)-1;
           // var minutes1 = Number(minutes - minutesbreak_time) + 60;
          return $scope.result;
           
            }
              

            //  $('#input_endtime').on('changeTime.timepicker', function(e) {
            //   $('#timeDisplayend').text(e.time.value);
            //   end = e.time.value;
            //   $rootScope.end_time = e.time.value;
            //   $scope.callme();
            // });
    

        $('#input_breaktime').timepicker({
         minuteStep: 1,
                template: 'modal',
                appendWidgetTo: 'body',
                // showSeconds: true,
                showMeridian: false,
                defaultTime: false
        });
             setTimeout(function() {
          $('#timeDisplaybreaktime').text($('#input_breaktime').val());
            }, 100);     
         //      setTimeout(function() {
         //  $('#timeDisplayend').text($('#input_endtime').val());
         //    }, 100);
             var start = "";
         //     var end = "";
            $('#input_breaktime').on('changeTime.timepicker', function(e) {
              $('#timeDisplaybreaktime').text(e.time.value);
              // console.log(e.time.value);
             start = e.time.value;
              $rootScope.breaktime = e.time.value;
              $scope.callme();

            });

     $('#taskstartDate').datepicker({
            uiLibrary: 'bootstrap',
            minDate: today,
           change: function (e) {

         },
            maxDate: function () {
                return $('#taskendDate').val();
            }
        });
              $('#taskendDate').datepicker({
            uiLibrary: 'bootstrap',
            change: function (e) {
         },
            minDate: function () {
                return $('#taskstartDate').val();
            }
        });
        
        var departmentId = $scope.departmentId; 

        // ProjectService.getProjects(departmentId).success(function(data, status) {   
        //   $scope.active = data.activePage;
        //   // console.log($scope.active);
        //    // $scope.projects = new NgTableParams({}, { dataset:data.projects })
        //     $scope.projects = data.projects;
        //     $scope.project_users = data.project_users;
        //     $scope.project_usseradded = data.project_usseradded;
        // $scope.role = data.role;
        //     $scope.company_projects = data.company_projects;
        //     $scope.projectstable = new NgTableParams({
        //               page: 1,
        //               count: 10,
        //               // sorting: { effective_dt: "asc" },
        //           },
        //           {
        //                  total: $scope.projects.length,
        //               counts: [10, 20, 50, 100,$scope.projects.length],
        //               getData: function( params) {
        //                 params.total($scope.projects.length);
        //               $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
        //               return $scope.data;
     
        //               }
        //           // $scope: $scope
        //       });
        //   // console.log(data.projects);
  
        //   // $scope.searchItem = function(searchedItem){
        //   //         $scope.searchedItem = searchedItem;
        //   //         $scope.projects.reload();
        //   //     }
        //   //     $scope.projects = new NgTableParams({
        //   //         page: 1,
        //   //         count: 10,
        //   //     },
        //   //     {
        //   //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
        //   //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
        //   //     getData: function($defer, params) {
        //   //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
        //   //         var orderedData = params.sorting() ?
        //   //         $filter('orderBy')(filteredData, params.orderBy()) :
        //   //         filteredData;
        //   //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        //   //     },
        //   //     $scope: $scope
        //   // });
        // }).error(function(data, status) {
        //   alert('something went wrong');
        // }); 
      // var departmentId = $scope.departmentId;
        $scope.projectDep = function (departmentId) {     
          ProjectService.getProjects(departmentId).success(function(data, status) {   
            $scope.active = data.activePage;
            // console.log($scope.active);
             // $scope.projects = new NgTableParams({}, { dataset:data.projects })
              $scope.projects = data.projects;
              $scope.projectstable = new NgTableParams({
                        page: 1,
                        count: 10,
                        // sorting: { effective_dt: "asc" },
                    },
                    {
                           total: $scope.projects.length,
                        counts: [10, 20, 50, 100,$scope.projects.length],
                        getData: function( params) {
                          params.total($scope.projects.length);
                        $scope.data = $scope.projects.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                        return $scope.data;
       
                        }
                    // $scope: $scope
                });
            // console.log(data.projects);
    
            // $scope.searchItem = function(searchedItem){
            //         $scope.searchedItem = searchedItem;
            //         $scope.projects.reload();
            //     }
            //     $scope.projects = new NgTableParams({
            //         page: 1,
            //         count: 10,
            //     },
            //     {
            //         total:  $filter('filter')(data.projects, $scope.searchedItem).length,
            //     counts: [10,25,50,100,$filter('filter')(data.projects).length] ,// hides page sizes
            //     getData: function($defer, params) {
            //         var filteredData = $filter('filter')(data.projects, $scope.searchedItem);
            //         var orderedData = params.sorting() ?
            //         $filter('orderBy')(filteredData, params.orderBy()) :
            //         filteredData;
            //         $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            //     },
            //     $scope: $scope
            // });
          }).error(function(data, status) {
            alert('something went wrong');
          }); 

          var projectId = -1;
          TaskService.getTasks(projectId).success(function(data, status) {   
            // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
           $scope.tasks = data.tasks;
         $scope.project_user = data.project_user;
   
           $scope.taskstimelog = data.taskstimelog;
   
           $scope.tasksArchived = data.tasksArchived;
                if (data.tasks.favourite == 1)
         {
          $scope.checked = true;
          
         }
            else
           {
         $scope.checked = false;
             
          }
   
           //  console.log($scope.tasks);
         }).error(function(data, status) {
           alert('something went wrong');
         }); 
   
   
           TimelogService.getTimelogs(projectId).success(function(data, status) {         
            // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
            $scope.timelogs = data.timelogs;

            
             $scope.timelogstable = new NgTableParams({
                       page: 1,
                       count: 10,
                       // sorting: { effective_dt: "asc" },
                   },
                   {
                          total: $scope.timelogs.length,
                       counts: [10, 20, 50, 100,$scope.timelogs.length],
                       getData: function( params) {
                         params.total($scope.timelogs.length);
                       $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                       return $scope.data;
      
                       }
                   // $scope: $scope
               });
               // $scope.timelogs = data.timelogs;
               // console.log($scope.timelogs);
               $scope.role = data.role;
               $scope.timelog_users = data.timelog_users;
   
               $scope.uiConfig = {
                   calendar:{
                   height: 450,
                   editable: true,
                   header:{
                     left: 'month basicWeek basicDay agendaWeek agendaDay',
                     center: 'title',
                     right: 'today prev,next'
                   },
                   events: $scope.timelogs,
                   // eventClick: $scope.alertEventOnClick,
                   // eventDrop: $scope.alertOnDrop,
                   // eventResize: $scope.alertOnResize
                   }
                 };
                   //  console.log( $scope.timelogs);
               }).error(function(data, status) {
                   alert('something went wrong');
               });
          
          
          // ProjectService.getProjectUsers($scope.projectId).success(function(data, status) {  
          // $rootScope.project_users = data.project_users;
          // console.log(data.project_users);
          // }).error(function(data, status) {
          //   alert('something went wrong');
          // }); 
          DepartmentService.updatedepcache(departmentId).success(function(data, status) {   
                  
          }).error(function(data, status) {
              alert('something went wrong');
          }); 
          // location.reload();
        }
        

        // $scope.projectDep = function (departmentId) { 
        ProjectService.getProjects(departmentId).success(function(data, status) {    
        $scope.projects = data.projects;
        $scope.project_users = data.project_users;
        $scope.project_usseradded = data.project_usseradded;
        $scope.role = data.role;
        $scope.company_projects = data.company_projects;
        

  
        if($scope.project){

        // $scope.project = $scope.project;
        var projectId =  $scope.project.id;

      TimelogService.getTimelogs(projectId).success(function(data, status) {         
         // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
         $scope.timelogs = data.timelogs;
         $scope.role = data.role;
          $scope.timelogstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.timelogs.length,
                    counts: [10, 20, 50, 100,$scope.timelogs.length],
                    getData: function( params) {
                      params.total($scope.timelogs.length);
                    $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                }
                // $scope: $scope
            });
            // $scope.timelogs = data.timelogs;
            // console.log($scope.timelogs);
            $scope.role = data.role;
            $scope.timelog_users = data.timelog_users;

            $scope.uiConfig = {
                calendar:{
                height: 450,
                editable: true,
                header:{
                  left: 'month basicWeek basicDay agendaWeek agendaDay',
                  center: 'title',
                  right: 'today prev,next'
                },
                events: $scope.timelogs,
                // eventClick: $scope.alertEventOnClick,
                // eventDrop: $scope.alertOnDrop,
                // eventResize: $scope.alertOnResize
                }
              };
                 console.log( $scope.timelogs);
            }).error(function(data, status) {
                alert('something went wrong');
            });
        
        }

        else{
        $scope.project = data.project_user_cache;
        var projectId = data.project_user_cache.id;
           // console.log(projectId);    
            // $scope.budgets = function (projectId){

        TimelogService.getTimelogs(projectId).success(function(data, status) {         
         // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
         $scope.timelogs = data.timelogs;
          $scope.timelogstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.timelogs.length,
                    counts: [10, 20, 50, 100,$scope.timelogs.length],
                    getData: function( params) {
                      params.total($scope.timelogs.length);
                    $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            // $scope.timelogs = data.timelogs;
            // console.log($scope.timelogs);
            $scope.role = data.role;
            $scope.timelog_users = data.timelog_users;

            $scope.uiConfig = {
                calendar:{
                height: 450,
                editable: true,
                header:{
                  left: 'month basicWeek basicDay agendaWeek agendaDay',
                  center: 'title',
                  right: 'today prev,next'
                },
                events: $scope.timelogs,
                // eventClick: $scope.alertEventOnClick,
                // eventDrop: $scope.alertOnDrop,
                // eventResize: $scope.alertOnResize
                }
              };
                 console.log( $scope.timelogs);
            }).error(function(data, status) {
                alert('something went wrong');
            });
        // }   

    }
     }).error(function(data, status) {
        alert('something went wrong');
      });
      DepartmentService.updatedepcache(departmentId).success(function(data, status) {         
      }).error(function(data, status) {
          alert('something went wrong');
      }); 

    // }

        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        
        $scope.date2 = today;

        $('#selectdate').datepicker({
            uiLibrary: 'bootstrap',
            maxDate: function () {
              return today;
          },
           minDate: function () {
              return today;
          }
            // setValue: $scope.date2,
        
        });

         $rootScope.display = function (displayIsactive) {
        $scope.displayIsactive = displayIsactive;
         $scope.IsVisible = true;
         console.log($scope.IsVisible);
         }
      function toTime(timeString){
     var timeTokens = timeString.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
      }
    //   $scope.nextTimelogs = [];

    // $scope.addNextTimelog = function (newrow) {

    //   $scope.nextTimelogs.push({});

    //   $scope.timeDifference = 'Yes';

    //   // $scope.validate(newrow);

    // }

 

    // $scope.removeNextTimelog = function ($index) {

    //   $scope.nextTimelogs.splice($index, 1);

    //   // $scope.validate(0);

 

    // }
       $scope.toggleTask = function(modalstate,id,milestoneId,taskId) {
                      // $scope.callme ();

                      if (id == null){
                        notify({
                          message:"Please Select A Project First.",
                          template:'templates/error.html',
                          position:'center'
                        });
                      }else {
                      $scope.timelog = "";
                      $scope.resultAmount = 0;
                      $scope.itemused = [];
                      $scope.totalsum = 0;
                      $scope.bilableAmount = 0;
                       $scope.start_time = new Date();
                 $scope.breakstart_time = new Date();
                 $scope.end_time = new Date();
                 $scope.breakend_time = new Date();
                 // $scope.breaktime = "";
                 $scope.showMeridian = true;
    var canvas = document.querySelector("canvas");

var signaturePad = new SignaturePad(canvas);
  signaturePad.minWidth = 1;
         signaturePad.maxWidth = 1;
         signaturePad.penColor = "rgb(66, 133, 244)";

         var saveButton = document.getElementById('save');
       
         var cancelButton = document.getElementById('clear');
//           saveButton.addEventListener('click', function (event) {
//            var data = signaturePad.toDataURL();
//          console.log(data);
// // Send data to server instead...
//             window.open(data);
//           });
          
          saveButton.addEventListener("click", function (event) {
      if (signaturePad.isEmpty()) {
        alert("Please provide signature first.");
        } else {
        document.getElementById("hfSign").value = signaturePad.toDataURL();
            }
         });

          // protected void Save(object sender, EventArgs e)
          //      {
          //   string signature = hfSign.Value;
          // }
          cancelButton.addEventListener('click', function (event) {
          signaturePad.clear();
          });
// Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
       signaturePad.toDataURL(); // save image as PNG
       signaturePad.toDataURL("image/jpeg"); // save image as JPEG
       signaturePad.toDataURL("image/svg+xml"); // save image as SVG

// Draws signature image from data URL.
// NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

// Returns signature image as an array of point groups
const data = signaturePad.toData();

// Draws signature image from an array of point groups
signaturePad.fromData(data);

// Clears the canvas
signaturePad.clear();

// Returns true if canvas is empty, otherwise returns false
signaturePad.isEmpty();

// Unbinds all event handlers
signaturePad.off();

// Rebinds all event handlers
signaturePad.on();

      
//  var signaturePad = new SignaturePad(canvas, {
//     minWidth: 5,
//     maxWidth: 10,
//     penColor: "rgb(66, 133, 244)"
// });
//  var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
//   backgroundColor: 'rgba(255, 255, 255, 0)',
//   penColor: 'rgb(0, 0, 0)'
// });
// var saveButton = document.getElementById('save');
// var cancelButton = document.getElementById('clear');

// saveButton.addEventListener('click', function (event) {
//   var data = signaturePad.toDataURL('image/png');

// // Send data to server instead...
//   window.open(data);
// });

           // $scope.timelog = "";
           // $scope.start_time = "";
           // $scope.end_time = "";
           // $scope.hourbreak_time = "";
           //  $scope.minutesbreak_time = "";
           //  $scope.result = "";
            $scope.pricetotal = function() {
            var price = $scope.budget.price;
            var quantity = $scope.budget.quantity;

            $scope.total = price * quantity;
             // body...
           }

          //     $scope.$watch('start_time', function(newvalue) {
          // });
     //   var timeDifference = function () {

     // console.log( $scope.start_Time);
     // console.log($scope.end_Time);
     //          // $scope.start_time = $('#timeDisplay').val();
     //         // console.log($('#timepicker1').val())

     //       var start_Time = $scope.start_time;
     //       var end_Time = $scope.end_time;
     //        // var break_time = $scope.hourbreak_time;
     //       // var minutesbreak_time =  $scope.minutesbreak_time;
     //       // var rate_value =  $scope.rate.value;

          
           

     //       var endTime=moment(end_Time, "HH:mm:ss a");
     //       var startTime=moment(start_Time, "HH:mm:ss a");
          
          

     //       var duration = moment.duration(endTime.diff(startTime));

     //       var duration2 = moment(duration, "HH:mm:ss a");
     //      console.log(duration)
     //      console.log(duration2)


     //       var hours = parseInt(duration.asHours());
     //       var minutes = parseInt(duration.asMinutes())-hours*60;

     //       console.log(hours)
     //      console.log(minutes)

           // var hours1 = parseInt(duration2.asHours());
           // var minutes1 = parseInt(duration2.asMinutes())-hours1*60;
           // var hours1 = Number(hours - hourbreak_time)-1;
           // var minutes1 = Number(minutes - minutesbreak_time) + 60;

          //  console.log(hours1)
          // console.log(minutes1)
          // console.log(breakTime)
              
           // if (minutes>= 60) {
           //     var hr = hours+1;
           //     var minutes1 = 00;
           //     $scope.result = (hr+":"+ minutes);
           //     // $scope.resultAmount = (hr * rate_value) + ($scope.totalsum);

           // }
           // else {
           //     $scope.result = (hours+":"+ minutes);
           //     // $scope.resultAmount = (hours1 * rate_value) + ($scope.totalsum);

           // }
            //  if (hr < 0 || hours < 0) {
            // alert('Break time can not exceed Total spent hours'); 
            // return;   
            //   }else {
            // $scope.result = $scope.result;
            //   }
             
            // console.log(result);
           // }
           
        var projectId = $scope.projectId
         var milestoneId =milestoneId
        $scope.modalstate = modalstate;

 
    TaskService.getTasks(id).success(function(data, status) {   
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
         $scope.project_user = data.project_user;

         $scope.taskstimelog = data.taskstimelog;
        
        $scope.tasksArchived = data.tasksArchived;
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }

        //  console.log($scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

           BudgetService.getBudgets(id).success(function(data, status) {  
            $scope.budgets = data.budgets;
     
            }).error(function(data, status) {
                alert('something went wrong');
            });

    UnitService.getUnits().success(function(data, status) {   
        
        $scope.units = data.units;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

      ProjectService.toggleProject(id).success(function(data, status) {  
      $scope.project = data.project;
      
      // $scope.name = data.user.name;
      // $scope.email = data.user.email;
      // $scope.role_id = data.user.role_id;
      // $scope.company_id = data.user.company_id;
      // console.log(id);
      })

 $('#projectstartDate').datepicker({
        uiLibrary: 'bootstrap',
        });
   $('#projectendDate').datepicker({
        uiLibrary: 'bootstrap',
        });
          //  $scope.removeItem = function($index) {
          //   console.log($index);

          //    $scope.newItem.splice($index,1);
          //   var totalAm = $scope.total;
          //   $scope.totalsum =  $scope.totalsum - totalAm;

          //    }
          // $scope.newItem = [];
          // $scope.totalsum = 0;
          //  $scope.saveItem = function(budget,projectId,action) {
          //   var item = $scope.budget.item;
          //   var uname = $scope.budget.uname;
          //   var quantity = $scope.budget.quantity;
          //   var price = $scope.budget.price;
          //   var totalAm = $scope.total;
          //   $scope.totalsum = totalAm + $scope.totalsum;
          //   $scope.newItem.push({item,uname,quantity,price,totalAm});
          //       console.log($scope.newItem);
          //        // $scope.toggleTask();

          //       if (action == 'close') {
          //           notify({
          //            message:"Item Added successful.",
          //            template:'templates/success.html',
          //            position:'center'
          //             });
          //           // location.reload();
          //       } else {
          //             $scope.budget = "";
          //             notify({
          //            message:"Item Added successful.",
          //            template:'templates/success.html',
          //            position:'center'
          //             });
                      
          //             // $('#taskstartDate').val();
          //       } 
          //  }

    //       $scope.start_time = $('#input_starttime').val();
    //     $scope.$watch("start_time", function(newValue, oldValue) {
    // console.log("I've changed : ", $scope.start_time);
    //    });

      TaskService.toggleTask(id,milestoneId).success(function(data, status) {  
      $scope.project = data.project;
      $scope.company_users = data.company_users;
      $scope.milestone = data.milestone;
      $scope.project_user = data.project_user;
      $scope.task = data.task;
      $scope.rates = data.rates;
      $scope.user_id = data.userId;
      $scope.role = data.rate.rname;
      $scope.name = data.rate.name;
      // console.log(data.project_user );
      // console.log(data.project );
      // console.log(data.task );

      // $scope.user_rate = data.rate;
      })
           $scope.$watch('user_id', function(newvalue) {

             angular.forEach($scope.project_user,function(key,value){
            if(key.id == newvalue){
            console.log(newvalue);

              $scope.user_rate = key.value;

              }
        });

          });
        switch (modalstate) { 
            case 'addtimelog':
                $scope.form_title1 = "Add Time Log ";
                $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                $('#myModal7').modal('show');
                break;

              

              

                case 'addProjfromtime':
                $scope.form_title2 = "Add New Project";
                $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                 $('#myModal').modal('show');
                break;

               case 'addTaskfromtime':
                $scope.form_title3 = "Add New Task";
                $scope.task={project_id:id};
                 $scope.milestone= milestoneId;
                 $('#taskmyModal').modal('show');
                break;
                   case 'addItem':
                $scope.form_title4 = "Add Item Used";
                // $scope.projectId = id;
                 $('#itemmyModal').modal('show');
                break;
 
        }
         
    } 
  }

     ProjectService.getTypes().success(function(data, status) {   
        
        $scope.types = data.types;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
      DepartmentService.getDepartments().success(function(data, status) {   
        
        $scope.departments = data.departments;
        $scope.departmentId = data.departmentId;

        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      ProjectService.getPriorities().success(function(data, status) {   
        
        $scope.Priorities = data.Priorities;
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

         // $scope.timeDifference = 'Yes';
       
        $scope.toggleTaskEdit = function(modalstate,id,projectId) {
          //  $scope.timeDifference = function () {
          //   var start_Time = new moment($scope.start_time).format("HH:mm:ss a");
          //  var end_Time = new moment($scope.end_time).format("HH:mm:ss a");
          //   var breakstart_time = new moment($scope.breakstart_time).format("HH:mm:ss a");
          //  var breakend_time =  new moment($scope.breakend_time).format("HH:mm:ss a");
          //  var rate_id =  $scope.rate_id;

          // console.log($scope.rate_id)
          
          //  var startTime=moment(start_Time, "HH:mm:ss a");
          //  var endTime=moment(end_Time, "HH:mm:ss a");
          
          //  var duration = moment.duration(endTime.diff(startTime));
          //  var hours = parseInt(duration.asHours());
          //  var minutes = parseInt(duration.asMinutes())-hours*60;

          //  var hours1 = Number(hours - hourbreak_time)-1;
          //  var minutes1 = Number(minutes - minutesbreak_time) + 60;

          //  if (minutes1>= 60) {
          //      var hr = hours1+1;
          //      var minutes1 = 00;
          //   $scope.result = (hr+":"+ minutes1);
          //  }
          //  else {
          //    $scope.result = (hours1+":"+ minutes1);
          //  }
          //    if (hr < 0 || hours1 < 0) {
          //   alert('Break time can not exceed Total spent hours'); 
          //   return;   
          //     }else {
          //   $scope.result = $scope.result;
          //     }
          //   $scope.resultAmount = $scope.result * rate_id;
          // console.log($scope.resultAmount)
              
          // }
        $scope.modalstate = modalstate;
        
        UnitService.getUnits().success(function(data, status) {   
        
        $scope.units = data.units;
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });
      

      TaskService.toggleTimelogEdit(id,projectId).success(function(data, status) {  
      $scope.project = data.project;
      $scope.project_user = data.project_user;
      $scope.tasks = data.tasks;
      $scope.company_users = data.company_users;
       $scope.result = data.timelog.logged_hours;
      $scope.timelog = data.timelog;
      $scope.newItem = data.itemused;
       $scope.role = data.rate.rname;
      $scope.name = data.rate.name;
      $scope.user_id = data.userId;

       
         $scope.totalsum = 0;
        angular.forEach(data.itemused,function(key,value){
            // if(key.id == newvalue){

              $scope.totalsum = $scope.totalsum + key.total;

              // }
        });

          $scope.$watch('timelog.user_id', function(newvalue) {

             angular.forEach($scope.project_user,function(key,value){
            if(key.id == newvalue){
            console.log(newvalue);

              $scope.user_rate = key.value;

              }
        });

          });
    // $scope.resultAmount = $scope.timelog.gross_amount - $scope.totalsum;
     // console.log();

      $scope.start_time = toTime(data.timelog.start_time);
      $scope.end_time = toTime(data.timelog.end_time);
      $scope.date = data.timelog.date;
      $scope.breakstart_time = toTime(data.timelog.breakstart_time);
      $scope.breakend_time = toTime(data.timelog.breakend_time);

     if (data.timelog.bilable == 1)
      {
       $scope.timelog.bilable = true;
       
      }
         else
        {
      $scope.timelog.bilable = false;
          
       }
       $('#editselectdate').datepicker({
            uiLibrary: 'bootstrap',
            setValue: data.timelog.date
        });
      })
      console.log(modalstate);

        switch (modalstate) {
                 case 'editTimelog':
                $scope.form_title = "Edit Timelog";
                // $scope.timelog={timelogId:id};
                $('#myModal8').modal('show');
                break;

                case 'reviewdenie':
                  $scope.form_title_denie = "Denie The Timelog";
                  // $scope.task={project_id:id};
                  //  $scope.milestone= milestoneId;
                  $('#myModaldenie').modal('show');
                  break;

                  case 'reviewaccept':
                    $scope.form_title_accept = "Accept The Timelog";
                    // $scope.task={project_id:id};
                    //  $scope.milestone= milestoneId;
                    $('#myModalaccept').modal('show');
                    break;

                  case 'review':
                    $scope.form_title_review = " Timelog Review From Admin";
                    // $scope.task={project_id:id};
                    //  $scope.milestone= milestoneId;
                    $('#myModalreview').modal('show');
                    break;
                
 
        }
         
    }
    $scope.resultAmount = 0;
    // $scope.$watch($(this).is(":checked"), function(newvalue) {
    //   $scope.resultAmount = $scope.timedecimal * $scope.user_rate.value;
    //       });
    //    $(function () {
    //     $("#chkbillable").click(function () {
    //         if ($(this).is(":checked")) {
    //        $scope.resultAmount = $scope.timedecimal * $scope.user_rate.value;  

    //           // console.log($scope.timedecimal);
    //           // console.log($scope.user_rate.value);

    //           // console.log($scope.user_id);
    //             // $("#dvbillable").show();
    //             // $("#AddPassport").hide();
    //         } else {
    //           // return $scope.result;
    //           $scope.resultAmount = 0;
    //           // console.log($scope.result);

    //             // $("#dvbillable").hide();
    //             // $("#AddPassport").show();
    //         }
    //     });
    // });
       $scope.isbillable = function (checked) {
        if (checked == true) {
          if ( $scope.timedecimal != 0) {
          $scope.resultAmount = $scope.timedecimal * $scope.user_rate;  

        } else{
              $scope.resultAmount = 0;

        }
        }else if (checked == false) {
              $scope.resultAmount = 0;

        }
       }

    // $(function () {
    //     $("#chkitem").click(function () {
    //         if ($(this).is(":checked")) {
    //             $("#dvitem").show();
    //             // $("#AddPassport").hide();
    //         } else {
    //             $("#dvitem").hide();
    //             // $("#AddPassport").show();
    //         }
    //     });
    // });


    $scope.addItem = function (newrow) {
      $scope.newItem.push({});
      $scope.newItem_validated = 'No';
      $scope.validate(newrow);

           }
        $scope.removeItem = function($index) {
             $scope.newItem.splice($index,1)
             $scope.validate(0);
            // var totalAm = $scope.total;
            // $scope.totalsum =  $scope.totalsum - totalAm;

             }
          $scope.newItem = [];
          $scope.rowdata = [];
     $scope.validate = function ($index) {
        console.log($index);

      $scope.valid = 'No';

      $scope.totalsum = $scope.total1;
 
      if ($scope.newItem[$index]) {
      $scope.total1 = 0;
      $scope.newItem[$index].total = $scope.newItem[$index].quantity * $scope.newItem[$index].price;
        if ($scope.newItem[$index].total) {

          for (var i = 0; i < $scope.newItem.length; i++) {

            $scope.total1 += Number($scope.newItem[i].total || 0);

            $scope.totalsum =  $scope.total1;

          }

        }

 

        if ($scope.newItem[$index].price &&

          $scope.newItem[$index].quantity &&
          $scope.newItem[$index].item &&
          $scope.newItem[$index].uname &&
          $scope.newItem[$index].total &&

          $scope.totalsum == 0) {

          $scope.newItem_validated = 'Yes';

        }
         // $scope.newItem[$index].push({});

      }
     if ($index > 0){
         $scope.rowdata.push($scope.newItem[$index-1]);

      }
      
        // console.log($scope.rowdata);

    }

    //    $rootScope.save = function(project,id, action) {

    //     var startDate = $('#projectstartDate').val();
    //     var endDate = $('#projectendDate').val();

    //   var pname = $scope.project.pname;
    //    var short_name = $scope.project.short_name;
    //    var description = $scope.project.description;
    //    var project_type = $scope.project.project_type;
    //     // console.log(description);
    //    if (project==null || pname==null || short_name==null || description==null || project_type==null) {
    //      notify({
    //           message:"Please Fill All The Fields Correct.",
    //           template:'templates/error.html',
    //           position:'center'
    //           });
    //    } else {
    //   ProjectService.save(project,id,startDate,endDate,action).success(function(data, status) {
    //   $scope.project = data.project;
    //   ProjectService.toggleProject($scope.project.id).success(function(data, status) {  
    //   $scope.project = data.project;
      
    //   // $scope.name = data.user.name;
    //   // $scope.email = data.user.email;
    //   // $scope.role_id = data.user.role_id;
    //   // $scope.company_id = data.user.company_id;
    //   // console.log(id);
    //   })
    //   TaskService.getTasks($scope.project.id).success(function(data, status) {   
    //      // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
    //     $scope.tasks = data.tasks;
    //     $scope.project_user = data.project_user;

    //     $scope.taskstimelog = data.taskstimelog;
    //     $scope.tasksArchived = data.tasksArchived;
    //      if (data.tasks.favourite == 1)
    //   {
    //    $scope.checked = true;
       
    //   }
    //      else
    //     {
    //   $scope.checked = false;
          
    //    }

    //      console.log($scope.tasks);
    //   }).error(function(data, status) {
    //     alert('something went wrong');
    //   });
    //   var departmentId = $scope.departmentId;

    //     ProjectService.getProjects(departmentId).success(function(data, status) {    
    //     $scope.projects = data.projects;
    //     $scope.project_users = data.project_users;
    //     $scope.project_usseradded = data.project_usseradded;
    //     $scope.role = data.role;
    //     $scope.company_projects = data.company_projects;

  
    //     if($scope.project){

    //     // $scope.project = $scope.project;
    //     var projectId =  $scope.project.id;

    //   TimelogService.getTimelogs(projectId).success(function(data, status) {         
    //      // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
    //      $scope.timelogs = data.timelogs;
    //       $scope.timelogstable = new NgTableParams({
    //                 page: 1,
    //                 count: 10,
    //                 // sorting: { effective_dt: "asc" },
    //             },
    //             {
    //                 total: $scope.timelogs.length,
    //                 counts: [10, 20, 50, 100,$scope.timelogs.length],
    //                 getData: function( params) {
    //                   params.total($scope.timelogs.length);
    //                 $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
    //                 return $scope.data;
   
    //                 }
    //             // $scope: $scope
    //         });
    //         // $scope.timelogs = data.timelogs;
    //         // console.log($scope.timelogs);
    //         $scope.role = data.role;
    //         $scope.timelog_users = data.timelog_users;

    //         $scope.uiConfig = {
    //             calendar:{
    //             height: 450,
    //             editable: true,
    //             header:{
    //               left: 'month basicWeek basicDay agendaWeek agendaDay',
    //               center: 'title',
    //               right: 'today prev,next'
    //             },
    //             events: $scope.timelogs,
    //             // eventClick: $scope.alertEventOnClick,
    //             // eventDrop: $scope.alertOnDrop,
    //             // eventResize: $scope.alertOnResize
    //             }
    //           };
    //              console.log( $scope.timelogs);
    //         }).error(function(data, status) {
    //             alert('something went wrong');
    //         });
        
    //     }

    //     else{
    //     $scope.project = data.project_user_cache;
    //     var projectId = data.project_user_cache.id;
    //        console.log(projectId);    
    //         // $scope.budgets = function (projectId){

    //     TimelogService.getTimelogs(projectId).success(function(data, status) {         
    //      // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
    //      $scope.timelogs = data.timelogs;
    //       $scope.timelogstable = new NgTableParams({
    //                 page: 1,
    //                 count: 10,
    //                 // sorting: { effective_dt: "asc" },
    //             },
    //             {
    //                    total: $scope.timelogs.length,
    //                 counts: [10, 20, 50, 100,$scope.timelogs.length],
    //                 getData: function( params) {
    //                   params.total($scope.timelogs.length);
    //                 $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
    //                 return $scope.data;
   
    //                 }
    //             // $scope: $scope
    //         });
    //         // $scope.timelogs = data.timelogs;
    //         // console.log($scope.timelogs);
    //         $scope.role = data.role;
    //         $scope.timelog_users = data.timelog_users;

    //         $scope.uiConfig = {
    //             calendar:{
    //             height: 450,
    //             editable: true,
    //             header:{
    //               left: 'month basicWeek basicDay agendaWeek agendaDay',
    //               center: 'title',
    //               right: 'today prev,next'
    //             },
    //             events: $scope.timelogs,
    //             // eventClick: $scope.alertEventOnClick,
    //             // eventDrop: $scope.alertOnDrop,
    //             // eventResize: $scope.alertOnResize
    //             }
    //           };
    //              console.log( $scope.timelogs);
    //         }).error(function(data, status) {
    //             alert('something went wrong');
    //         });
    //     // }   

    // }
    //  }).error(function(data, status) {
    //     alert('something went wrong');
    //   });
    //   // $scope.name = data.user.name;
    //   // $scope.email = data.user.email;
    //   // $scope.role_id = data.user.role_id;
    //   // $scope.company_id = data.user.company_id;
    //   // console.log(id);
    //          if (action == 'close') {
    //             notify({
    //                  message:"Project Added successful.",
    //                  template:'templates/success.html',
    //                  position:'center'
    //                   });
    //                 location.reload();
    //             } else {
    //         // console.log("nimefika");
    //                   $scope.project = ""; 
    //                     notify({
    //                  message:"Project Added successful.",
    //                  template:'templates/success.html',
    //                  position:'center'
    //                   });     
    //             }
    //               if (action == 'close') {
    //                 notify({
    //                  message:"Project Added successful.",
    //                  template:'templates/success.html',
    //                  position:'center'
    //                   });
    //                 location.reload();
    //             } else {
    //                   $scope.project = "";
    //                   notify({
    //                  message:"Project Added successful.",
    //                  template:'templates/success.html',
    //                  position:'center'
    //                   });
                      
    //                   // $('#taskstartDate').val();
    //             } 
    //   }).error(function(data, status) {
    //     alert('something went wrong');
    //   });
    // }
    
    // }

      $scope.onFileSelect = function($files){
    // console.log($files);
   //$scope.file = $files;
   $scope.datatasks={};
     //$scope.file = {};
        for (var i = $files.length - 1; i >= 0; i--){
             $scope.datatasks['file'+i]=$files[i];
        }
    $scope.datatasks['filecount']=$files.length;
    console.log($scope.datatasks);
   }

 $scope.saveTask = function(task,id,projectId,companyId,action) {
  console.log(id);
    


    if ($scope.datatasks) {
       var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

        var endDate = $('#taskendDate').val();
    $scope.datatasks['startDate']=startDate;
    $scope.datatasks['endDate']=endDate;
    $scope.datatasks['task']=task;
    $scope.datatasks['id']=id;
    $scope.datatasks['projectId']=projectId;
    $scope.datatasks['companyId']=companyId;
    console.log($scope.datatasks);

           $scope.upload = $upload.upload({
                url: 'storeFromTime',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.datatasks,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });

                   
                      
     TaskService.getTasks(projectId).success(function(data, status) {   
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
         $scope.project_user = data.project_user;

         $scope.taskstimelog = data.taskstimelog;
        $scope.tasksArchived = data.tasksArchived;
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }

         console.log($scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      });
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
               var startDate = $('#taskstartDate').val();
  // console.log($('#taskstartDate').val());

               var endDate = $('#taskendDate').val();

             var title = $scope.task.title;
             var description = $scope.task.description;
            //  var assign_to = $scope.task.assign_to;
             var type_id = $scope.task.type_id;
             var priority = $scope.task.priority;
       console.log(task);
                 if (task==null || title==null || description==null  || type_id==null || priority==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
               TaskService.storeFromTime(task,id,projectId,companyId,startDate,endDate,action).success(function(response) { 
                  if (action == 'close') {
                    notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.task = "";
                      notify({
                     message:"Task Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });


        //   $scope.$watch('timelog.user_id', function(newvalue) {


        //  });
                      
     TaskService.getTasks(projectId).success(function(data, status) {   
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
         $scope.project_user = data.project_user;

         $scope.taskstimelog = data.taskstimelog;
        $scope.tasksArchived = data.tasksArchived;
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }

         console.log($scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      });
                      // $('#taskstartDate').val();
                } 
         
            }).error(function(response) {
                   notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            });
          }
          } 
            // location.reload();  
  // console.log($scope.data);
        };
      $scope.projectTask  = function (month,projectId) { 
        if (month.startDate != null) 
        {
          var date = month;
          var startDate = date.startDate.format('YYYY-MM-DD');
          var endDate = date.endDate.format('YYYY-MM-DD');
        }
        else
        {
          var startDate = $rootScope.firstd;
          var endDate = $rootScope.lastd;
        }
      TaskService.updateprojectcache(projectId).success(function(data, status) {         
            }).error(function(data, status) {
                alert('something went wrong');
            });  

     TaskService.getTasks(projectId).success(function(data, status) {   
         // $scope.tasks = new NgTableParams({}, { dataset:data.tasks })
        $scope.tasks = data.tasks;
      $scope.project_user = data.project_user;

        $scope.taskstimelog = data.taskstimelog;

        $scope.tasksArchived = data.tasksArchived;
             if (data.tasks.favourite == 1)
      {
       $scope.checked = true;
       
      }
         else
        {
      $scope.checked = false;
          
       }

        //  console.log($scope.tasks);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 


        TimelogService.getTimelogs(startDate,endDate,projectId).success(function(data, status) {         
         // $scope.timelogs = new NgTableParams({}, { dataset:data.timelogs })
         $scope.timelogs = data.timelogs;
          $scope.timelogstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.timelogs.length,
                    counts: [10, 20, 50, 100,$scope.timelogs.length],
                    getData: function( params) {
                      params.total($scope.timelogs.length);
                    $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
            // $scope.timelogs = data.timelogs;
            // console.log($scope.timelogs);
            $scope.role = data.role;
            $scope.timelog_users = data.timelog_users;

            $scope.uiConfig = {
                calendar:{
                height: 450,
                editable: true,
                header:{
                  left: 'month basicWeek basicDay agendaWeek agendaDay',
                  center: 'title',
                  right: 'today prev,next'
                },
                events: $scope.timelogs,
                // eventClick: $scope.alertEventOnClick,
                // eventDrop: $scope.alertOnDrop,
                // eventResize: $scope.alertOnResize
                }
              };
                //  console.log( $scope.timelogs);
            }).error(function(data, status) {
                alert('something went wrong');
            });
        }
          $scope.CreateHeader = function(pname) {
          showHeader = (pname!=$scope.currentprojectname); 
          $scope.currentprojectname = pname;
          return showHeader;
          }

            $scope.downloadExcelTimelog = function(projectId) {
         console.log(projectId);
            TimelogService.downloadExcelTimelog(projectId).success(function(data, status) {
                var url = 'index.php/downloadExcelTimelog/xls/?data=' +"&projectId="+projectId;
                $window.open(url);
            });
        };
            
       $rootScope.usertimelog = function(month,projectId,userId) { 
        // console.log(projectId);
        if (month.startDate != null) 
        {
          var date = month;
          var startDate = date.startDate.format('YYYY-MM-DD');
          var endDate = date.endDate.format('YYYY-MM-DD');
        }
        else
        {
          var startDate = $rootScope.firstd;
          var endDate = $rootScope.lastd;
        }
      TimelogService.usertimelog(startDate,endDate,projectId,userId).success(function(data, status){  
          $scope.CreateHeader = function(pname) {
          showHeader = (pname!=$scope.currentprojectname); 
          $scope.currentprojectname = pname;
          return showHeader;
          }
          $scope.timelogs = data.timelogs;
           $scope.timelogstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.timelogs.length,
                    counts: [10, 20, 50, 100,$scope.timelogs.length],
                    getData: function( params) {
                      params.total($scope.timelogs.length);
                    $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
             $scope.uiConfig = {
                calendar:{
                height: 450,
                editable: true,
                header:{
                  left: 'month basicWeek basicDay agendaWeek agendaDay',
                  center: 'title',
                  right: 'today prev,next'
                },
                events: $scope.timelogs,
                // eventClick: $scope.alertEventOnClick,
                // eventDrop: $scope.alertOnDrop,
                // eventResize: $scope.alertOnResize
                }
              };
      // console.log($scope.timelogs);
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    }

    $rootScope.acceptTimelog = function(month,timelog,timelogId,projectId,statusId) {
      // var modalOptions = {
      //   closeButtonText: 'Cancel',
      //   actionButtonText: 'Accept Timelog',
      //   headerText: 'Accept Timelog',
      //   bodyText: 'Are you sure?',
      // };
      // modalService.showModal({}, modalOptions).then(function (result) {
        if (month.startDate != null) 
        {
          var date = month;
          var startDate = date.startDate.format('YYYY-MM-DD');
          var endDate = date.endDate.format('YYYY-MM-DD');
        }
        else
        {
          var startDate = $rootScope.firstd;
          var endDate = $rootScope.lastd;
        }
          TimelogService.acceptTimelog(startDate,endDate,timelog,timelogId,projectId,statusId).success(function(data, status) {
          // $scope.timelogs =data.timelogs;
               $scope.timelogs = data.timelogs;
            $scope.timelogstable = new NgTableParams({
                      page: 1,
                      count: 10,
                      // sorting: { effective_dt: "asc" },
                  },
                  {
                         total: $scope.timelogs.length,
                      counts: [10, 20, 50, 100,$scope.timelogs.length],
                      getData: function( params) {
                        params.total($scope.timelogs.length);
                      $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                      return $scope.data;
     
                      }
                  // $scope: $scope
              });
          // console.log(projectId);  
        }).error(function(data, status) {
          alert('something went wrong');
        }); 
      // });
    
  
      } 

      $rootScope.denieTimelog = function(month,timelog,timelogId,projectId) {
        // var modalOptions = {
        //   closeButtonText: 'Cancel',
        //   actionButtonText: 'Accept Timelog',
        //   headerText: 'Accept Timelog',
        //   bodyText: 'Are you sure you Accept this timelog ?',
        // };
        // modalService.showModal({}, modalOptions).then(function (result) {
          if (month.startDate != null) 
          {
            var date = month;
            var startDate = date.startDate.format('YYYY-MM-DD');
            var endDate = date.endDate.format('YYYY-MM-DD');
          }
          else
          {
            var startDate = $rootScope.firstd;
            var endDate = $rootScope.lastd;
          }
            TimelogService.denieTimelog(startDate,endDate,timelog,timelogId,projectId).success(function(data, status) {
            // $scope.timelogs =data.timelogs;
                 $scope.timelogs = data.timelogs;
              $scope.timelogstable = new NgTableParams({
                        page: 1,
                        count: 10,
                        // sorting: { effective_dt: "asc" },
                    },
                    {
                           total: $scope.timelogs.length,
                        counts: [10, 20, 50, 100,$scope.timelogs.length],
                        getData: function( params) {
                          params.total($scope.timelogs.length);
                        $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                        return $scope.data;
       
                        }
                    // $scope: $scope
                });
            // console.log(projectId);  
          }).error(function(data, status) {
            alert('something went wrong');
          }); 
        // });
      
    
        } 

    

      $rootScope.sendTimelog = function(month,timelogId,projectId,statusId) {
    var modalOptions = {
      closeButtonText: 'Cancel',
      actionButtonText: 'Submit Timelog',
      headerText: 'Submit Timelog',
      bodyText: 'Are you sure you want to Submit this timelog ?',
    };

    if (month.startDate != null) 
    {
      var date = month;
      var startDate = date.startDate.format('YYYY-MM-DD');
      var endDate = date.endDate.format('YYYY-MM-DD');
    }
    else
    {
      var startDate = $rootScope.firstd;
      var endDate = $rootScope.lastd;
    }
    modalService.showModal({}, modalOptions).then(function (result) {
        TimelogService.sendTimelog(startDate,endDate,timelogId,projectId,statusId).success(function(data, status) {
        // $scope.timelogs =data.timelogs;
             $scope.timelogs = data.timelogs;
          $scope.timelogstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.timelogs.length,
                    counts: [10, 20, 50, 100,$scope.timelogs.length],
                    getData: function( params) {
                      params.total($scope.timelogs.length);
                    $scope.data = $scope.timelogs.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        // console.log(projectId);  
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
    });
  

    } 

        $rootScope.updateTimelog = function(itemused,timelog,user_id,projectId) {
     var selectdate = $('#editselectdate').val();
        var logged_hours = $scope.result;
        var bilableAmount = $scope.resultAmount;
       //  var hourbreak_time = $scope.hourbreak_time;
       //  var minutesbreak_time =  $scope.minutesbreak_time;
       //  // var break_time = (hourbreak_time+":"+ minutesbreak_time);
        var start_timelog = new moment($scope.start_time).format("HH:mm:ss");
        var breakstart_timelog = new moment($scope.breakstart_time).format("HH:mm:ss");
        var end_timelog = new moment($scope.end_time).format("HH:mm:ss");
        var breakend_timelog = new moment($scope.breakend_time).format("HH:mm:ss");

       var task_id = $scope.timelog.task_id;
       var user_id = $scope.user_id;
       var note = $scope.timelog.note;
       var progress = $scope.timelog.progress;
       var client = $scope.timelog.client;
       var isitemused = $scope.timelog.isitemused;
          if (client == 'no') {
       // console.log(itemused);

        $scope.timelog.adress = "";
      }

      if (isitemused == 'no') {
       // console.log(itemused);

        itemused = [];
        $scope.totalsum = 0;
      }
       // console.log(itemused);
       if ( task_id==null || note==null || progress==null|| client==null|| isitemused==null || selectdate==null || start_timelog==null || end_timelog==null ) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
       TimelogService.updateTimelog(itemused,timelog,user_id,projectId,selectdate,$scope.totalsum,logged_hours,bilableAmount,start_timelog,end_timelog,breakstart_timelog,breakend_timelog).success(function(response) {
            location.reload();
         
              notify({
                     message:"Timelog Updated successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    // location.reload();
                
                      // $scope.timelog = "";
             
      }).error(function(response) {
             notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
      }); 
    }
 
    }
      // var form = $('#sign_form');
      //    $(save).click(function() {
      //       $.ajax({
      //           url: form.attr( 'action' ),
      //           data: form.serialize(),
      //           type: 'POST',
      //           success: function(response, ui) {
      //               swal({
      //                 title: "Signature Saved",
      //                 text: "Your signature has now been stored.",
      //                 icon: "success",
      //               });
      //               window.setTimeout(function(){window.location.reload()}, 3000);
      //           },
      //           error: function(response) {
      //               console.log('Error!');
      //           }
      //       });
      //   });

       $rootScope.saveTimelog = function(itemused,timelog,user_id,projectId,taskId,action) {
        // console.log($scope.start_time);
        // console.log(user_id);
        // console.log(projectId);
        // console.log(itemused);
        // console.log($scope.resultAmount);
        if ($scope.result == "0:0"){
          notify({
            message:"Time used cannot be zero duration, Please select start time and end time.",
            template:'templates/error.html',
            position:'center'
          });
        }else {
            var logged_hours = $scope.result;
            var selectdate = $('#selectdate').val();
            var bilableAmount = $scope.resultAmount;
           //  var hourbreak_time = $scope.hourbreak_time;
           //  var minutesbreak_time =  $scope.minutesbreak_time;
           //  // var break_time = (hourbreak_time+":"+ minutesbreak_time);
           var breakstart_timelog = new moment($scope.breakstart_time).format("HH:mm:ss");
           var breakend_timelog = new moment($scope.breakend_time).format("HH:mm:ss");

           if(breakstart_timelog == breakend_timelog){
            breakstart_timelog = 0;
            breakend_timelog =0;
           }else{
            var breakstart_timelog = new moment($scope.breakstart_time).format("HH:mm:ss");
            var breakend_timelog = new moment($scope.breakend_time).format("HH:mm:ss");
           }
            var start_timelog = new moment($scope.start_time).format("HH:mm:ss");
            var end_timelog = new moment($scope.end_time).format("HH:mm:ss");
    
           var task_id = $scope.timelog.task_id;
           var user_id = $scope.user_id;
           var note = $scope.timelog.note;
           var progress = $scope.timelog.progress;
           var client = $scope.timelog.client;
           var isitemused = $scope.timelog.isitemused;
              if (client == 'no') {
           // console.log(itemused);
    
            $scope.timelog.adress = "";
          }
    
          if (isitemused == 'no') {
           // console.log(itemused);
    
            itemused = [];
            $scope.totalsum = 0;
          }
           // console.log(itemused);
           if ( task_id==null || note==null || progress==null|| client==null|| isitemused==null || selectdate==null || start_timelog==null || end_timelog==null ) {
             notify({
                  message:"Please Fill All The Fields Correct.",
                  template:'templates/error.html',
                  position:'center'
                });
           } else {
           TimelogService.saveTimelog(itemused,timelog,user_id,projectId,taskId,selectdate,$scope.totalsum,logged_hours,bilableAmount,start_timelog,end_timelog,breakstart_timelog,breakend_timelog).success(function(response) {
                // location.reload();
                if (action == 'close') {
                  notify({
                         message:"Timelog Added successful.",
                         template:'templates/success.html',
                         position:'center'
                          });
                        location.reload();
                    } else {
                            $scope.timelog = "";
                          $scope.resultAmount = 0;
                          $scope.itemused = [];
                          $scope.totalsum = 0;
                          $scope.bilableAmount = 0;
                           $scope.start_time = new Date();
                     $scope.breakstart_time = new Date();
                     $scope.end_time = new Date();
                     $scope.breakend_time = new Date();
                     // $scope.breaktime = "";
                     $scope.showMeridian = true;
                          notify({
                         message:"Timelog Added successful.",
                         template:'templates/success.html',
                         position:'center'
                          });
                    }
          }).error(function(response) {
                 notify({
                  message:"Please Fill All The Fields Correct.",
                  template:'templates/error.html',
                  position:'center'
                });
          }); 
        }
          }
   
  }

    function toTime(timeString){
    var timeTokens = timeString.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
}

 $scope.printtime = function(projectId,timelogId) {
   var url = 'index.php/printtime/?data='+"&projectId="+projectId+"&timelogId="+timelogId;
                $window.open(url);
            // TimelogService.printtime(projectId,timelogId).success(function(data, status) {
            //     var url = 'index.php/printtime/?data='+"&projectId="+projectId+"&timelogId="+timelogId;
            //     $window.open(url);
            // });
        };

  $scope.deleteItem = function(id,modelname){
  // console.log(id);
    globalService.deleteItem(id,modelname)
  }

	}); 

  
