main.service('BudgetService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Budget = this;

    Budget.updateBudget = function(budget,projectId,edittotal) {
    return $http({
      method: 'POST',
      url : 'index.php/updateBudget/',
      params: {
        'budget':budget,
        'projectId':projectId,
        'edittotal':edittotal,
      }
    });
  };
       Budget.getBudgets = function(projectId) {
    return $http({
      url : 'index.php/getBudgets/',
      params: {
        'projectId':projectId,
      }
    });
  };
    Budget.downloadjobExcel = function(jobcostings,projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/downloadjobExcel/xls',
      params: {
        'jobcostings':jobcostings,
        'projectId':projectId,
      }
    });
  }; 
       Budget.getJobcostings = function(projectId) {
    return $http({
      url : 'index.php/getJobcostings/',
      params: {
        'projectId':projectId,
      }
    });
  };
    Budget.getProjects = function() {
    return $http({
      url : 'index.php/getProjects/',
    });
  }; 
  
      Budget.toggleBudgetEdit = function(id,projectId) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleTaskEdit/',
      params: {
        'id':id,
        'projectId':projectId,
      }
    });
  }; 

         Budget.saveItem = function(additem,projectId,jobcostingId,totalsum,effectiveDate) {
     // console.log(projectId); 
      return $http({
      method: 'POST',
      url : 'index.php/saveItem/',
      params: {
        'additem[]':additem,
        'projectId':projectId,
        'jobcostingId':jobcostingId,
        'totalsum':totalsum,
        'effectiveDate':effectiveDate,
       }
    });
  };
    Budget.saveDL = function(list,casuallabour,totalsum,projectId,jobcostingId,effectiveDate) {
    // console.log(projectId);

      return $http({
        method: 'POST',
        url: 'index.php/saveDL',
      params: {
          'list[]' : list,
          'casuallabour[]' : casuallabour,
          // 'Totalperday' : Totalperday,
          'totalsum' : totalsum,
          'projectId' : projectId,
          'jobcostingId' : jobcostingId,
          'effectiveDate' : effectiveDate,
        }
      });
    },
        Budget.saveBudget = function(budget,projectId,total) {
     // console.log(projectId); 
      return $http({
      method: 'POST',
      url : 'index.php/saveBudget/',
      params: {
        'budget':budget,
        'projectId':projectId,
        'total':total,
       }
    });
  };
          Budget.saveJobcosting = function(startDate,endDate,projectId) {
     // console.log(projectId); 
      return $http({
      method: 'POST',
      url : 'index.php/saveJobcosting/',
      params: {
        'startDate':startDate,
        'endDate':endDate,
        'projectId':projectId,
       }
    });
  };

      Budget.editJobcosting = function(startDate,endDate,projectId,jobcostingId) {
     // console.log(projectId); 
      return $http({
      method: 'POST',
      url : 'index.php/editJobcosting/',
      params: {
        'startDate':startDate,
        'endDate':endDate,
        'projectId':projectId,
        'jobcostingId':jobcostingId,
       }
    });
  };

});
