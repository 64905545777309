main.service('CompanyService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Company = this;

  Company.getUsers = function() {
    return $http({
      url : 'index.php/getUsers/',
    });
  };
   Company.getRoles = function() {
    return $http({
      url : 'index.php/getRoles/',
    });
  };
    Company.saveCompany = function(company,startDate,endDate) {
    return $http({
       method: 'POST',
      url : 'index.php/companies/',
      params: {
        'company':company,
        'startDate':startDate,
        'endDate':endDate,
      }
    });
  };

    Company.activateDeactivate = function(companyId,actDeaId) {
    return $http({
        method: 'POST',
      url : 'index.php/activateDeactivateC/',
      params: {
        'companyId':companyId,
        'actDeaId':actDeaId,
      }
    });
  };

    Company.updateC = function(company,startDate,endDate) {
    return $http({
       method: 'POST',
      url : 'index.php/updateC/',
      params: {
        'company':company,
          'startDate':startDate,
        'endDate':endDate,
      }
    });
  };

  Company.getCompanies = function() {
    return $http({
      url : 'index.php/getCompanies/',
    });
  };

  Company.getProjects = function() {
    return $http({
      url : 'index.php/getProjects/',
    });
  };
     Company.toggleCompany = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleCompany/',
      params: {
        'id':id,
      }
    });
  };
  
  Company.getCompany = function(companyId) {
    return $http({
      method: 'GET',
      url : 'index.php/getCompany/',
      params: {
        'companyId':companyId,
      }
    });
  };
   Company.ChangeCompany = function(companyId) {
    return $http({
      method: 'GET',
      url : 'index.php/ChangeCompany/',
      params: {
        'companyId':companyId,
      }
    });
  };
   Company.myCompany = function(companyId) {
    return $http({
      method: 'GET',
      url : 'index.php/myCompany/',
      params: {
        'companyId':companyId,
      }
    });
  };
   Company.removelogo = function(logoId,id) {
    // console.log(fileId);
    return $http({
      method: 'GET',
      url : 'index.php/removelogo/',
      params: {
        'logoId':logoId,
        'id':id,
      }
    });
  };
  

});
