'use strict';
 main.controller('DashboardController',	function($rootScope,$stateParams,CompanyService,globalService, $scope,$http, actions, $modal, $log, notify, modalService) {
    

		$scope.companies = function () {
           
	CompanyService.getCompanies().success(function(data, status) {   
				
        $scope.companies = data.companies;
      // $scope.id = data.id;
      $rootScope.cname = data.cname;
      $rootScope.website = data.website;
      $rootScope.contact_phone = data.contact_phone;
      $rootScope.logo = data.logo;
        // $scope.cname = data.cname;
        // 
        // console.log(data.companies);  		
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}
     
     CompanyService.getRoles().success(function(data, status) {   
        
        $scope.roles = data.roles;
        // console.log(data.roles)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

      CompanyService.getCompanies().success(function(data, status) {    
        $scope.companies = data.companies;
        console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

        CompanyService.getUsers().success(function(data, status) {   
        $scope.users = data.users;
        $scope.role = data.role;
        $scope.company = data.company;
        $scope.email = data.email;
        $scope.name = data.name;
        console.log($scope.users);   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

	}); 

  