main.factory('actions', function($http) {
  ' use strict';
  return {

    deleteItem: function(id, modelname) {
      return $http({
        method: 'DELETE',
        url: 'index.php/deleteItem',
        params: {
          'id': id,
          'modelname': modelname
        }
      });
    },
        // specific store reque
  };
});
