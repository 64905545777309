main.service('DepartmentService', function (modalService, actions, notify, $modal, $http) {

    'use strict';
  
    var Department = this;
  
    Department.getDepartments = function() {
      return $http({
        url : 'index.php/getDepartments/',
      });
    };  

    Department.updatedepcache = function(departmentId) {
        return $http({
          url : 'index.php/updatedepcache/',
          params: {
            'departmentId':departmentId,
          }
        });
      };
  
    Department.toggleDep = function(id) {
      return $http({
        method: 'GET',
        url : 'index.php/toggleDep/',
        params: {
          'id':id,
        }
      });
    };
  
  });
  