'use strict'; 
main.service('globalService', function (modalService, actions, notify, $modal, $http, NgTableParams) {

  var rest = this;

 

  rest.deleteItem = function(id, modelname, Iterator) { 
    var modalOptions = {
      closeButtonText: 'Cancel',
      actionButtonText: 'Delete '+modelname+' ',
      headerText: 'Delete '+modelname+' ',
      bodyText: 'Are you sure you want to delete this '+modelname+' ?',
    };
    modalService.showModal({}, modalOptions).then(function (result) {
      actions.deleteItem(id, modelname).success(function(data, status, headers, config) {
        // Iterator.splice($index,0); 
        notify({
          message: data.message,
          template:'templates/success.html',
          position:'center'
        });
        // location.reload();
      }).error(function(data, status, headers, config) {
        notify({
          message: "Something went wrong on deleting " +modelname+". Please try Again!",
          template:'templates/error.html',
          position:'center'
        });
      }); 
    });
  };
    rest.intiateNgTable = function(modalService, $scope,success) {
         $scope.tasks = new NgTableParams({}, { dataset:[] })
        return new NgTableParams({
            page: 1,
            count: 10,
            // sorting: {yearc: "desc" },
        }, {
            total: 0,
            getData: function($defer, params) {
                modalService.success(function(data) {
                    // params.total($filter('filter')(data.data, $scope.searchedTerm).length);
                    // var filteredData = $filter('filter')(data.data, $scope.searchedTerm);
                    // var orderedData = params.sorting() ? $filter('orderBy')(filteredData, params.orderBy()) : filteredData;
                    // $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                }).error(function(data, status) {
                    notify({
                        message: data.message,
                        template: 'templates/error.html',
                        position: 'center'
                    });
                });
            },
            $scope: $scope
        });
    }

    
});

