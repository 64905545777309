'use strict';
 main.controller('UsersController',function($rootScope,UserService,DepartmentService,UnitService,$stateParams,globalService, $upload,actions, $scope,$http,$modal, $log, notify, modalService,$filter,NgTableParams) {
  
  $scope.setOrderProperty = function(propertyName) {
    if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = '-' + propertyName;
    } else if ($scope.orderProperty === '-' + propertyName) {
        $scope.orderProperty = propertyName;
    } else {
        $scope.orderProperty = propertyName;
    }
}
     $scope.userId=$stateParams.userId;

      UserService.getusernotifications().success(function(data, status) {   
        
        $scope.newtask = data.notifications.new_task;
        $scope.replycoments = data.notifications.reply_task;
        $scope.statuschange = data.notifications.task_status;
        $scope.taskreport = data.notifications.task_report;
        $scope.taskdue = data.notifications.task_due;
        $scope.dailyreport = data.notifications.weekly_usage_alert;
        // console.log(data.roles)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      });       

      DepartmentService.getDepartments().success(function(data, status) {   
        
        $scope.departments = data.departments;
        $scope.departmentId = data.departmentId;
        UserService.getUsers($scope.departmentId).success(function(data, status) {   
          $scope.users = data.users;  
          $scope.role = data.role;  
          // console.log($scope.SuperAdmins); 
                  $scope.userstable = new NgTableParams({
                      page: 1,
                      count: 10,
                      // sorting: { effective_dt: "asc" },
                  },
                  {
                      total: $scope.users.length,
                      counts: [10, 20, 50, 100,$scope.users.length],
                      getData: function( params) {
                        params.total($scope.users.length);
                      $scope.data = $scope.users.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                      return $scope.data;
     
                      }
                  // $scope: $scope
              });
  
                  $scope.SuperAdmins = data.SuperAdmins; 
                     // console.log($scope.SuperAdmins); 
                  $scope.SuperAdminstable = new NgTableParams({
                      page: 1,
                      count: 10,
                      // sorting: { effective_dt: "asc" },
                     },
                     {
                      total: $scope.SuperAdmins.length,
                      counts: [10, 20, 50, 100,$scope.SuperAdmins.length],
                      getData: function( params) {
                        params.total($scope.SuperAdmins.length);
                      $scope.data = $scope.SuperAdmins.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                      return $scope.data;
     
                      }
                  // $scope: $scope
              });
        }).error(function(data, status) {
          alert('something went wrong');
        });
        // console.log(data.companies)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      var departmentId = $scope.departmentId;
		// $scope.users = function (departmentId) {
           
			UserService.getUsers(departmentId).success(function(data, status) {   
        $scope.users = data.users;  
        $scope.role = data.role;  
        // console.log($scope.SuperAdmins); 
                $scope.userstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.users.length,
                    counts: [10, 20, 50, 100,$scope.users.length],
                    getData: function( params) {
                      params.total($scope.users.length);
                    $scope.data = $scope.users.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

                $scope.SuperAdmins = data.SuperAdmins; 
                   // console.log($scope.SuperAdmins); 
                $scope.SuperAdminstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                   },
                   {
                    total: $scope.SuperAdmins.length,
                    counts: [10, 20, 50, 100,$scope.SuperAdmins.length],
                    getData: function( params) {
                      params.total($scope.SuperAdmins.length);
                    $scope.data = $scope.SuperAdmins.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
    // }
    
    $scope.projectDep = function (departmentId) { 
      UserService.getUsers(departmentId).success(function(data, status) {   
        $scope.users = data.users;  
        $scope.role = data.role;  
        // console.log($scope.SuperAdmins); 
                $scope.userstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.users.length,
                    counts: [10, 20, 50, 100,$scope.users.length],
                    getData: function( params) {
                      params.total($scope.users.length);
                    $scope.data = $scope.users.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

                $scope.SuperAdmins = data.SuperAdmins; 
                   // console.log($scope.SuperAdmins); 
                $scope.SuperAdminstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                   },
                   {
                    total: $scope.SuperAdmins.length,
                    counts: [10, 20, 50, 100,$scope.SuperAdmins.length],
                    getData: function( params) {
                      params.total($scope.SuperAdmins.length);
                    $scope.data = $scope.SuperAdmins.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
			}).error(function(data, status) {
				alert('something went wrong');
      }); 
      DepartmentService.updatedepcache(departmentId).success(function(data, status) {         
      }).error(function(data, status) {
          alert('something went wrong');
      }); 
    }

         var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('#birthDate').datepicker({
            uiLibrary: 'bootstrap',
        
        });
   // $('#editbirthDate').datepicker({
   //          uiLibrary: 'bootstrap',
        
   //      })

          $scope.checkCurrentPassword = function(current_password){
            UserService.checkIfPasswordCorrect(current_password).success(function(data,status){
              //success
              $scope.password_correct = data.password_correct;
            }).error(function(data){
              //error
              alert('something went wrong when checking current password');
            })
          }
          $scope.matchPassword = function(formdata){
           if (formdata.password == formdata.repeat_password) {
            return $scope.passwordMatch = 1;
          }else{
            return $scope.passwordMatch = 0;
          }
          if (formdata.password == formdata.current_password ) {
            return $scope.passwordMatch = 0;
          };
        }

          $scope.postChangePassword = function(formdata) {
            var current_password = $scope.formdata.current_password;
            var password = $scope.formdata.password;
            var repeat_password = $scope.formdata.repeat_password;
            if ( current_password==null || password==null || repeat_password==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       } else {
          UserService.postChangePassword(formdata).success(function(data,status){
            //successfully
            
            notify({
              message: data.message ,
              template:'templates/success.html',
              position:'center'
            });
              // $scope.formdata = "";
              $scope.checkCurrentPassword ();

          }).error(function(data,status){
            notify({
              message: data.message ,
              template:'templates/error.html',
              position:'center'
            });
          })
         }
        };

      UserService.getRoles().success(function(data, status) {   
        
        $scope.roles = data.roles;
        // console.log(data.roles)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 


    
         
      UnitService.getRates().success(function(data, status) {   
        $scope.rates = data.rates;
        console.log(data.rates)   
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

       UserService.getProfile().success(function(data, status) {   
        $rootScope.profile = data;
      // console.log($rootScope.profile);
        
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

       $scope.onFileSelect = function($files){
    // console.log($files);
   //$scope.file = $files;
   $scope.dataprofile={};
     //$scope.file = {};
        for (var i = $files.length - 1; i >= 0; i--){
             $scope.dataprofile['file'+i]=$files[i];
        }
    $scope.dataprofile['filecount']=$files.length;
    console.log($scope.dataprofile);
   }

       $rootScope.display = function (displayIsactive) {
        $scope.displayIsactive = displayIsactive;
         $scope.IsVisible = true;
        //  console.log($scope.displayIsactive);
         }

         $rootScope.accesCompany = function (userId,companyId) {
     UserService.accesCompany(userId,companyId).success(function(data, status) {  
      $scope.companies = data.companies;
           $scope.companiestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.companies.length,
                    counts: [10, 20, 50, 100,$scope.companies.length],
                    getData: function( params) {
                      params.total($scope.companies.length);
                    $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            }); 
      }).error(function(data, status) {
        alert('something went wrong');
      });
    }
       $rootScope.NotaccesCompany = function (userId,companyId) {
     UserService.NotaccesCompany(userId,companyId).success(function(data, status) {  
      $scope.companies = data.companies;
           $scope.companiestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.companies.length,
                    counts: [10, 20, 50, 100,$scope.companies.length],
                    getData: function( params) {
                      params.total($scope.companies.length);
                    $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            }); 
      }).error(function(data, status) {
        alert('something went wrong');
      });
    }
      $rootScope.activateDeactivate = function (userId,actDeaId) {
    UserService.activateDeactivate(userId,actDeaId).success(function(data, status) {   
        // $rootScope.profile = data;
      // console.log($rootScope.profile);
       $scope.users = data.users;  
        $scope.role = data.role;  
        console.log($scope.users); 
                $scope.userstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.users.length,
                    counts: [10, 20, 50, 100,$scope.users.length],
                    getData: function( params) {
                      params.total($scope.users.length);
                    $scope.data = $scope.users.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });

                $scope.SuperAdmins = data.SuperAdmins; 
                   console.log($scope.SuperAdmins); 

                $scope.SuperAdminstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.SuperAdmins.length,
                    counts: [10, 20, 50, 100,$scope.SuperAdmins.length],
                    getData: function( params) {
                      params.total($scope.SuperAdmins.length);
                    $scope.data = $scope.SuperAdmins.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
      }).error(function(data, status) {
         alert('something went wrong');
          
      }).error(function(data, status) {
         alert('something went wrong');
      }); 
     }
   

      UserService.getUser($scope.userId).success(function(data, status) {  
      $scope.user = data.user;
      $scope.companyUsers = data.companyUsers;
      $scope.companies = data.companies;
        // console.log(data.companies) 
        $scope.companiestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                    total: $scope.companies.length,
                    counts: [10, 20, 50, 100,$scope.companies.length],
                    getData: function( params) {
                      params.total($scope.companies.length);
                    $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });       
      }).error(function(data, status) {
        alert('something went wrong');
      }); 

      //  UserService.getCompanies().success(function(data, status) {    
      //   $scope.companies = data.companies;
      //   // console.log(data.companies) 
      //   $scope.companiestable = new NgTableParams({
      //               page: 1,
      //               count: 10,
      //               // sorting: { effective_dt: "asc" },
      //           },
      //           {
      //               total: $scope.companies.length,
      //               counts: [10, 20, 50, 100,$scope.companies.length],
      //               getData: function( params) {
      //                 params.total($scope.companies.length);
      //               $scope.data = $scope.companies.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
      //               return $scope.data;
   
      //               }
      //           // $scope: $scope
      //       });       
        
      // }).error(function(data, status) {
      //   alert('something went wrong');
      // });  

        //disply modalform.
        // $scope.toggle = function(modalstate, id) {
        // $scope.modalstate = modalstate;

        // switch (modalstate) {
        //     case 'add':
        //         $scope.form_title = "Add New User";
        //         break;
         
        // }
        // console.log(id)
    //      $('#myModal').modal('show');
    // }
$scope.emailnotification = function (newtask,replycoments,statuschange,taskreport,taskdue,dailyreport) {
  // body...
      UserService.emailnotification(newtask,replycoments,statuschange,taskreport,taskdue,dailyreport).success(function(data, status) {   
        // $rootScope.profile = data;
      // console.log($rootScope.profile);
          notify({
                     message:"Settings Changed successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
        
      }).error(function(data, status) {
        alert('something went wrong');
      }); 
  
}

   $scope.toggle = function(modalstate, id) {
      $scope.modalstate = modalstate;

      UserService.toggle(id).success(function(data, status) {  
      $scope.user = data.user;
      $scope.previousId = data.user.company_id;
      $scope.date = data.user.b_date;

      $scope.role = data.role;
      $scope.remained = data.remained;
      // console.log($scope.role);
         $('#editbirthDate').datepicker({
        uiLibrary: 'bootstrap',
            setValue: data.user.b_date
        });
      // $scope.name = data.user.name;
      // $scope.email = data.user.email;
      // $scope.role_id = data.user.role_id;
      // $scope.company_id = data.user.company_id;
      // console.log($scope.user);
      })
        

        switch (modalstate) {
         case 'edit':
                $scope.form_title = "Edit User";
                 $('#myModal1').modal('show');
                break;
             case 'editprofilepic':
                 $scope.form_title = "Change Profile";
                 $('#myModalprofilep').modal('show');
                break;

                case 'add':
                $scope.form_title = "Add New User";
                 $('#myModal').modal('show');
                break;

           
                 case 'adduser':
                $scope.form_title = "Add New User";
                 $('#myModal2').modal('show');
                break;
        }
        // console.log(id)
         // $('#myModal1').modal('show');
    }



   

     // save new record & update existing record
    $rootScope.save = function(userP,id,action) {
        var birthDate = $('#birthDate').val();

       if ($scope.dataprofile) {
    $scope.dataprofile['user']=userP;
    $scope.dataprofile['id']=id;
    $scope.dataprofile['birthDate']=birthDate;
    console.log($scope.dataprofile);

           $scope.upload = $upload.upload({
                url: 'users',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'Close') {
                    location.reload();
                } else {
                      $scope.user = "";
                      notify({
                     message:"User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
       var url = "users";
       var name = $scope.userP.name;
       var email = $scope.userP.email;
       var password = $scope.userP.password;
       var gender = $scope.userP.gender;
       var role_id = $scope.userP.role_id;
      //  var dep_id = $scope.userP.dep_id;
       var remained = $scope.remained;
       var nationality = $scope.userP.nationality;
       var marital_status = $scope.userP.marital_status;
      console.log($scope.userP)
      if (remained<=0 ){
        notify({
          message:"You have reached package user limit, Please upgrade to another package.",
          template:'templates/error.html',
          position:'center'
          });
      }else {
        if (userP==null || name==null || email==null || password==null || role_id==null || gender==null || nationality==null || marital_status==null ) {
          notify({
               message:"Please Fill All The Fields Correct.",
               template:'templates/error.html',
               position:'center'
               });
        } else {
 
           UserService.save(userP,id,birthDate).success(function(data, status) {   
         // $scope.user = data.user;
          if (action == 'close') {
                 notify({
                      message:"User Added successful.",
                      template:'templates/success.html',
                      position:'center'
                       });
                     location.reload();
                 } else {
             // console.log("nimefika");
                       $scope.userP = ""; 
                         notify({
                      message:"User Added successful.",
                      template:'templates/success.html',
                      position:'center'
                       });     
                 }
       }).error(function(data, status) {
         alert('something went wrong');
       });
        }
      }
    
          } 
 
    }

       $rootScope.saveSuper = function(user,id,action) {

                var birthDate = $('#birthDate').val();

       if ($scope.dataprofile) {
    $scope.dataprofile['user']=user;
    $scope.dataprofile['id']=id;
    $scope.dataprofile['birthDate']=birthDate;
    console.log($scope.dataprofile);

           $scope.upload = $upload.upload({
                url: 'saveSuper',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                if (action == 'Close') {
                    location.reload();
                } else {
                      $scope.user = "";
                      notify({
                     message:"User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
       var url = "saveSuper";
       var name = $scope.user.name;
       var email = $scope.user.email;
       var password = $scope.user.password;
       var gender = $scope.user.gender;
       var nationality = $scope.user.nationality;
       var marital_status = $scope.user.marital_status;
      
       if (user==null || name==null || email==null || password==null  || gender==null || nationality==null || marital_status==null || birthDate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {

          UserService.saveSuper(user,id,birthDate).success(function(data, status) {   
        // $scope.user = data.user;
         if (action == 'close') {
                notify({
                     message:"Super User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
            // console.log("nimefika");
                      $scope.user = ""; 
                        notify({
                     message:"Super User Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });     
                }
      }).error(function(data, status) {
        alert('something went wrong');
      });
       }
          } 
      //   var url = "saveSuper";
      //       var name = $scope.user.name;
      //  var email = $scope.user.email;
      //  var password = $scope.user.password;
      //   // console.log(description);
      //  if (user==null || name==null || email==null || password==null) {
      //    notify({
      //         message:"Please Fill All The Fields Correct.",
      //         template:'templates/error.html',
      //         position:'center'
      //         });
      //  } else {
      //   $http({
      //       method: 'POST',
      //       url: url,
      //       data: $.param($scope.user),
      //       headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      //      }).success(function(response) {
      //           if (action == 'close') {
      //           notify({
      //                message:"SuperUser Added successful.",
      //                template:'templates/success.html',
      //                position:'center'
      //                 });
      //               location.reload();
      //           } else {
      //       // console.log("nimefika");
      //                 $scope.user = ""; 
      //                   notify({
      //                message:"SuperUser Added successful.",
      //                template:'templates/success.html',
      //                position:'center'
      //                 });     
      //           }
      //   }).error(function(response) {
      //       console.log(response);
      //       alert('An error has occured. Please check the log for details');
      //   });
      // }

    }
    $rootScope.updateProfile = function(id) {
      $scope.dataprofile['id']=id;
         $scope.upload = $upload.upload({
                url: 'updateProfile',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                // if (action == 'Close') {
                    location.reload();
        
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
    }


    $rootScope.update = function(user,previousId) {
      console.log(previousId);

        var birthDate = $('#editbirthDate').val();

    if ($scope.dataprofile) {
    $scope.dataprofile['user']=user;
    $scope.dataprofile['birthDate']=birthDate;
    $scope.dataprofile['previousId']=previousId;

    // console.log($scope.dataprofile);

           $scope.upload = $upload.upload({
                url: 'update',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                // if (action == 'Close') {
                    location.reload();
        
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
       var url = "update";
       var name = $scope.user.name;
       var email = $scope.user.email;
       var gender = $scope.user.gender;
       var role_id = $scope.user.role_id;
       var dep_id = $scope.user.dep_id;
       var rate = $scope.user.rate;
       var nationality = $scope.user.nationality;
       var marital_status = $scope.user.marital_status;
      
       if ( name==null || email==null || role_id==null || rate==null || gender==null || nationality==null || marital_status==null || birthDate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {
        UserService.update(user,previousId,birthDate).success(function(data, status) { 
        location.reload();  
      }).error(function(data, status) {
        alert('something went wrong');
      });
       }
      } 
    }

      $rootScope.updateSuper = function(user,previousId) {
         var birthDate = $('#editbirthDate').val();

    if ($scope.dataprofile) {
    $scope.dataprofile['user']=user;
    $scope.dataprofile['birthDate']=birthDate;

    console.log(previousId);

           $scope.upload = $upload.upload({
                url: 'updateSuper',
                //data: {myObj: $scope.myModelObj,'task':task,'projectId':projectId,'companyId':companyId,'file1':$scope.file[0],'file2':$scope.file[1]},
                data:$scope.dataprofile,
                //file: $scope.file,
                 // file: $scope.file,
                 headers: {'Content-Type': undefined},
            }).then(function(response) {
                // if (action == 'Close') {
                    location.reload();
        
            }, function(response) {
                // console.log('Error status: ' + resp.status);
              notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
          }else {
       var name = $scope.user.name;
       var email = $scope.user.email;
       var gender = $scope.user.gender;
       var nationality = $scope.user.nationality;
       var marital_status = $scope.user.marital_status;
      
       if ( name==null || email==null  || gender==null || nationality==null || marital_status==null || birthDate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
              });
       } else {
        UserService.updateSuper(user,previousId,birthDate).success(function(data, status) { 
        location.reload();  
      }).error(function(data, status) {
        alert('something went wrong');
      });
       }
      } 
       //  var url = "updateSuper";
       //  var name = $scope.user.name;
       // var email = $scope.user.email;
       //  // console.log(description);
       // if (user==null || name==null || email==null) {
       //   notify({
       //        message:"Please Fill All The Fields Correct.",
       //        template:'templates/error.html',
       //        position:'center'
       //        });
       // } else {
       //  $http({
       //      method: 'POST',
       //      url: url,
       //      data: $.param($scope.user),
       //      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
       //  }).success(function(response) {
       //      console.log(response);
       //      location.reload();
       //  }).error(function(response) {
       //      console.log(response);
       //      alert('An error has occured. Please check the log for details');
       //  });
       // }
    }
     
 

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id, modelname)
  }
  $scope.removefile = function(userId){
  // console.log(taskId);
    UserService.removefile(userId).success(function(data, status) {   
        $scope.user = data.user;
      }).error(function(data, status) {
        alert('something went wrong');
      });
  }

	}); 