main.service('UnitService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Type = this;

 Type.getUnits = function() {
    return $http({
      url : 'index.php/getUnits/',
    });
  };  


 Type.getRates = function() {
    return $http({
      url : 'index.php/getRates/',
    });
  };
    Type.toggleUnit = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleUnit/',
      params: {
        'id':id,
      }
    });
  };

});
