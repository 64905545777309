'use strict';
 main.controller('UnitsController',function($rootScope,UnitService,globalService, $scope,$http, actions, $modal, $log, notify, modalService,NgTableParams) {
            
		$scope.units = function () {
           
			UnitService.getUnits().success(function(data, status) {   
				
        $scope.units = data.units;
          $scope.unitstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.units.length,
                    counts: [10, 20, 50, 100,$scope.units.length],
                    getData: function( params) {
                      params.total($scope.units.length);
                    $scope.data = $scope.units.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
				
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

    UnitService.getRates().success(function(data, status) {   
        
        $scope.rates = data.rates;
        console.log($scope.rates);
          $scope.ratestable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.rates.length,
                    counts: [10, 20, 50, 100,$scope.rates.length],
                    getData: function( params) {
                      params.total($scope.rates.length);
                    $scope.data = $scope.rates.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
        
      }).error(function(data, status) {
        alert('something went wrong');
      });

        //disply modalform.
        $scope.toggleUnit = function(modalstate, id) {
        $scope.modalstate = modalstate;
      UnitService.toggleUnit(id).success(function(data, status) {  
      $scope.unit = data.unit;
      $scope.rate = data.rate;
      })
        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Unit";
                $('#myModal').modal('show');
                break;
                case 'edit':
                $scope.form_title = "Edit Unit";
                $('#myModal1').modal('show');
                break;
                case 'addrate':
                $scope.form_title = "Add Rate";
                $('#myModal2').modal('show');
                break;
                case 'editrate':
                $scope.form_title = "Edit Rate";
                $('#myModal3').modal('show');
                break;
 
        }
        // console.log(id)
         
    }


   

     // save new record & update existing record
    $rootScope.save = function(modalstate,id,unitdata,action) {
            // console.log(unitdata);

        var url = "units";
             var uname = $scope.unitdata.uname;
          var short_name = $scope.unitdata.short_name;
        if ( uname==null || short_name==null || unitdata==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else{
        $http({
            method: 'POST',
            url: url,
            data: $.param(unitdata),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data, status) {
        $scope.units = data.units;
                    if (action == 'close') {
                    notify({
                     message:"Unit Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.unitdata = "";
                      notify({
                     message:"Unit Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
        }).error(function(data, status) {
                    notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
        });
      }
    }

        $rootScope.saverate = function(modalstate,id,ratedata,action) {
            // console.log(unitdata);

        var url = "rates";
             var ratename = $scope.ratedata.ratename;
          var value = $scope.ratedata.value;
          var dvalue = $scope.ratedata.dvalue;
        if ( ratename==null || value==null|| dvalue==null || ratedata==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else{
        $http({
            method: 'POST',
            url: url,
            data: $.param(ratedata),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data, status) {
        $scope.rates = data.rates;
                    if (action == 'close') {
                    notify({
                     message:"Rate Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.ratedata = "";
                      notify({
                     message:"Unit Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
        }).error(function(data, status) {
                    notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
        });
      }
    }
     
     
  $rootScope.updateU = function(unit,id) {
        var url = "updateU";
         var uname = $scope.unit.uname;
          var short_name = $scope.unit.short_name;
        if ( uname==null || short_name==null || unit==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else{
        $http({
            method: 'POST',
            url: url,
            data: $.param($scope.unit),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });
       }
    }

     $rootScope.updaterate = function(rate,id) {
        var url = "updaterate";
         var ratename = $scope.rate.ratename;
          var value = $scope.rate.value;
          var dvalue = $scope.rate.dvalue;
        if ( ratename==null || value==null || dvalue==null || rate==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else{
        $http({
            method: 'POST',
            url: url,
            data: $.param($scope.rate),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });
       }
    }

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id,modelname)
  }


	}); 

  