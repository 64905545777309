main.service('DashboardService', function (modalService, actions, notify, $modal, $http) {

  'use strict';

  var Company = this;

  Company.getUsers = function() {
    return $http({
      url : 'index.php/getUsers/',
    });
  };
   Company.getRoles = function() {
    return $http({
      url : 'index.php/getRoles/',
    });
  };


  Company.getCompanies = function() {
    return $http({
      url : 'index.php/getCompanies/',
    });
  };
     Company.toggleCompany = function(id) {
    return $http({
      method: 'GET',
      url : 'index.php/toggleCompany/',
      params: {
        'id':id,
      }
    });
  };

  Company.getCompany = function(companyId) {
    return $http({
      method: 'GET',
      url : 'index.php/getCompany/',
      params: {
        'companyId':companyId,
      }
    });
  };

  

});
