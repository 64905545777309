'use strict';
 main.controller('DepartmentsController',function($rootScope,DepartmentService,globalService, $scope,$http, actions, $modal, $log, notify, modalService,NgTableParams) {
            
		$scope.departments = function () {
           
			DepartmentService.getDepartments().success(function(data, status) {   
				
        $scope.departments = data.departments;
        $scope.role = data.role;
            $scope.departmentstable = new NgTableParams({
                    page: 1,
                    count: 10,
                    // sorting: { effective_dt: "asc" },
                },
                {
                       total: $scope.departments.length,
                    counts: [10, 20, 50, 100,$scope.departments.length],
                    getData: function( params) {
                      params.total($scope.departments.length);
                    $scope.data = $scope.departments.slice((params.page() - 1) * params.count(), params.page() * params.count());                      
                    return $scope.data;
   
                    }
                // $scope: $scope
            });
				
			}).error(function(data, status) {
				alert('something went wrong');
			}); 
		}

        //disply modalform.
        $scope.toggleDep = function(modalstate, id) {
        $scope.modalstate = modalstate;
      DepartmentService.toggleDep(id).success(function(data, status) {  
      $scope.department = data.department;
      })
        switch (modalstate) {
            case 'add':
                $scope.form_title = "Add New Department";
                $('#depModal').modal('show');
                break;
                case 'edit':
                $scope.form_title = "Edit Department";
                $('#depModal1').modal('show');
                break;
 
        }
        // console.log(id)
         
    }


   

     // save new record & update existing record
    $rootScope.save = function(modalstate,id,department,action) {
            // console.log(typedata);

        var url = "departments";
         var depname = $scope.department.depname;
          var short_name = $scope.department.short_name;
        if ( depname==null || short_name==null || department==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else {
        $http({
            method: 'POST',
            url: url,
            data: $.param(department),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data, status) {
        $scope.departments = data.departments;
        // console.log($scope.types);
                 if (action == 'close') {
                    notify({
                     message:"Department Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                    location.reload();
                } else {
                      $scope.department = "";
                      notify({
                     message:"Department Added successful.",
                     template:'templates/success.html',
                     position:'center'
                      });
                      
                      // $('#taskstartDate').val();
                }
        }).error(function(data, status) {
                 notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
        });
      }
    }
     
     
  $rootScope.updateDep = function(department,id) {
        var url = "updateDep";

        var depname = $scope.department.depname;
          var short_name = $scope.department.short_name;
        if ( depname==null || short_name==null || department==null) {
         notify({
              message:"Please Fill All The Fields Correct.",
              template:'templates/error.html',
              position:'center'
            });
       }else {
        $http({
            method: 'POST',
            url: url,
            data: $.param($scope.department),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(response) {
            console.log(response);
            location.reload();
        }).error(function(response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });
       }
    }

$scope.deleteItem = function(id,modelname){
  console.log(id);
    globalService.deleteItem(id,modelname)
  }


	}); 

  